let storageCache: Map<string, string> = new Map<string, string>();

async function get<Type>(
  key: string,
  defaultValue?: Type
): Promise<Type | undefined> {
  let value: string | undefined;
  if (await willBePersisted()) {
    value = localStorage.getItem(key) ?? undefined;
  } else {
    value = storageCache.get(key);
  }

  if (value === undefined) return defaultValue;
  return JSON.parse(value) as Type;
}

async function set<Type>(key: string, value: Type) {
  if (await willBePersisted()) {
    await localStorage.setItem(key, JSON.stringify(value));
  } else {
    storageCache.set(key, JSON.stringify(value));
  }
}

let cachedWillBePersisted: boolean | undefined;
async function willBePersisted(): Promise<boolean> {
  if (cachedWillBePersisted !== undefined) return cachedWillBePersisted;
  const testKey = "featureTest";
  const testValue = "yes";
  try {
    await localStorage.setItem(testKey, testValue);
    if (localStorage.getItem(testKey) === testValue) {
      localStorage.removeItem(testKey);
      cachedWillBePersisted = true;
      return true;
    }
  } catch {}
  cachedWillBePersisted = false;
  return false;
}

const LocalStorage = {
  get,
  set,
  willBePersisted,
};

export default LocalStorage;
