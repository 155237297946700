import React from "react";
import {
  Box,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import SecondaryButton from "Components/SecondaryButton";
import { BlueGray } from "Themes/Colors";
import PrimaryButton from "Components/PrimaryButton";
import StripeCampCheckout from "Components/StripeCampCheckout";
import { ITrainingCampPackage } from "Sdk/Data/Models/TrainingCampPackage";

//#region Properties
export interface PurchaseCampPackageProps {
  Package: ITrainingCampPackage;
  OnBack: () => void;
  SuccessCallback: () => void;
}
interface Props extends PurchaseCampPackageProps, WithStyles<typeof styles> {}
//#endregion

interface State {
  IsCheckingOut: boolean;
}

//#region Component
class PurchaseCampPackage extends React.Component<Props, State> {
  private priceFormatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  constructor(props: Props) {
    super(props);

    this.state = { IsCheckingOut: false };
  }

  onClickBack(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    this.props.OnBack();
  }
  onClickCheckout(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ IsCheckingOut: true });
  }

  render() {
    const { classes, Package } = this.props;

    const coachPrice = Package.Price;
    const priceAfterKano = coachPrice * 1.12;
    const finalTotal = (priceAfterKano + 0.3) / 0.971;
    const transactionFee = finalTotal - priceAfterKano;

    return (
      <Box className={classes.root}>
        {this.state.IsCheckingOut ? (
          <StripeCampCheckout
            CampPackage={Package}
            BackCallback={() => this.setState({ IsCheckingOut: false })}
            SuccessCallback={() => this.props.SuccessCallback()}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid container item xs={9}>
              <Typography>Subtotal:</Typography>
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <Typography>
                {this.priceFormatter.format(priceAfterKano)}
              </Typography>
            </Grid>

            <Grid container item xs={12}>
              <hr className={classes.line} />
            </Grid>

            <Grid container item xs={9}>
              <Typography>Transaction Fee:</Typography>
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <Typography>
                {this.priceFormatter.format(transactionFee)}
              </Typography>
            </Grid>

            <Grid container item xs={12}>
              <hr className={classes.line} />{" "}
            </Grid>

            <Grid container item xs={9}>
              <Typography>
                <b>Final Total:</b>
              </Typography>
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <Typography>
                <b>{this.priceFormatter.format(finalTotal)}</b>
              </Typography>
            </Grid>

            <Grid container item xs={12}>
              <Box display="flex" className={classes.buttonSection}>
                <SecondaryButton
                  text="Back"
                  onClick={(e) => this.onClickBack(e)}
                  className={classes.backButton}
                />
                <PrimaryButton
                  text="Checkout Now"
                  onClick={(e) => this.onClickCheckout(e)}
                  className={classes.checkoutButton}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  let lineColor = BlueGray[200];
  return createStyles({
    root: {
      width: "80%",
      maxWidth: "50em",
      margin: "auto",
    },
    line: {
      width: "100%",
      color: lineColor,
      backgroundColor: lineColor,
      borderColor: lineColor,
    },
    buttonSection: {
      width: "100%",
      marginTop: "1em",
    },
    checkoutButton: {
      width: "100%",
    },
    backButton: {
      width: "25%",
      marginRight: "1em",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(PurchaseCampPackage);
