import React from "react";
import TextLoop from "react-text-loop";

//#region Properties
export interface MultiTextProps {
  text: Array<string>;
  intervalSeconds: number;
  className?: string;
}

//#endregion

//#region Component
class MultiText extends React.Component<
  MultiTextProps,
  { currentText: string; currentIndex: number }
> {
  constructor(props: MultiTextProps) {
    super(props);

    this.state = { currentText: this.props.text[0], currentIndex: 0 };
  }

  render() {
    return (
      <TextLoop
        className={this.props.className}
        children={this.props.text}
        interval={this.props.intervalSeconds * 1000}
        springConfig={{ stiffness: 150, damping: 30 }}
        mask
      />
    );
  }
}
//#endregion

export default MultiText;
