import React from "react";
import {
  Checkbox,
  createStyles,
  FormControlLabel,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";

//#region Properties
export interface LabeledChecboxProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}
interface Props extends LabeledChecboxProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class LabeledChecbox extends React.Component<Props> {
  render() {
    // const { classes } = this.props;
    return (
      <FormControlLabel
        className={this.props.className}
        control={
          <Checkbox
            style={{ marginRight: "5px" }}
            color="primary"
            checked={this.props.value}
            onChange={(event) => this.props.onChange(event.target.checked)}
          />
        }
        label={this.props.label}
      />
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(LabeledChecbox);
