import Endpoints from "../Config/Endpoints";
import Request from "../Services/Request";
import UserPassword from "../Data/Models/UserPassword";
import ApiResponse from "../Utils/ApiResponse";
import TokenManager from "Sdk/Services/TokenManager";
import ErrorMessage from "Sdk/Utils/ErrorMessage";
import { IPlayer } from "Sdk/Data/Models/Player";
import { IUser } from "Sdk/Data/Models/User";
import Position from "Sdk/Data/Reference/Position";
import PlayerPosition, { IPlayerPosition } from "../Data/Models/PlayerPosition";
import { IOrganization } from "Sdk/Data/Models/Organization";
import ScholarshipLevel from "Sdk/Data/Reference/ScholarshipLevel";
import School from "../Data/Models/School";
import Player from "./PlayerController";
import { ICoach } from "Sdk/Data/Models/Coach";
import PlayerCoach from "Sdk/Data/Models/PlayerCoach";

let activeUser: IUser | undefined;
let ActiveUser = () => activeUser;

//#region Create new Account
// TODO: Fix this when we are making backend changes
async function createNewPlayerAndLogin(
  player: IPlayer,
  password: string
): Promise<ApiResponse<IUser>> {
  let createUserResult = await createNewUserAndLogin(player, password);
  if (!createUserResult.wasSuccess) {
    return createUserResult;
  }
  let serverUser = createUserResult.data!;
  player.Id = serverUser.Id;

  var response = await Player.updatePlayer(player);
  if (response.wasSuccess && response.data) {
    activeUser = response.data;
  }
  return response;
}

/**
 * Creates a new user and logs in.
 * Returned ApiResult will always have data for success.
 * @param user The user to create
 * @param password The password to use for the new user
 */
async function createNewUserAndLogin(
  user: IUser,
  password: string
): Promise<ApiResponse<IUser>> {
  // Create a new UnknownUser
  let newUserResult = await Request.post<IUser>(
    Endpoints.account.signUp,
    new UserPassword(user, password)
  );
  if (!newUserResult.wasSuccess) {
    switch (newUserResult.error!.message) {
      case "already exists":
      case "already_exists":
        newUserResult.error!.message =
          "User with that email already exists." +
          "\nDownload the Kano Train app to get started!";
        break;
      case "exists disabled":
      case "exists_disabled":
        newUserResult.error!.message =
          "This email is already in-use by a disabled account." +
          "\nPlease contact support for assistance.";
        break;
      case "invalid email":
      case "invalid_email":
        newUserResult.error!.message = "This email address is invalid.";
        break;
      default:
        newUserResult.error!.message =
          "An internal error caused signup to fail." +
          "\nPlease try again later.";
    }

    return newUserResult;
  } else if (!newUserResult.data) {
    return new ApiResponse<IUser>(
      ErrorMessage.cat(ErrorMessage.unknownError, ErrorMessage.devAlert),
      undefined
    );
  }

  // Log in with newly created account
  let serverUser = newUserResult.data;
  let loginResult = await TokenManager.login(serverUser, password);
  if (loginResult) {
    return new ApiResponse<IUser>(loginResult.message, undefined);
  }

  activeUser = serverUser;
  return newUserResult;
}

async function createPlayerPosition(
  user: IUser,
  organization: IOrganization,
  position: Position,
  yearsExperience: number,
  yearsStarting: number,
  isPrimary: boolean,
  scholarshipLevel?: ScholarshipLevel
): Promise<ApiResponse<IPlayerPosition>> {
  let playerPosition = new PlayerPosition(
    user,
    organization,
    position,
    yearsExperience,
    yearsStarting,
    0,
    isPrimary,
    scholarshipLevel
  );
  return await Request.post<IPlayerPosition>(
    Endpoints.playerPosition.root,
    playerPosition
  );
}

async function createSchool(name: string): Promise<ApiResponse<IOrganization>> {
  let school = new School(name);
  return await Request.post<IOrganization>(Endpoints.organization.root, school);
}
//#endregion

//#region Manage Coaches
async function addCoach(
  player: IPlayer,
  coach: ICoach,
  isPrimaryCoach: boolean
): Promise<ApiResponse<boolean>> {
  if (!player || !coach) {
    return new ApiResponse("There was a problem adding your coach.");
  }
  let playerCoach = new PlayerCoach(player, coach, isPrimaryCoach);
  return await Request.post(Endpoints.playerCoach.root, playerCoach);
}
//#endregion

const AccountController = {
  createNewPlayerAndLogin,
  createSchool,
  createPlayerPosition,
  addCoach,
  ActiveUser,
};

export default AccountController;
