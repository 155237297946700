import React from "react";
import OrganizationController from "Sdk/Controllers/OrganizationController";
import UserFriendlyError from "Utils/UserFriendlyError";
import TermsOfServiceInfo from "../../Data/TermsOfServiceInfo";
import RegistrationStep from "../../RegistrationStep";
import TermsOfServiceStepComponent from "./TermsOfServiceStepComponent";

export default class TermsOfServiceStep
  implements RegistrationStep<TermsOfServiceInfo> {
  public StepTitle = "Terms of Service";
  private componentRef: React.RefObject<TermsOfServiceStepComponent> = React.createRef();
  private data?: TermsOfServiceInfo;
  private readonly organizationId?: string;
  private orgWaiver: string;

  public constructor(organizationId?: string) {
    this.organizationId = organizationId;

    this.orgWaiver = "";
    (async () => {
      if (!this.organizationId) return;

      let waiverResponse = await OrganizationController.getReleaseWaiver(
        this.organizationId
      );
      if (!waiverResponse.wasSuccess) {
        console.error(
          "Unable to get release waiver: " + waiverResponse.error?.message
        );
        return;
      }

      this.orgWaiver = waiverResponse.data!;
    })();
  }

  public getComponent() {
    return (
      <TermsOfServiceStepComponent
        ref={this.componentRef}
        data={this.data}
        orgWaiver={this.orgWaiver}
      />
    );
  }

  componentWillUnmount() {
    // Make sure we've always got the latest data before it's inaccessible
    this.getData();
  }

  public getData() {
    let component = this.componentRef.current;
    if (component == null) {
      if (this.data == null) {
        return new UserFriendlyError("There was a problem");
      }
      return this.data;
    }

    let data = component.getData();
    if (!(data as UserFriendlyError).message) {
      this.data = data as TermsOfServiceInfo;
    }
    return data;
  }
}
