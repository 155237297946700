const TermsOfServiceContent = () => {
  return (
    <>
      <p>
        Welcome to KANO! These Terms of Use (&ldquo;
        <strong>
          <em>Terms</em>
        </strong>
        &rdquo;) apply to your access to and use of the website, mobile
        application and other online products and services (collectively, our
        &ldquo;
        <strong>
          <em>Services</em>
        </strong>
        &rdquo;) provided by KANO Performance, LLC. (&ldquo;
        <strong>
          <em>KANO Performance, LLC</em>
        </strong>
        ,&rdquo; &ldquo;
        <strong>
          <em>we</em>
        </strong>
        ,&rdquo; &ldquo;
        <strong>
          <em>us</em>
        </strong>
        &rdquo;, &ldquo;
        <strong>
          <em>our</em>
        </strong>
        &rdquo;, &ldquo;KANO&rdquo;, or &ldquo;KANO Train&rdquo;).&nbsp;Please
        read these Terms of Use carefully before accessing or using our website
        or any applications provided by us.{" "}
        <strong>
          By accessing or using our Services, you agree to these Terms. If you
          do not agree to these Terms, including the mandatory arbitration
          provision and class action waiver in Section 19, do not access or use
          our Services.
        </strong>
      </p>
      <p>
        These Terms do not alter in any way the terms or conditions of any other
        agreement you may have with KANO for products, services or otherwise. If
        you are accessing or using our Services on behalf of another person or
        entity, you represent and warrant that you are authorized to accept
        these Terms on such person or entity's behalf, and that you and such
        person or entity will be responsible to KANO if either violate these
        Terms.
      </p>
      <p>
        If you have any question regarding the use of our Services, please&nbsp;
        <a href="https://www.kanotrain.com/contact">contact us</a>.
      </p>
      <ol>
        <li>
          <strong> Eligibility, Registration and Account</strong>
        </li>
      </ol>
      <p>
        By using our Services, you represent and warrant that you (a)&nbsp;are
        18 years of age or older, (b)&nbsp;have not been previously suspended or
        removed from our Services, or engaged in any activity that could result
        in suspension or removal from our Services, (c) will not accept payment
        or make payment for coaching services or training outside of
        KANO&rsquo;s services, (d)&nbsp;have full power and authority to enter
        into these Terms and in so doing will not violate any other agreement to
        which you are a party and (e) will not reproduce, duplicate, modify,
        reengineer, copy, sell, resell or exploit any portion of the Services,
        use of the Services, or access to the Services or any contact with the
        website or application through which services are provided, without the
        express written permission of us.
      </p>
      <p>
        In order to access or use certain areas and features of our Services,
        you may need to register for an account. By registering for an account,
        you agree to (i)&nbsp;provide accurate, truthful, current and complete
        account information; (ii)&nbsp;maintain and promptly update your account
        information to maintain its accuracy and completeness;
        (iii)&nbsp;maintain the security of your account by not sharing your
        password with others and restricting access to your account, computer
        and device; (iv)&nbsp;promptly notify KANO if you discover or otherwise
        suspect any security breaches related to our Services or your account;
        and (v)&nbsp;take responsibility for all activities that occur under
        your account and accept all risks of unauthorized access.
      </p>
      <ol start={2}>
        <li>
          <strong> Communications Preferences</strong>
        </li>
      </ol>
      <p>
        By creating a KANO account, you consent to receive electronic
        communications from KANO (e.g., via email or by posting notices on our
        Services). These communications may include notices about your account
        (e.g., payment authorizations, password changes and other transactional
        information) and are part of your relationship with us. You agree that
        any notices, agreements, disclosures or other communications that we
        send to you electronically will satisfy any legal communication
        requirements, including, but not limited to, that such communications be
        in writing. You should maintain copies of electronic communications from
        us by printing a paper copy or saving an electronic copy. We may also
        send you promotional communications via email, including, but not
        limited to, newsletters, special offers, surveys and other news and
        information we think will be of interest to you. You may opt out of
        receiving these promotional emails at any time by following the
        unsubscribe instructions provided in these communications.
      </p>
      <ol start={3}>
        <li>
          <strong> Our Services Offer a Venue</strong>
        </li>
      </ol>
      <p>
        Our Services offers tools, resources and a venue to connect those
        seeking athletic training with those seeking to provide coaching
        services. KANO: (a)&nbsp;does not employ, recommend or endorse any users
        and has no control over the acts or omissions of any users; (b)&nbsp;is
        not responsible or liable in any manner for the performance or conduct
        of any users or other third parties online or offline; (c)&nbsp;makes no
        representations or warranties about the quality of the services provided
        by any user or about your interactions or dealings with other users
        except as expressly stated on our Services; and (d)&nbsp;does not screen
        users or conduct any kind of identity or background checks except as
        otherwise expressly stated in these Terms or on or in our Services.
        Regardless of whether KANO screens users or performs a background check,
        you should exercise caution and perform your own screening before
        connecting or meeting with any other user through our Services, entering
        into a contract or agreement with any other user or obtaining any
        services. KANO hereby expressly disclaims, and you hereby expressly
        release KANO from, any and all liability whatsoever for any
        controversies, claims, suits, injuries, loss, harm or damages arising
        from or related to our Services or your interactions or dealings with
        other users, including any acts or omissions of users online or
        offline.&nbsp;
        <strong>
          All use of our Services is at your sole and exclusive risk.
        </strong>
      </p>
      <ol start={4}>
        <li>
          <strong> User Transactions; Release</strong>
        </li>
      </ol>
      <p>
        Our Services may be used to help obtain or offer coaching services
        provided by users and to facilitate payment for such services. However,
        users transact solely between themselves and KANO is not a party to any
        transactions between users. KANO hereby expressly disclaims, and you
        hereby expressly release KANO from, any and all liability whatsoever for
        any controversies, claims, suits, injuries, loss, harm or damages
        arising from or related to disputes, dealings, or interactions between
        you and any other users or third parties.
      </p>
      <p>
        If you offer coaching services, the first and all future bookings with
        KANO users must be booked via our Services. Failure to abide by this
        policy may result in suspension from our Services.
      </p>
      <ol start={5}>
        <li>
          <strong> Payment Terms</strong>
        </li>
      </ol>
      <p>
        <strong>5.1. Coaching Sessions; Expiration</strong>
      </p>
      <p>
        You may use our Services to schedule and purchase one or more coaching
        sessions. Any coaching sessions purchased through our Services must be
        scheduled and completed within 30 days of the purchase date. Any
        coaching sessions not scheduled and completed within one year of
        purchase are forfeit and you will not be issued a refund.
      </p>
      <p>
        <strong>5.2. Payment and Billing Information</strong>
      </p>
      <p>
        By providing a credit card or other payment method that we accept, you
        represent and warrant that you are authorized to use the designated
        payment method and that you authorize us (or our third-party payment
        processor) to charge your payment method for the total amount of your
        purchase (including any applicable taxes, fees and other charges)
        (collectively, as applicable, an &ldquo;Order&rdquo;). If the payment
        method cannot be verified, is invalid or is otherwise not acceptable,
        your Order may be suspended or cancelled. You must resolve any problem
        we encounter in order to proceed with your Order.
      </p>
      <p>
        You acknowledge that the amount billed may vary due to promotional
        offers, changes to your coaching sessions or changes in applicable
        taxes, fees or other charges, and you authorize us (or our third-party
        payment processor) to charge your payment method for the corresponding
        amount.
      </p>
      <p>
        <strong>5.3. Pricing and Availability</strong>
      </p>
      <p>
        All prices are shown in U.S. dollars and applicable taxes and other
        charges, if any, are additional. Prices may be adjusted at any time and
        for any reason (or no reason) and without providing you prior notice.
      </p>
      <p>
        Coaching sessions are subject to availability, and we reserve the right
        to cancel all or part of a coaching session and to discontinue making
        certain coaching services available through our Services without prior
        notice.
      </p>
      <p>
        <strong>5.4. Returns and Refunds</strong>
      </p>
      <p>
        KANO does not provide returns and refunds.&nbsp; Transactions are
        between the player and coach.&nbsp;
      </p>
      <ol start={6}>
        <li>
          <strong> Copyright and Limited License</strong>
        </li>
      </ol>
      <p>
        Unless otherwise indicated in writing by us, our Services and all
        content and other materials on our Services, including the KANO logo and
        all designs, text, graphics, pictures, information, data, software,
        sound files, other files and the selection and arrangement thereof
        (collectively, the &ldquo;Service Materials&rdquo;) are the proprietary
        property of KANO or our licensors or users, as applicable, and are
        protected by U.S. and international copyright laws.
      </p>
      <p>
        Subject to these Terms, you are granted a limited, nonexclusive,
        nontransferable, nonsublicensable license to access and use our Services
        and Service Materials. However, such license is revocable at any time
        and does not include any right to: (a)&nbsp;sell or resell our Services
        or the Service Materials; (b)&nbsp;collect and use any service listings,
        pictures or descriptions; (c)&nbsp;distribute, publicly perform or
        publicly display any of the Service Materials; (d)&nbsp;modify or
        otherwise make any derivative uses of our Services or the Service
        Materials, or any portion thereof; (e)&nbsp;use any data mining, robots
        or similar data gathering or extraction methods; (f)&nbsp;download
        (other than the page caching) any portion of our Services, the Service
        Materials or any information contained therein, except as expressly
        permitted by KANO in writing; or (g)&nbsp;use our Services or the
        Service Materials in violation of these Terms or for any purposes other
        than their intended purposes. Your right to use our Services and the
        Service Materials is conditioned upon your use of our Services and the
        Service Materials in accordance with these Terms. Accordingly, any use
        of the Services or the Service Materials other than as specifically
        authorized in these Terms, without the prior written permission of KANO,
        will automatically terminate the license granted above. Such
        unauthorized use may also violate applicable laws, including, without
        limitation, copyright and trademark laws and applicable communications
        regulations and statutes. Except for the license granted above, no
        additional rights are granted and nothing in these Terms will be
        construed as conferring any license to intellectual property rights,
        whether by estoppel, implication or otherwise.
      </p>
      <p>
        Notwithstanding anything to the contrary in these Terms, our Services
        and the Service Materials may include software components provided by
        KANO or a third party that are subject to separate license terms, in
        which case those license terms will govern such software components.
      </p>
      <ol start={7}>
        <li>
          <strong> Copyright Complaints</strong>
        </li>
      </ol>
      <p>
        We have a policy of limiting access to our Services and terminating the
        accounts of users who infringe the intellectual property rights of
        others. If you believe that anything on our Services infringes any
        copyright that you own or control, you may notify KANO&rsquo;s
        Designated Agent as set forth below. 63 S Royal St # 1300, Mobile, AL
        36602
      </p>
      <p>
        <em>Robbie Turnipseed, Esq</em>
      </p>
      <p>
        <em>
          Armbretch Jackson LLP
          <br /> 63 S Royal St. # 1300, Mobile, AL 36602
          <br /> Mobile, AL 36602
          <br /> <br /> JRT@ajlaw.com
        </em>
      </p>
      <p>
        Please see&nbsp;
        <a href="http://www.copyright.gov/title17/92chap5.html#512">
          17 U.S.C. &sect;512(c)(3)
        </a>
        &nbsp;for the requirements of a proper notification. You should note
        that if you knowingly misrepresent in your notification that the
        material or activity is infringing, you may be liable for any damages,
        including costs and attorneys&rsquo; fees, incurred by KANO or the
        alleged infringer as the result of KANO&rsquo;s relying upon such
        misrepresentation in removing or disabling access to the material or
        activity claimed to be infringing.
      </p>
      <ol start={8}>
        <li>
          <strong> Trademarks</strong>
        </li>
      </ol>
      <p>
        &ldquo;KANO,&rdquo; the KANO logos and any other product or service name
        or slogan contained on our Services are trademarks of KANO and its
        suppliers or licensors and may not be copied, imitated or used, in whole
        or in part, without the prior written permission of KANO or the
        applicable trademark holder.
      </p>
      <p>
        You may not use any meta tags or any other hidden text utilizing
        &ldquo;KANO&rdquo; or any other name, trademark or product or service
        name of KANO without KANO&rsquo;s prior written permission. In addition,
        the look and feel of our Services, including all page headers, custom
        graphics, button icons and scripts, is the service mark, trademark
        and/or trade dress of KANO and may not be copied, imitated or used, in
        whole or in part, without KANO&rsquo;s prior written permission. All
        other trademarks, registered trademarks, product names and company names
        or logos mentioned on our Services are the property of their respective
        owners. Reference to any products, services, processes or other
        information, by trade name, trademark, manufacturer, supplier or
        otherwise does not constitute or imply endorsement, sponsorship or
        recommendation thereof by KANO.
      </p>
      <ol start={9}>
        <li>
          <strong> Third-Party Content</strong>
        </li>
      </ol>
      <p>
        Our Services may include links to websites or content owned or operated
        by third parties as well as other third-party content, including
        advertisements, promotional offers, and social &ldquo;widgets&rdquo;
        (collectively, &ldquo;
        <strong>
          <em>Third-Party Content</em>
        </strong>
        &rdquo;). KANO does not own, control or endorse any Third-Party Content
        and makes no representation or warranties of any kind regarding the
        Third-Party Content, including, without limitation, regarding its
        accuracy or completeness. You acknowledge and agree that KANO is not
        responsible or liable in any manner for any Third-Party Content and
        undertakes no responsibility to update or review any Third-Party
        Content. You access and use such Third-Party Content at your own risk.
        The inclusion of Third-Party Content on our Services does not imply
        affiliation, endorsement or adoption by KANO of any information
        contained therein. In addition, your business dealings or correspondence
        with, or participation in the promotional offers of, any third party
        responsible for Third-Party Content, and any terms, conditions,
        warranties or representations associated with such dealings or
        promotional offers, are solely between you and such third party. KANO is
        not responsible or liable for any loss or damage of any sort incurred as
        the result of any such dealings or promotions or as the result of the
        presence of such Third-Party Content on our Services. When you navigate
        away from or otherwise leave our Services, you should understand that
        KANO&rsquo;s terms and policies no longer govern and that the terms and
        policies of those third-party sites will now apply. You should review
        the applicable terms and policies, including privacy and data gathering
        practices, of any site or services to which you navigate to from our
        Services.
      </p>
      <ol start={10}>
        <li>
          <strong> User Content and Interactive Areas</strong>
        </li>
      </ol>
      <p>
        Our Services include interactive areas and features (&ldquo;Interactive
        Areas&rdquo;) that allow you or other users to create, post, share or
        store text, photos, videos and other materials (collectively,
        &ldquo;User Content"). In the event you decide to share your User
        Content with others through our Services or third-party platforms, you
        understand that this User Content will be viewable by others. You are
        solely responsible for your User Content and for your use of any
        Interactive Areas.
      </p>
      <p>
        You will not post, upload to, transmit, distribute, store, create or
        otherwise publish or send through our Services any of the following:
      </p>
      <ul>
        <li>
          User Content that is unlawful, libelous, defamatory, obscene,
          pornographic, indecent, lewd, suggestive, harassing, threatening,
          invasive of privacy or publicity rights, abusive, inflammatory,
          fraudulent, false, misleading or otherwise objectionable;
        </li>
        <li>
          User Content that would constitute, encourage or provide instructions
          for a criminal offense, violate the rights of any party or otherwise
          create liability or violate any local, state, national or
          international law;
        </li>
        <li>
          User Content that may infringe any patent, trademark, trade secret,
          copyright or other proprietary right of any party;
        </li>
        <li>
          User Content that contains or depicts any statements, remarks or
          claims that do not reflect your honest views and experiences;
        </li>
        <li>
          User Content that contains personal information about any person,
          including, without limitation, names, addresses, email address or
          credit card information without that person&rsquo;s permission;
        </li>
        <li>
          User Content that impersonates or misrepresents your affiliation with
          any person or entity;
        </li>
        <li>
          Unsolicited promotions, political campaigning, advertising or
          solicitations;
        </li>
        <li>
          Viruses, corrupted data or other harmful, disruptive or destructive
          files or content; or
        </li>
        <li>
          User Content that, in the sole judgment of KANO, is objectionable or
          which restricts or inhibits any other person from using or enjoying
          the Interactive Areas or our Services, or which may expose KANO or its
          users to any harm or liability of any type.
        </li>
      </ul>
      <p>
        KANO does not control, take responsibility for or assume liability for
        any User Content posted, stored or uploaded by you or any third party,
        or for any loss or damage to such User Content. When you participate in
        Interactive Areas, you understand that certain User Content you choose
        to post may be displayed publicly or to select users. You are solely
        responsible for your use of our Services and the Interactive Areas and
        use them at your own risk.
      </p>
      <ol start={11}>
        <li>
          <strong> Rights to User Content</strong>
        </li>
      </ol>
      <p>
        If you submit or post User Content to our Services, unless KANO
        indicates otherwise, you grant KANO a worldwide, nonexclusive,
        perpetual, irrevocable, royalty-free, fully-paid up, and sub-licensable
        license to use, reproduce, sell, modify, adapt, publish, translate,
        create derivative works from, distribute, publicly perform and display
        your User Content in any media format and channel now known or later
        developed without compensation to you, including in connection with
        KANO&rsquo;s marketing and promotional activities. You further grant
        KANO and KANO&rsquo;s sublicensees the right to use the name that you
        submit in connection with User Content, if they choose.
      </p>
      <p>
        By submitting or posting User Content to our Services, you represent and
        warrant that: (a)&nbsp;such User Content is non-confidential;
        (b)&nbsp;you own and control all of the rights to the User Content that
        you post or you otherwise have all necessary rights to post such User
        Content on our Services; (c)&nbsp;the User Content is accurate and not
        misleading or harmful in any manner; and (d)&nbsp;the User Content, and
        your use and posting thereof in connection with our Services, does not
        and will not violate these Terms or any applicable law, rule, regulation
        or third-party right.
      </p>
      <ol start={12}>
        <li>
          <strong> Acceptable Use of Our Services</strong>
        </li>
      </ol>
      <p>
        Your use of our Services will not violate any law, contract,
        intellectual property or other third-party right or constitute a tort,
        and you are solely responsible for your conduct while on our Services.
        You will not:
      </p>
      <ul>
        <li>
          Use our Services in any manner that could interfere with, disrupt,
          negatively affect or inhibit other users from fully enjoying our
          Services, or that could damage, disable, overburden or impair the
          functioning of our Services in any manner;
        </li>
        <li>
          Engage in any discriminatory, defamatory, hateful, harassing, abusive,
          obscene, threatening, physically dangerous, or otherwise objectionable
          conduct;
        </li>
        <li>
          Attempt to indicate in any manner that you have a relationship with
          KANO or that KANO has endorsed you or any services for any purpose
          unless KANO has provided written permission to do so;
        </li>
        <li>
          Send any unsolicited or unauthorized advertising, solicitations,
          promotional materials, spam, junk mail, chain letters or pyramid
          schemes, or harvest or collect the email addresses or other contact
          information of other users for the purpose of sending spam or other
          commercial messages;
        </li>
        <li>
          Attempt to reverse engineer any aspect of our Services or do anything
          that might discover source code or bypass or circumvent measures
          employed to prevent or limit access to any area, content or code of
          our Services (except as otherwise expressly permitted by law);
        </li>
        <li>
          Access, use or attempt to access or use another's account without
          authorization from such user and KANO;
        </li>
        <li>
          Develop any third-party application that interacts with User Content
          or our Services without KANO&rsquo;s prior written consent;
        </li>
        <li>
          Use any robot, iframe, spider, crawler, scraper or other automated
          means or interface not provided or authorized by KANO to access out
          Services, including, without limitation, for the purpose of copying,
          extracting, aggregating, displaying, publishing or distributing any
          content or data made available via our Services;
        </li>
        <li>
          Bypass or ignore instructions contained in the robots.txt file,
          accessible at&nbsp;
          <a href="http://www.kanotrain.com/robots.txt">KANO.com/robots.txt</a>,
          that controls all automated access to our Services; or
        </li>
        <li>
          Use our Services for any illegal or unauthorized purpose, or engage
          in, encourage or promote any activity that violates these Terms.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start={13}>
        <li>
          <strong> Indemnification</strong>
        </li>
      </ol>
      <p>
        You will defend, indemnify and hold harmless KANO, its independent
        contractors, service providers and consultants, and their respective
        directors, officers, employees and agents (collectively, &ldquo;
        <strong>
          <em>KANO Parties</em>
        </strong>
        &rdquo;), from and against any claims, damages, costs, liabilities and
        expenses (including, but not limited to, reasonable attorneys' fees)
        arising out of or related to (a)&nbsp;your access to or use of our
        Services; (b)&nbsp;any User Content or Feedback you provide;
        (c)&nbsp;your violation of these Terms; (d)&nbsp;your violation,
        misappropriation or infringement of any rights of another (including
        intellectual property rights or privacy rights); or (e)&nbsp;your
        conduct in connection with our Services.
      </p>
      <ol start={15}>
        <li>
          <strong> Disclaimers</strong>
        </li>
      </ol>
      <p>
        <strong>
          We do not control, endorse or take responsibility for any third-party
          content available on or linked to by our Services, including User
          Content.
        </strong>
      </p>
      <p>
        <strong>
          Your use of our Services is at your sole risk. Our Services are
          provided &ldquo;as is&rdquo; and &ldquo;as available&rdquo; without
          warranties of any kind, either express or implied, including, but not
          limited to, implied warranties of merchantability, fitness for a
          particular purpose, title, and non-infringement. In addition, KANO
          does not represent or warrant that our Services are accurate,
          complete, reliable, current or error-free. While KANO attempts to make
          your access to and use of our Services safe, we cannot and do not
          represent or warrant that our Services or servers are free of viruses
          or other harmful components. You assume the entire risk as to the
          quality and performance of the Services.
        </strong>
      </p>
      <ol start={16}>
        <li>
          <strong> Limitation of Liability</strong>
        </li>
      </ol>
      <p>
        <strong>
          KANO and the other KANO Parties will not be liable to you under any
          theory of liability&mdash;whether based in contract, tort, negligence,
          strict liability, warranty, or otherwise&mdash;for any indirect,
          consequential, exemplary, incidental, special or punitive damages or
          lost profits, to the fullest extent permitted by the applicable law,
          even if KANO or the other KANO Parties have been advised of the
          possibility of such damages. The limitations of liability set for in
          this section will apply even if the remedies otherwise provided under
          these Terms, at law or in equity, fail of their essential purpose.
        </strong>
      </p>
      <p>
        <strong>
          The total liability of KANO and the other KANO Parties, for any claim
          arising out of or relating to these Terms or our Services, regardless
          of the form of the action, is limited to the greater of (a)&nbsp;the
          amount paid, if any, by you to access or use our Services or
          (b)&nbsp;$100.
        </strong>
      </p>
      <p>
        <strong>
          The limitations set forth in this section will not limit or exclude
          liability for the gross negligence, fraud or intentional misconduct of
          KANO or the other KANO Parties.
        </strong>
      </p>
      <ol start={17}>
        <li>
          <strong> Assumption of Risk</strong>
        </li>
      </ol>
      <p>
        You assume all risks when using our Services, including all of the risks
        associated with interactions with other users. You agree to take all
        necessary precautions when interacting with other users.
      </p>
      <ol start={18}>
        <li>
          <strong> Modifications to Our Services</strong>
        </li>
      </ol>
      <p>
        KANO reserves the right to modify or discontinue, temporarily or
        permanently, our Services, or any features or portions thereof, without
        prior notice. You agree that KANO will not be liable for any
        modification, suspension or discontinuance of our Services, or any part
        thereof.
      </p>
      <ol start={19}>
        <li>
          <strong> Dispute Resolution; Arbitration</strong>
        </li>
      </ol>
      <p>
        <strong>
          PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO
          ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH KANO AND LIMITS THE MANNER
          IN WHICH YOU CAN SEEK RELIEF FROM US.
        </strong>
      </p>
      <p>
        <strong>19.1. Binding Arbitration</strong>
      </p>
      <p>
        Except for any disputes, claims, suits, actions, causes of action,
        demands or proceedings (collectively, &ldquo;Disputes&rdquo;) arising
        out of or related to a violation of Sections 10 or 12 or Disputes in
        which either party seeks to bring an individual action in small claims
        court or seeks injunctive or other equitable relief for the alleged
        unlawful use of intellectual property, including, without limitation,
        copyrights, trademarks, trade names, logos, trade secrets or patents,
        you and KANO agree (a)&nbsp;to waive your and KANO&rsquo;s respective
        rights to have any and all Disputes arising from or related to these
        Terms, our Services or the products and services available on our
        Services, resolved in a court, and (b)&nbsp;to waive your and
        KANO&rsquo;s respective rights to a jury trial. Instead, you and KANO
        agree to arbitrate Disputes through binding arbitration (which is the
        referral of a Dispute to one or more persons charged with reviewing the
        Dispute and making a final and binding determination to resolve it
        instead of having the Dispute decided by a judge or jury in court).
      </p>
      <p>
        <strong>
          19.2. No Class Arbitrations, Class Actions or Representative Actions
        </strong>
      </p>
      <p>
        You and KANO agree that any Dispute arising out of or related to these
        Terms, our Services or the products and services available on our
        Services is personal to you and KANO and that such Dispute will be
        resolved solely through individual arbitration and will not be brought
        as a class arbitration, class action or any other type of representative
        proceeding. You and KANO agree that there will be no class arbitration
        or arbitration in which an individual attempts to resolve a Dispute as a
        representative of another individual or group of individuals. Further,
        you and KANO agree that a Dispute cannot be brought as a class or other
        type of representative action, whether within or outside of arbitration,
        or on behalf of any other individual or group of individuals.
      </p>
      <p>
        <strong>19.3. Federal Arbitration Act</strong>
      </p>
      <p>
        You and KANO agree that these Terms affect interstate commerce and that
        the enforceability of this Section 19 will be both substantively and
        procedurally governed by and construed and enforced in accordance with
        the Federal Arbitration Act, 9 U.S.C. &sect; 1 et seq. (the &ldquo;
        <strong>
          <em>FAA</em>
        </strong>
        &rdquo;), to the maximum extent permitted by applicable law.
      </p>
      <p>
        <strong>19.4. Notice; Informal Dispute Resolution</strong>
      </p>
      <p>
        You and KANO agree that each party will notify the other party in
        writing of any arbitrable or small claims Dispute within thirty (30)
        days of the date it arises, so that the parties can attempt in good
        faith to resolve the Dispute informally. Notice to KANO will be sent by
        certified mail or courier to 182 St. Francis St. Suite 301 Mobile, AL
        36602. Your notice must include (a)&nbsp;your name, postal address,
        telephone number, the email address you use or used for your KANO
        account and, if different, an email address at which you can be
        contacted, (b)&nbsp;a description in reasonable detail of the nature or
        basis of the Dispute, and (c)&nbsp;the specific relief that you are
        seeking. Our notice to you will be sent electronically and will include
        (x)&nbsp;our name, postal address, telephone number and an email address
        at which we can be contacted with respect to the Dispute, (y)&nbsp;a
        description in reasonable detail of the nature or basis of the Dispute,
        and (z)&nbsp;the specific relief that we are seeking. If you and KANO
        cannot agree how to resolve the Dispute within thirty (30) days after
        the date notice is received by the applicable party, then either you or
        KANO may, as appropriate and in accordance with this Section 19,
        commence an arbitration proceeding or, to the extent specifically
        provided for in Section 19.1, file a claim in court.
      </p>
      <p>
        <strong>19.5. Process</strong>
      </p>
      <p>
        Except for Disputes arising out of or related to a violation of Sections
        10 or 12 or Disputes in which either party seeks to bring an individual
        action in small claims court or seeks injunctive or other equitable
        relief for the alleged unlawful use of intellectual property, including,
        without limitation, copyrights, trademarks, trade names, logos, trade
        secrets or patents, you and KANO agree that any Dispute must be
        commenced or filed by you or KANO within one (1) year of the date the
        Dispute arose, otherwise the underlying claim is permanently barred
        (which means that you and KANO will no longer have the right to assert
        such claim regarding the Dispute). You and KANO agree that (a)&nbsp;any
        arbitration will occur in Mobile, Alabama, (b)&nbsp;arbitration will be
        conducted confidentially by a single arbitrator in accordance with the
        rules of the Judicial Arbitration and Mediation Services
        (&ldquo;JAMS&rdquo;), which are hereby incorporated by reference, and
        (c)&nbsp;that the state or federal courts of the State of Alabama and
        the United States, respectively, sitting in Mobile, Alabama, have
        exclusive jurisdiction over any appeals and the enforcement of an
        arbitration award. You may also litigate a Dispute in the small claims
        court located in the county of your billing address if the Dispute meets
        the requirements to be heard in small claims court.
      </p>
      <p>
        <strong>19.6. Authority of Arbitrator</strong>
      </p>
      <p>
        As limited by the FAA, these Terms and the applicable JAMS rules, the
        arbitrator will have (a)&nbsp;the exclusive authority and jurisdiction
        to make all procedural and substantive decisions regarding a Dispute,
        including the determination of whether a Dispute is arbitrable, and
        (b)&nbsp;the authority to grant any remedy that would otherwise be
        available in court; provided, however, that the arbitrator does not have
        the authority to conduct a class arbitration or a representative action,
        which is prohibited by these Terms. The arbitrator may only conduct an
        individual arbitration and may not consolidate more than one
        individual&rsquo;s claims, preside over any type of class or
        representative proceeding or preside over any proceeding involving more
        than one individual.
      </p>
      <p>
        <strong>19.7. Rules of JAMS</strong>
      </p>
      <p>
        The rules of JAMS and additional information about JAMS are available on
        the&nbsp;<a href="http://www.jamsadr.com/">JAMS website</a>. By agreeing
        to be bound by these Terms, you either (a)&nbsp;acknowledge and agree
        that you have read and understand the rules of JAMS, or (b)&nbsp;waive
        your opportunity to read the rules of JAMS and any claim that the rules
        of JAMS are unfair or should not apply for any reason.
      </p>
      <p>
        <strong>19.8. Severability</strong>
      </p>
      <p>
        If any term, clause or provision of this Section 19 is held invalid or
        unenforceable, it will be so held to the minimum extent required by law,
        and all other terms, clauses and provisions of this Section 19 will
        remain valid and enforceable. Further, the waivers set forth in Section
        19.2 are severable from the other provisions of these Terms and will
        remain valid and enforceable, except as prohibited by applicable law.
      </p>
      <ol start={20}>
        <li>
          <strong> Applicable Law and Venue</strong>
        </li>
      </ol>
      <p>
        These Terms and your use of our Services will be governed by and
        construed in accordance with the laws of Mobile, AL. without resort to
        its conflict of law provisions. To the extent the arbitration provision
        in Section 19 does not apply, you agree that any action at law or in
        equity arising out of or relating to these Terms will be filed only in
        the state and federal courts located in Mobile, Alabama and you hereby
        irrevocably and unconditionally consent and submit to the exclusive
        jurisdiction of such courts over any suit, action or proceeding arising
        out of these Terms.
      </p>
      <ol start={21}>
        <li>
          <strong> Termination</strong>
        </li>
      </ol>
      <p>
        KANO reserves the right, without notice and in its sole discretion, to
        terminate your license to access and use our Services and to block or
        prevent your future access to and use of our Services.
      </p>
      <ol start={22}>
        <li>
          <strong> Changes to These Terms</strong>
        </li>
      </ol>
      <p>
        We may make changes to these Terms from time to time. If we make
        changes, we will post the amended Terms to our Services and update the
        &ldquo;Last Updated&rdquo; date above. We may also attempt to notify you
        by sending an email notification to the address associated with your
        account or providing notice through our Services. Unless we say
        otherwise in our notice, the amended Terms will be effective immediately
        and your continued access to and use of our Services after we provide
        notice will confirm your acceptance of the changes. If you do not agree
        to the amended Terms, you must stop accessing and using our Services.
      </p>
      <ol start={23}>
        <li>
          <strong> Severability</strong>
        </li>
      </ol>
      <p>
        If any provision of these Terms will be deemed unlawful, void or
        unenforceable, then that provision will be deemed severable from these
        Terms and will not affect the validity and enforceability of any
        remaining provisions.
      </p>
      <ol start={24}>
        <li>
          <strong> Notice for California Users</strong>
        </li>
      </ol>
      <p>
        Under California Civil Code Section 1789.3, California users of our
        Services are entitled to the following consumer rights notice: The
        Complaint Assistance Unit of the Division of Consumer Services of the
        California Department of Consumer Affairs may be contacted in writing at
        1625 N. Market Blvd., Suite N-112, Sacramento, California 95834-1924, or
        by telephone at (800) 952-5210.
      </p>
      <ol start={25}>
        <li>
          <strong> Miscellaneous</strong>
        </li>
      </ol>
      <p>
        These Terms constitute the entire agreement between you and KANO
        relating to your access to and use of our Services and your order,
        receipt and use of products or services made available through our
        Services. These Terms, and any rights and licenses granted hereunder,
        may not be transferred or assigned by you without the prior written
        consent of KANO. No waiver of any provision of these Terms will
        constitute a waiver of such provision in any prior, concurrent or
        subsequent circumstance, and KANO&rsquo;s failure to assert any right or
        provision under these Terms will not constitute a waiver of such right
        or provision. Except as otherwise provided herein, these Terms are
        intended solely for the benefit of the parties and are not intended to
        confer third party beneficiary rights upon any other person or entity.
      </p>
      <p>
        <strong>Questions &amp; Contact Information</strong>
      </p>
      <p>
        Questions or comments about our Services may be directed to our&nbsp;
        <u>
          <a href="https://www.kanotrain.com/contact">contact page</a>
        </u>
        .
      </p>
    </>
  );
};

export default TermsOfServiceContent;
