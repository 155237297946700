import Endpoints from "Sdk/Config/Endpoints";
import { ITrainingCampPackage } from "Sdk/Data/Models/TrainingCampPackage";
import { ITrainingCourseSessionPackage } from "Sdk/Data/Models/TrainingCourseSessionPackage";
import Request from "Sdk/Services/Request";
import ApiResponse from "Sdk/Utils/ApiResponse";

async function PurchaseSessionPackageWithoutCheckout(
  sessionPackage: ITrainingCourseSessionPackage
): Promise<ApiResponse<{ secret: string; totalAmount: number }>> {
  let endpoint = Endpoints.subscription.purchaseSessionPackageWithoutCheckout.clone();
  endpoint.Url.searchParams.set("sessionPackageId", sessionPackage.Id!);

  return Request.post<{ secret: string; totalAmount: number }>(endpoint, {});
}

async function PurchaseCampPackageWithoutCheckout(
  campPackage: ITrainingCampPackage
): Promise<ApiResponse<{ secret: string; totalAmount: number }>> {
  let endpoint = Endpoints.subscription.purchaseCampPackageWithoutCheckout.clone();
  endpoint.Url.searchParams.set("campPackageId", campPackage.Id!);

  return Request.post<{ secret: string; totalAmount: number }>(endpoint, {});
}

const SubscriptionController = {
  PurchaseSessionPackageWithoutCheckout,
  PurchaseCampPackageWithoutCheckout,
};

export default SubscriptionController;
