export default class Env {
  private constructor() {}

  public static get DeviceTokenClientName(): string {
    return process.env.REACT_APP_DEVICE_TOKEN_CLIENT_NAME as string;
  }
  public static get DeviceTokenClientId(): string {
    return process.env.REACT_APP_DEVICE_TOKEN_CLIENT_ID as string;
  }
}
