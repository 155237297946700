import React from "react";
import {
  Box,
  CircularProgress,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Zoom,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

//#region Properties
export interface AnimatedSuccessProps {
  doneCallback: () => void;
}
interface Props extends AnimatedSuccessProps, WithStyles<typeof styles> {}
//#endregion

interface State {
  progress: number;
}

function noop() {}
//#region Component
class AnimatedSuccess extends React.Component<Props, State> {
  private doneCallback: () => void;

  componentDidMount() {
    let timer = window.setInterval(() => {
      this.setState({ progress: this.state.progress + 10 });

      if (this.state.progress >= 100) {
        this.done();

        window.clearInterval(timer);
      }
    }, 50);
  }

  constructor(props: Props) {
    super(props);

    this.doneCallback = this.props.doneCallback ?? noop;

    this.state = { progress: 10 };
  }

  private done() {
    let timer = window.setInterval(() => {
      this.doneCallback();

      window.clearInterval(timer);
    }, 500);
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Box className={classes.outerContainer}>
          <Box className={classes.innerContainer}>
            <CircularProgress
              size={100}
              variant="determinate"
              value={this.state.progress}
              className={classes.progress}
            />
            <Zoom
              in={this.state.progress >= 100}
              style={{ transitionDelay: "100ms" }}
            >
              <CheckCircle color="primary" className={classes.icon} />
            </Zoom>
          </Box>
        </Box>
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    outerContainer: {
      display: "table-cell",
    },
    innerContainer: {
      position: "relative",
      display: "inline-block",
    },
    progress: {
      display: "block",
    },
    icon: {
      position: "absolute",
      fontSize: "80px",
      top: "10px",
      left: "10px",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(AnimatedSuccess);
