import UserFriendlyError from "Utils/UserFriendlyError";
import ErrorMessage from "./ErrorMessage";

export default class ApiResponse<DataType> {
  public data?: DataType;
  public error?: UserFriendlyError;

  public get wasSuccess(): boolean {
    return !this.error;
  }
  public set wasSuccess(value: boolean) {
    if (value) {
      this.error = undefined;
    } else if (this.wasSuccess) {
      this.error = new UserFriendlyError(
        ErrorMessage.cat(ErrorMessage.unknownError, ErrorMessage.devAlert)
      );
    }
  }

  constructor(error?: string, data?: DataType) {
    if (!!error) {
      this.error = new UserFriendlyError(error);
    }
    this.data = data;
  }
}
