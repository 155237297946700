import ApiResponse from "../Utils/ApiResponse";
import ErrorMessage from "../Utils/ErrorMessage";
import { IApiEndpoint } from "../Config/Endpoints";
import getReferrer from "../Utils/getReferrer";
import AuthenticationType from "Sdk/Data/Enums/AuthenticationType";
import TokenManager from "./TokenManager";
import _merge from "lodash-es/merge";
import HttpStatusCode from "Sdk/Data/Enums/HttpStatusCode";
import { jsonIgnoreReplacer } from "json-ignore";
import processError from "Logging/processError";

let defaultConfig: RequestInit = {
  redirect: "error",
  mode: "cors",
  referrerPolicy: "origin",
  referrer: getReferrer(),
  credentials: "include",
  headers: {
    Accept: "application/json",
  },
};

let postConfig: RequestInit = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

const defaultServerErrorMessage = ErrorMessage.cat(
  ErrorMessage.serverError,
  ErrorMessage.devAlert
);

async function wrapRequest<T>(
  endpoint: IApiEndpoint,
  makeRequest: (url: string, config: RequestInit) => Promise<Response>,
  abortSignal?: AbortSignal
): Promise<ApiResponse<T>> {
  try {
    let config: RequestInit = { ...defaultConfig };

    if (abortSignal) {
      config.signal = abortSignal;
    }

    await addBearerAuthHeader(config, endpoint.AuthenticationType);

    let response = await makeRequest(endpoint.Url.toString(), config);
    if (!response.ok) {
      let error = await response.json();
      // HACK: Temporary workaround for web registration with an existing account
      // Correct fix - make API return a `UserFriendlyErrorMessage` object, so we know when to display the error directly
      if (response.status === HttpStatusCode.Conflict) {
        return new ApiResponse<T>(error, undefined);
      }

      return new ApiResponse<T>(
        (error.userFriendlyErrorMessage as string) ?? defaultServerErrorMessage,
        undefined
      );
    }

    let data: T | undefined = undefined;
    if (response.status !== HttpStatusCode.NoContent)
      data = (await response.json()) as T;

    return new ApiResponse<T>(undefined, data);
  } catch (error) {
    processError(error);
    return new ApiResponse<T>(ErrorMessage.networkError, undefined);
  }
}

async function get<T>(
  endpoint: IApiEndpoint,
  overrideConfig?: RequestInit,
  abortSignal?: AbortSignal
): Promise<ApiResponse<T>> {
  return await wrapRequest<T>(
    endpoint,
    async (url, config) => await fetch(url, _merge(config, overrideConfig)),
    abortSignal
  );
}

async function post<T = boolean>(
  endpoint: IApiEndpoint,
  data: any,
  overrideConfig?: RequestInit,
  abortSignal?: AbortSignal
): Promise<ApiResponse<T>> {
  return await wrapRequest<T>(
    endpoint,
    async (url, config) =>
      await fetch(
        url,
        _merge(
          config,
          postConfig,
          {
            body: JSON.stringify(data, jsonIgnoreReplacer),
          },
          overrideConfig
        )
      ),
    abortSignal
  );
}

async function put<T = boolean>(
  endpoint: IApiEndpoint,
  data: any,
  overrideConfig?: RequestInit,
  abortSignal?: AbortSignal
): Promise<ApiResponse<T>> {
  return await wrapRequest<T>(
    endpoint,
    async (url, config) =>
      await fetch(
        url,
        _merge(
          config,
          postConfig,
          {
            body: JSON.stringify(data, jsonIgnoreReplacer),
            method: "PUT",
          },
          overrideConfig
        )
      ),
    abortSignal
  );
}

function setReferrer(referrer: string) {
  defaultConfig.referrer = referrer;
}

async function addBearerAuthHeader(
  config: RequestInit,
  authType: AuthenticationType
) {
  let token: string | undefined = undefined;

  // TODO: Better error handling
  if (authType === AuthenticationType.None) {
    return;
  } else if (authType === AuthenticationType.DeviceToken) {
    let error = await TokenManager.ensureDeviceTokenValid();
    if (!!error) {
      throw error;
    }

    token = await TokenManager.getDeviceTokenString();
  } else if (authType === AuthenticationType.UserToken) {
    let error = await TokenManager.ensureUserTokenValid();
    if (!!error) {
      throw error;
    }

    token = await TokenManager.getUserTokenString();
  }
  if (token === undefined)
    throw new Error(
      ErrorMessage.cat(ErrorMessage.unknownError, ErrorMessage.devAlert)
    );

  _merge(config.headers, {
    Authorization: `Bearer ${token}`,
  });
}

const Request = {
  get,
  post,
  put,
  setReferrer,
};

export default Request;
