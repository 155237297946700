import { init as initApm } from "@elastic/apm-rum";
import packageJson from "package.alias.json";

const apm = initApm({
  serviceName: "train-website",
  serverUrl: "https://kano-train.apm.centralus.azure.elastic-cloud.com",
  serviceVersion: packageJson.version,
  environment: process.env.NODE_ENV,
  distributedTracingOrigins: [
    /https:\/\/(.*\.)?kanoperformance\.com/,
    /http:\/\/localhost(:\d+)?/,
  ],
  propagateTracestate: true,
});

export function addApmErrorHandlers() {
  window.addEventListener("error", (event) => {
    try {
      console.log("*** Logging Error ***");
      if (process.env.NODE_ENV !== "production") {
        console.error(event.message);
      }
      apm.captureError(event.error);
    } catch (e) {
      console.error("Error logging to APM: " + e.toString());
    }
  });

  window.addEventListener("unhandledrejection", (event) => {
    try {
      console.log("*** Logging Rejected Promise ***");
      if (process.env.NODE_ENV !== "production") {
        console.error(JSON.stringify(event.reason));
      }
      if (event.reason instanceof String) {
        apm.captureError(event.reason as string);
      } else if (event.reason instanceof Error) {
        apm.captureError(event.reason);
      } else {
        apm.captureError(JSON.stringify(event.reason));
      }
    } catch (e) {
      console.error("Error logging to APM: " + e.toString());
    }
  });
}

export default apm;
