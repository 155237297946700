import React from "react";
import { Box, TextField } from "@material-ui/core";
import ProfileInfo from "../../Data/ProfileInfo";
import UserFriendlyError from "Utils/UserFriendlyError";
import RegistrationStepComponent from "../RegistrationStepComponent";
import classes from "../Steps.module.css";
import Validators from "Sdk/Utils/Validators";
import PasswordInput from "Components/Inputs/PasswordInput";
// import AvatarPicker from "Components/Inputs/AvatarPicker";

export interface ProfileInfoStepComponentProps {
  name: string;
}

interface State extends Partial<ProfileInfo> {}

//#region Component
export default class ProfileInfoStepComponent
  extends React.Component<ProfileInfoStepComponentProps, State>
  implements RegistrationStepComponent<ProfileInfo> {
  public StepTitle = "Login Information";

  constructor(props: ProfileInfoStepComponentProps) {
    super(props);

    if (process.env.NODE_ENV === "development") {
      this.state = {
        Email: "debug_signup@example.com",
        ConfirmEmail: "debug_signup@example.com",
        Password: "testtest",
        ConfirmPassword: "testtest",
      };
    } else {
      this.state = {};
    }
  }

  public getData(): ProfileInfo | UserFriendlyError {
    let state: Partial<ProfileInfo> = this.state;

    if (!state.Email || !Validators.emailRegex.test(state.Email)) {
      return new UserFriendlyError(
        "That email doesn't look quite right to me 🤔"
      );
    } else if (!state.ConfirmEmail || state.Email !== state.ConfirmEmail) {
      return new UserFriendlyError(
        "Your email doesn't match your email confirmation. Please double-check it"
      );
    } else if (!state.Password || state.Password.length < 8) {
      return new UserFriendlyError(
        "Please enter a password that's at least eight characters"
      );
    } else if (
      !state.ConfirmPassword ||
      state.Password !== state.ConfirmPassword
    ) {
      return new UserFriendlyError(
        "Your password confirmation doesn't match your password! Please try again"
      );
    }

    // This is used to keep typescript validation in place
    // `return state as LoginInfo` would prevent it from warning us about forgetting to validate new properties
    return {
      Email: state.Email,
      ConfirmEmail: state.ConfirmEmail,
      Password: state.Password,
      ConfirmPassword: state.ConfirmPassword,
      ProfilePicture: state.ProfilePicture,
    };
  }

  render() {
    return (
      <>
        {/* <AvatarPicker
          height={10}
          name={this.props.name}
          className={classes.avatar}
        /> */}
        <Box className={classes.root}>
          <TextField
            required
            className={classes.field}
            type="email"
            variant="filled"
            label="Email Address"
            value={this.state.Email}
            onChange={(event) => this.setState({ Email: event.target.value })}
          />
          <TextField
            required
            className={classes.field}
            type="email"
            variant="filled"
            label="Confirm Email"
            value={this.state.ConfirmEmail}
            onChange={(event) =>
              this.setState({ ConfirmEmail: event.target.value })
            }
          />
        </Box>
        <Box className={classes.root}>
          <PasswordInput
            required
            className={classes.field}
            value={this.state.Password}
            onChange={(event) =>
              this.setState({ Password: event.target.value })
            }
          />
          <PasswordInput
            required
            label="Confirm Password"
            className={classes.field}
            value={this.state.ConfirmPassword}
            onChange={(event) =>
              this.setState({ ConfirmPassword: event.target.value })
            }
          />
        </Box>
      </>
    );
  }
}
//#endregion
