import { Guid } from "guid-typescript";
import UserType from "../Enums/UserType";

export interface IWaitListEntry {
  Id?: Guid;
  Email: string;
  UserType: UserType;
}

export default class WaitListEntry implements IWaitListEntry {
  private id?: Guid;
  private email: string;
  private userType: UserType;

  //#region Props
  public get Id(): Guid | undefined {
    return this.id;
  }
  public set Id(value: Guid | undefined) {
    this.id = value;
  }

  public get Email(): string {
    return this.email;
  }
  public set Email(value: string) {
    this.email = value;
  }

  public get UserType(): UserType {
    return this.userType;
  }
  public set UserType(value: UserType) {
    this.userType = value;
  }
  //#endregion

  constructor(email: string, userType: UserType) {
    this.email = email;
    this.userType = userType;
  }
}
