import React from "react";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import BaseButton, { BaseButtonProps } from "./BaseButton/BaseButton";

//#region Properties
export interface SecondaryButtonProps
  extends Omit<BaseButtonProps, "variant" | "color"> {}

interface Props extends SecondaryButtonProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class SecondaryButton extends React.Component<Props> {
  render() {
    // const { classes } = this.props;

    return (
      <BaseButton
        {...this.props}
        variant="outlined"
        className={`classes.root ${this.props.className}`}
      />
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(SecondaryButton);
