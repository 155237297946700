const devAlert = "Our developers have been alerted.";
const serverError =
  "The server encountered an unknown error processing your request";
const networkError =
  "There was a network error while sending the request. Please check your network connection and try again.";
const unknownError = "There was an unknown eror.";

function cat(...messageParts: string[]): string {
  return messageParts.join(" ");
}

var ErrorMessage = {
  devAlert,
  serverError,
  networkError,
  unknownError,
  cat,
};

export default ErrorMessage;
