import Endpoints from "Sdk/Config/Endpoints";
import { ICoach } from "Sdk/Data/Models/Coach";
import ApiResponse from "Sdk/Utils/ApiResponse";
import Request from "Sdk/Services/Request";
import { IUserDistance } from "Sdk/Data/Models/UserDistance";
import { ITrainingCourse } from "Sdk/Data/Models/TrainingCourse";
import { IPlayer } from "Sdk/Data/Models/Player";
import { IPlayerCoach } from "Sdk/Data/Models/PlayerCoach";

async function getCoachFromEmail(
  email: string,
  hydrate: boolean = true
): Promise<ApiResponse<ICoach>> {
  let getCoachEndpoint = Endpoints.coach.getCoachFromEmail.clone();
  getCoachEndpoint.Url.searchParams.append("email", email);
  getCoachEndpoint.Url.searchParams.append("hydrate", hydrate.toString());

  return await Request.get<ICoach>(getCoachEndpoint);
}

async function getNearbyCoaches(
  zipCode: string,
  distanceInMiles: number,
  organizationId?: string
): Promise<ApiResponse<IUserDistance<ICoach>[]>> {
  let endpoint = Endpoints.user.getNearbyCoaches.clone();
  endpoint.Url.searchParams.append("zipcode", zipCode);
  endpoint.Url.searchParams.append(
    "distanceInMiles",
    distanceInMiles.toString()
  );
  if (organizationId) {
    endpoint.Url.searchParams.append("organizationId", organizationId);
  }

  return await Request.get<IUserDistance<ICoach>[]>(endpoint);
}

async function getCourses(
  coach: ICoach,
  ignoreDisabled: boolean = true
): Promise<ApiResponse<ITrainingCourse[]>> {
  let endpoint = Endpoints.trainingCourse.root.clone();
  endpoint.Url.searchParams.append("coachId", coach.Id!.toString());
  endpoint.Url.searchParams.append("ignoreDisabled", ignoreDisabled.toString());

  return await Request.get<ITrainingCourse[]>(endpoint);
}

async function getCoachesForPlayer(
  player: IPlayer
): Promise<ApiResponse<IPlayerCoach[]>> {
  let endpoint = Endpoints.playerCoach.forPlayer;

  return await Request.get<IPlayerCoach[]>(endpoint);
}

async function getStripeToken(coach: ICoach): Promise<ApiResponse<string>> {
  let endpoint = Endpoints.coach.getStripeToken.clone();
  endpoint.Url.searchParams.set("coachId", coach.Id!);

  return await Request.get<string>(endpoint);
}

const CoachesController = {
  getCoachFromEmail,
  getNearbyCoaches,
  getCourses,
  getCoachesForPlayer,
  getStripeToken,
};

export default CoachesController;
