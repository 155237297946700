import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import PrimaryButton from "Components/PrimaryButton";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

export interface DownloadPromptProps {
  onCloseTapped: () => void;
}

interface Props extends DownloadPromptProps, WithStyles<typeof styles> {}

class DownloadPrompt extends React.Component<Props> {
  private openAppStore() {
    window.open("https://apps.apple.com/us/app/kano-train/id1469823586");
  }
  private openPlayStore() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.kanoperformance.train"
    );
  }

  render() {
    const { classes, onCloseTapped } = this.props;

    return (
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          action={
            <IconButton
              aria-label="close"
              color="secondary"
              onClick={onCloseTapped}
            >
              <Clear />
            </IconButton>
          }
        />

        <CardContent className={classes.content}>
          <Typography variant="h4" color="textPrimary">
            Kano Train is in closed beta.
          </Typography>
          <Typography color="textSecondary">
            If you have an invite code from your coach, download the app to sign
            up.
          </Typography>
        </CardContent>

        <CardActions>
          <PrimaryButton
            type="submit"
            text="View on Apple App Store"
            onClick={this.openAppStore}
          />
          <PrimaryButton
            type="submit"
            text="View on Google Play  Store"
            onClick={this.openPlayStore}
          />
        </CardActions>
      </Card>
    );
  }
}

//#region Styling
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "5em",
        paddingRight: "5em",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1.25em",
        paddingRight: "1.25em",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "3em",
        paddingRight: "3em",
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "1em",
        paddingRight: "1em",
      },
    },
    content: {
      width: "100%",
    },
  });
};

export default withStyles(styles, { withTheme: true })(DownloadPrompt);
