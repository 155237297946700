function treatAsUTC(date: Date): Date {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

function treatAsUTCDayOnly(date: Date): Date {
  var result = treatAsUTC(date);
  result.setHours(0, 0, 0, 0);
  return result;
}

export function DaysBetweenCalendarDates(startDate: Date, endDate: Date) {
  var millisecondsPerDay = 24 * 60 * 60 * 1000;
  const fractionalResult =
    (treatAsUTCDayOnly(endDate).getTime() -
      treatAsUTCDayOnly(startDate).getTime()) /
    millisecondsPerDay;

  return Math.round(fractionalResult) + 1;
}
