import { jsonIgnore } from "json-ignore";
import ColorHelpers from "Sdk/Utils/ColorHelpers";
import { ITrainingCampEvent } from "./TrainingCampEvent";
import { ITrainingCamp_Coach } from "./TrainingCamp_Coach";

export interface ITrainingCamp {
  Id?: string;
  Enabled: boolean;
  HighlightColor: string;
  HighlightColorArgb: number;
  Name: string;
  Description: string;
  EndDate: Date;
  MinAge: number;
  MaxAge: number;
  TrainingCampEvents: ITrainingCampEvent[];
  TrainingCamp_Coaches?: ITrainingCamp_Coach[];
  HeaderImageUrl?: string;
  StripeId?: string;
}

export default class TrainingCamp implements ITrainingCamp {
  private id?: string;
  private enabled: boolean;
  private highlightColorArgb: number;
  private name: string;
  private description: string;
  private endDate: Date;
  private minAge: number;
  private maxAge: number;
  private trainingCampEvents: ITrainingCampEvent[];
  private trainingCamp_Coaches?: ITrainingCamp_Coach[];
  private headerImageUrl?: string;
  private stripeId?: string;

  //#region Props
  public get Id(): string | undefined {
    return this.id;
  }
  public set Id(value: string | undefined) {
    this.id = value;
  }

  public get Enabled(): boolean {
    return this.enabled;
  }
  public set Enabled(value: boolean) {
    this.enabled = value;
  }

  @jsonIgnore()
  public get HighlightColor(): string {
    return ColorHelpers.toColor(this.HighlightColorArgb);
  }
  public set HighlightColor(value: string) {
    throw new Error("Setting Highlight Color is not yet implemented");
  }

  public get HighlightColorArgb(): number {
    return this.highlightColorArgb;
  }
  public set HighlightColorArgb(value: number) {
    this.highlightColorArgb = value;
  }

  public get Name(): string {
    return this.name;
  }
  public set Name(value: string) {
    this.name = value;
  }

  public get Description(): string {
    return this.description;
  }
  public set Description(value: string) {
    this.description = value;
  }

  public get EndDate(): Date {
    return this.endDate;
  }
  public set EndDate(value: Date) {
    this.endDate = value;
  }

  public get MinAge(): number {
    return this.minAge;
  }
  public set MinAge(value: number) {
    this.minAge = value;
  }

  public get MaxAge(): number {
    return this.maxAge;
  }
  public set MaxAge(value: number) {
    this.maxAge = value;
  }

  public get TrainingCampEvents(): ITrainingCampEvent[] {
    return this.trainingCampEvents;
  }

  public set TrainingCampEvents(value: ITrainingCampEvent[]) {
    this.trainingCampEvents = value;
  }

  public get TrainingCamp_Coaches(): ITrainingCamp_Coach[] | undefined {
    return this.trainingCamp_Coaches;
  }

  public set TrainingCamp_Coaches(value: ITrainingCamp_Coach[] | undefined) {
    this.trainingCamp_Coaches = value;
  }

  public get HeaderImageUrl(): string | undefined {
    return this.headerImageUrl;
  }
  public set HeaderImageUrl(value: string | undefined) {
    this.headerImageUrl = value;
  }

  public get StripeId(): string | undefined {
    return this.stripeId;
  }
  public set StripeId(value: string | undefined) {
    this.stripeId = value;
  }
  //#endregion

  public static createFromInterface(course: ITrainingCamp) {
    return new this(
      course.Enabled,
      course.HighlightColorArgb,
      course.Name,
      course.Description,
      course.EndDate,
      course.MinAge,
      course.MaxAge,
      course.TrainingCampEvents,
      course.HeaderImageUrl,
      course.StripeId,
      course.Id,
      course.TrainingCamp_Coaches
    );
  }

  constructor(
    enabled: boolean,
    highlightColorArgb: number,
    name: string,
    description: string,
    endDate: Date,
    minAge: number,
    maxAge: number,
    trainingCampEvents: ITrainingCampEvent[],
    headerImageUrl?: string,
    stripeId?: string,
    id?: string,
    trainingCamp_Coaches?: ITrainingCamp_Coach[]
  ) {
    this.enabled = enabled;
    this.highlightColorArgb = highlightColorArgb;
    this.name = name;
    this.description = description;
    this.endDate = endDate;
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.trainingCampEvents = trainingCampEvents;
    this.headerImageUrl = headerImageUrl;
    this.stripeId = stripeId;
    this.id = id;
    this.trainingCamp_Coaches = trainingCamp_Coaches;
  }
}
