import React from "react";
import UserFriendlyError from "Utils/UserFriendlyError";
import AthleteInfo from "../../Data/AthleteInfo";
import RegistrationStep from "../../RegistrationStep";
import AthleteInfoStepComponent from "./AthleteInfoStepComponent";

export default class AthleteInfoStep implements RegistrationStep<AthleteInfo> {
  public StepTitle = "Personal Information";
  private componentRef: React.RefObject<AthleteInfoStepComponent> = React.createRef();
  private data?: AthleteInfo;

  componentWillUnmount() {
    // Make sure we've always got the latest before it's inaccessible.
    this.getData();
  }

  public getData() {
    let component = this.componentRef.current;
    if (component == null) {
      if (this.data == null)
        return new UserFriendlyError("There was a problem");
      return this.data;
    }

    let data = component.getData();
    if (!(data as UserFriendlyError).message) {
      this.data = data as AthleteInfo;
    }
    return data;
  }

  public getComponent() {
    return (
      <AthleteInfoStepComponent ref={this.componentRef} data={this.data} />
    );
  }
}
