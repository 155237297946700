import React from "react";
import {
  Button,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { ReactComponent as KanoLogo } from "Images/logo.svg";
import Licensing from "Sdk/Services/Licensing";

//#region Properties
export interface SmallBrandingProps {
  forceDisplay?: boolean;
}
interface Props extends SmallBrandingProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class SmallBranding extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    if (Licensing.shouldRemoveBranding() && !this.props.forceDisplay) {
      return "";
    } else {
      return (
        <Button
          size="small"
          className={classes.root}
          href="https://kanotrain.com"
          target="_blank"
          aria-label="Made with Kaino Train"
        >
          <KanoLogo
            alignmentBaseline="central"
            height="1.75em"
            className={classes.logo}
            aria-hidden
          />

          <Typography color="textSecondary" className={classes.brandName}>
            <span aria-label="kaino">Kano</span> Train
          </Typography>
        </Button>
      );
    }
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginLeft: "auto",
      padding: "0.3em",
      textTransform: "capitalize",
    },
    brandName: {
      padding: 0,
    },
    logo: {
      marginRight: "0.3em",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(SmallBranding);
