import React from "react";
import {
  Box,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";

//#region Properties
export interface ContactPageProps {}
interface Props extends ContactPageProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class ContactPage extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Box
        width="100%"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <p>Thanks for your interest in Kano Performance.</p>
        <p>
          Please contact us at{" "}
          <a className={classes.link} href="mailto:support@kanoperformance.com">
            support@kanoperformance.com
          </a>
        </p>
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    link: {
      color: "#ccf",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(ContactPage);
