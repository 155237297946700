import {
  Box,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Error } from "@material-ui/icons";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  PaymentIntentResult,
  Stripe,
  StripeElementChangeEvent,
} from "@stripe/stripe-js";
import PrimaryButton from "Components/PrimaryButton";
import SecondaryButton from "Components/SecondaryButton";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import config from "Sdk/Config";
import AccountController from "Sdk/Controllers/AccountController";
import SubscriptionController from "Sdk/Controllers/SubscriptionController";
import TrainingCampsController from "Sdk/Controllers/TrainingCampsController";
import { ITrainingCampPackage } from "Sdk/Data/Models/TrainingCampPackage";
import { BlueGray } from "Themes/Colors";

export interface StripeCheckoutProps {
  CampPackage: ITrainingCampPackage;
  BackCallback: () => void;
  SuccessCallback: () => void;
}

interface FieldProps {
  label: string;
  id: string;
  type: string;
  placeholder: string;
  required?: boolean;
  autoComplete: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}: FieldProps) => {
  const classes = useStyles();
  return (
    <div className={classes.formRow}>
      <label htmlFor={id} className={classes.formRowLabel}>
        <Typography>{label}</Typography>
      </label>
      <TextField
        className={classes.formRowInput}
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        InputProps={{ disableUnderline: true }}
      />
    </div>
  );
};

const StripeCheckoutInternal = (props: StripeCheckoutProps) => {
  const priceFormatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const activeUser = AccountController.ActiveUser();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | undefined>(undefined);
  const [cardComplete, setCardComplete] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: `${activeUser?.FirstName} ${activeUser?.LastName}` ?? "",
    email: activeUser?.EmailAddress ?? "",
  });

  useEffect(() => {
    (async () => {
      let purchaseResponse = await SubscriptionController.PurchaseCampPackageWithoutCheckout(
        props.CampPackage
      );
      if (!purchaseResponse.wasSuccess) {
        setError(purchaseResponse.error!.message);
        return;
      }

      let { secret, totalAmount } = purchaseResponse.data!;

      setClientSecret(secret);
      setPaymentAmount(totalAmount);
    })();
  }, [props.CampPackage]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // Return if stripe hasn't loaded
    if (!stripe || !elements) return;

    if (!cardComplete) return;

    let response: PaymentIntentResult;
    setProcessing(true);
    try {
      response = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement)!,
          },
        },
        {}
      );
    } catch (e) {
      setError("There was an error: " + e!.toString());
      return;
    }

    setProcessing(false);

    if (response.error) {
      setError(response.error.message);
      return;
    }

    props.SuccessCallback();
  };

  const handleChange = (e: StripeElementChangeEvent) => {
    setDisabled(e.empty);
    setError(e.error?.message);
    setCardComplete(e.complete);
  };

  const classes = useStyles();

  return (
    <Box>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box boxShadow={2} className={classes.roundedCorners}>
          <fieldset className={classes.formGroup}>
            <Field
              label="Name"
              id="name"
              type="text"
              placeholder="Jane Doe"
              required
              autoComplete="name"
              value={billingDetails.name}
              onChange={(e) =>
                setBillingDetails({ ...billingDetails, name: e.target.value })
              }
            />
            <Field
              label="Email"
              id="email"
              type="email"
              placeholder="janedoe@gmail.com"
              required
              autoComplete="email"
              value={billingDetails.email}
              onChange={(e) =>
                setBillingDetails({ ...billingDetails, email: e.target.value })
              }
            />
          </fieldset>
        </Box>

        <Box boxShadow={2} className={classes.roundedCorners}>
          <fieldset className={classes.formGroup}>
            <Box className={classes.formRow}>
              <CardElement
                options={{
                  style: {
                    base: {
                      iconColor: "#FFF",
                      color: "#FFF",
                      fontSize: "16px",
                      fontSmoothing: "antialiased",
                      fontFamily: "Roboto, sans-serif",
                      "::placeholder": {
                        color: "#bbb",
                      },
                    },
                    invalid: {
                      iconColor: "#bc1f0b",
                      color: "#cc2f1b",
                    },
                  },
                }}
                className={classes.stripeElement}
                onChange={(e) => handleChange(e)}
              />
            </Box>
          </fieldset>
        </Box>

        {error && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={1.2}
          >
            <Error color="secondary" />
            <Box ml={2}>
              <Typography>{error}</Typography>
            </Box>
          </Box>
        )}

        <Box display="flex">
          <SecondaryButton
            text="Back"
            className={classes.backButton}
            onClick={() => props.BackCallback()}
          />
          <PrimaryButton
            type="submit"
            className={classes.submitButton}
            text={
              processing
                ? "Processing..."
                : paymentAmount !== 0
                ? `Pay ${priceFormatter.format(paymentAmount)}`
                : "Loading..."
            }
            disabled={
              !stripe ||
              disabled ||
              !paymentAmount ||
              !cardComplete ||
              processing
            }
          />
        </Box>
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formGroup: {
      marginBottom: "1.2rem",
      padding: 0,
      borderStyle: "none",
      borderRadius: "5px",
      backgroundColor: "rgba(255, 255, 255, .2)",
      backdropFilter: "blur(5px)",
      marginLeft: 0,
      marginRight: 0,
    },
    formRow: {
      display: "flex",
      alignItems: "center",
      marginLeft: "15px",
      marginRight: "15px",
      borderTop: `1px solid ${BlueGray[500]}`,
      "&:first-child": {
        borderTop: "none",
      },
    },
    formRowLabel: {
      width: "15%",
      minWidth: "70px",
      padding: "11px 0",
      color: "#eee",
      overflow: "hidden",
      textOverflow: "elipsis",
      whiteSpace: "nowrap",
    },
    formRowInput: {
      marginTop: "1px",
      width: "100%",
      padding: "11px 15px 11px 0",
      color: "#FFF",
      backgroundColor: "transparent",
      border: "none",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "#bbb",
      },
    },
    roundedCorners: {
      borderRadius: "5px",
    },
    stripeElement: {
      width: "100%",
      padding: "11px 15px 11px 0",
    },
    submitButton: {
      width: "100%",
    },
    backButton: {
      width: "25%",
      marginRight: "1em",
    },
  })
);

const StripeCheckout = (props: StripeCheckoutProps) => {
  async function getStripe(): Promise<Stripe | null> {
    let stripeAccountResponse = await TrainingCampsController.getStripeToken(
      props.CampPackage.TrainingCamp
    );

    return loadStripe(config.stripeKey, {
      stripeAccount: stripeAccountResponse.data,
    });
  }

  return (
    <Elements
      stripe={getStripe()}
      options={{
        fonts: [
          {
            src: "https://fonts.googleapis.com/css?family=Roboto&display=swap",
            family: "Roboto",
            style: "normal",
          },
        ],
      }}
    >
      <StripeCheckoutInternal {...props} />
    </Elements>
  );
};

export default StripeCheckout;
