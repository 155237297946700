import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import random from "random-number-in-range";

//#region Properties
export interface CoachOfferingCardSKeletonProps {}
interface Props
  extends CoachOfferingCardSKeletonProps,
    WithStyles<typeof styles> {}
interface State {
  textLengths: number[];
}
//#endregion

//#region Component
class CoachOfferingCardSkeleton extends React.Component<Props, State> {
  textLengths = Array.from(Array(3), () => random(50));

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardMedia>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="10em"
          />
        </CardMedia>
        <CardContent>
          <Skeleton variant="text" animation="wave" height="3em" width="85%" />
          {this.textLengths.map((length) => (
            <Box mt={3}>
              <Skeleton variant="text" animation="wave" width={`${length}%`} />
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: "100%",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(
  CoachOfferingCardSkeleton
);
