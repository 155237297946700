import Request from "../Services/Request";
import Endpoints from "../Config/Endpoints";
import ApiResponse from "../Utils/ApiResponse";

async function getReleaseWaiver(
  organizationId: string
): Promise<ApiResponse<string>> {
  let endpoint = Endpoints.organization.getReleaseWaiver.clone();
  endpoint.Url.searchParams.set("organizationId", organizationId);

  return Request.get<string>(endpoint);
}

const OrganizationController = {
  getReleaseWaiver,
};

export default OrganizationController;
