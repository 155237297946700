import AbstractReferenceData from "./AbstractReferenceData";

// TODO: This should all really just be an enum - requires backend change
export type OrganizationTypeOptions = "Training" | "School";

export default class OrganizationType extends AbstractReferenceData {
  // Organizaed like this to ease transition to enum
  public static Training = new OrganizationType(
    "d803ed65-eff2-49d8-809b-1518e10ff8dd",
    "Training",
    "TRAINING"
  );
  public static School = new OrganizationType(
    "dd51baa8-60ce-46cf-91a3-700351cbdd05",
    "School",
    "SCHOOL"
  );
}
