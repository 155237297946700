import React from "react";
import {
  createStyles,
  FilledInput,
  FilledInputProps,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import uniqueDomId from "Utils/uniqueDomId";
import { Visibility, VisibilityOff } from "@material-ui/icons";

//#region Properties
export interface PasswordInputProps {
  id?: string;
  value: FilledInputProps["value"];
  onChange: FilledInputProps["onChange"];
  className: string;
  label?: string;
  required?: boolean;
}
interface Props extends PasswordInputProps, WithStyles<typeof styles> {}
//#endregion

//#region State
interface PasswordInputState {
  showPassword?: boolean;
}
//#endregion

//#region Component
class PasswordInput extends React.Component<Props, PasswordInputState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPassword: false,
    };
  }
  render() {
    // const { classes } = this.props;
    const { showPassword } = this.state;
    let inputId = this.props.id ?? uniqueDomId("password-field-");
    return (
      <FormControl
        variant="filled"
        className={this.props.className}
        required={this.props.required}
      >
        <InputLabel htmlFor={inputId}>
          {this.props.label ?? "Password"}
        </InputLabel>
        <FilledInput
          id={inputId}
          type={showPassword ? "text" : "password"}
          value={this.props.value}
          onChange={this.props.onChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => this.setState({ showPassword: !showPassword })}
                onMouseDown={(event) => event.preventDefault}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(PasswordInput);
