import React from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SnackbarProvider } from "notistack";
import Clear from "@material-ui/icons/Clear";
import { BlueGray } from "Themes/Colors";

//#region Properties
interface Props extends WithStyles<typeof styles> {}
//#endregion

//#region Component
class NotificationProvider extends React.Component<Props> {
  render() {
    const notistackRef = React.createRef<SnackbarProvider>();
    const onClickDismiss = (key?: string | number) => () => {
      notistackRef.current?.closeSnackbar(key);
    };

    const { classes } = this.props;
    return (
      <SnackbarProvider
        classes={classes}
        children={this.props.children}
        ref={notistackRef}
        preventDuplicate
        disableWindowBlurListener
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <Clear />
          </IconButton>
        )}
      />
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    contentRoot: {
      backgroundColor: BlueGray[500],
      color: theme.palette.text.primary,
      fontSize: "100%",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(NotificationProvider);

// import { makeStyles } from "@material-ui/core";
// import { SnackbarProvider, SnackbarProviderProps } from "notistack";
// import { FC } from "react";

// const useStyles = makeStyles(({ palette }) => ({
//     // default variant
//     contentRoot: {
//        backgroundColor: 'aqua',
//     },
//     variantSuccess: {
//       backgroundColor: palette.success.main,
//     },
//     variantError: {
//       backgroundColor: palette.error.main,
//     },
//     variantInfo: {
//       backgroundColor: palette.info.main,
//     },
//     variantWarning: {
//       backgroundColor: palette.warning.main,
//     },
//   }));

//   export const NotificationProvider: FC = ({ children }) => {
//     const classes = useStyles();
//     return <SnackbarProvider classes={classes} children={children} />;
//   };
