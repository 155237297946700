import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import KanoTrainRegistration from "Components/Authentication/Registration";
import AnimatedSuccess from "Components/AnimatedFeedback/AnimatedSuccess";
import LinkToApp from "../LinkToApp";
import CoachSelection from "Components/CoachSelection";
import OfferingPurchasing from "Components/OfferingPurchasing";

//#region Properties
export interface KanoTrainPurchasingProps {
  ForceDisplayBranding?: boolean;
  OrganizationId?: string;
  hideCoursesOnCoachSelect?: boolean;
  alertProvider?: (message: string) => void;
}
interface Props extends KanoTrainPurchasingProps, WithStyles<typeof styles> {}
//#endregion

interface State {
  isTransitioning: boolean;
  activeStep: number;
}

function defaultAlertProvider(message: string) {
  window.alert(message);
}

//#region Component
class KanoTrainPurchasing extends React.Component<Props, State> {
  private alertProvider: (message: string) => void;
  private steps: React.ReactNode[];
  private transition: React.ReactNode;

  private stepComplete() {
    this.transition = <AnimatedSuccess doneCallback={() => this.next()} />;
    this.setState({ isTransitioning: true });
  }

  private isLastStep = () => this.state.activeStep === this.steps.length - 1;

  private next() {
    if (this.isLastStep()) {
      this.alertProvider("done");
      return;
    }
    let step = this.state.activeStep + 1;
    this.setState({ isTransitioning: false, activeStep: step });
  }

  constructor(props: Props) {
    super(props);

    this.alertProvider = props.alertProvider ?? defaultAlertProvider;

    this.steps = [
      <KanoTrainRegistration
        OrganizationId={this.props.OrganizationId}
        ForceDisplayBranding={this.props.ForceDisplayBranding}
        alertProvider={(message) => this.alertProvider(message)}
        successCallback={() => this.stepComplete()}
      />,
      <CoachSelection
        OrganizationId={this.props.OrganizationId}
        ForceDisplayBranding={this.props.ForceDisplayBranding}
        alertProvider={(message) => this.alertProvider(message)}
        successCallback={() => this.stepComplete()}
        hideCourses={this.props.hideCoursesOnCoachSelect}
      />,
      <OfferingPurchasing
        ForceDisplayBranding={this.props.ForceDisplayBranding}
        alertProvider={(message) => this.alertProvider(message)}
        successCallback={() => this.stepComplete()}
      />,
      <LinkToApp />,
    ];

    this.state = { isTransitioning: false, activeStep: 0 };
  }

  render() {
    // const { classes } = this.props;
    return this.state.isTransitioning
      ? this.transition
      : this.steps[this.state.activeStep];
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(KanoTrainPurchasing);
