import User, { IUser } from "./User";

export interface ICoach extends IUser {
  // TODO
}

export default class Coach extends User implements ICoach {
  // TODO: implement https://github.com/typestack/class-transformer
  public static createFromInterface(coach: ICoach): Coach {
    return super.createFromInterface(coach);
  }
}
