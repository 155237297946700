import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Grid, { GridProps } from "@material-ui/core/Grid";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import IconComponent from "../IconComponent";

//#region Properties
export interface BaseButtonProps {
  type?: ButtonProps["type"];
  text: string;
  icon?: IconComponent;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  className?: ButtonProps["className"];
  disabled?: boolean;
}
interface Props extends BaseButtonProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class BaseButton extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    let Icon = this.props.icon;

    let iconJsx =
      Icon == null ? null : (
        <Grid xs={1} justify="center" item container>
          <Icon />
        </Grid>
      );
    let padding =
      Icon == null ? null : <Grid xs={1} justify="center" item container />;
    let xsTextColumns: GridProps["xs"] = Icon == null ? 12 : 10;

    let props: ButtonProps = { ...this.props };
    props.color = props.color ?? "primary";

    props.className = "";

    return (
      <Button
        {...props}
        className={`${classes.root} ${this.props.className}`}
        disabled={this.props.disabled}
      >
        <Grid spacing={1} direction="row" alignItems="center" container>
          {iconJsx}
          <Grid xs={xsTextColumns} item container justify="center">
            {this.props.text}
          </Grid>
          {padding}
        </Grid>
      </Button>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      // paddingRight: "1.5em",
      // paddingLeft: "1.5em",
      // paddingTop: "0.75em",
      // paddingBottom: "0.75em",
      padding: ".5em",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(BaseButton);
