import React from "react";
import { Box, TextField, TextFieldProps } from "@material-ui/core";
import AthleteInfo from "../../Data/AthleteInfo";
import UserFriendlyError from "Utils/UserFriendlyError";
import RegistrationStepComponent from "../RegistrationStepComponent";
import toWords from "split-camelcase-to-words";
import classes from "../Steps.module.css";
import age from "Utils/age";
import HeightEditor from "Components/Inputs/HeightSlider";
import DatePicker from "Components/Inputs/DatePicker";

//#region Props
export interface AthleteInfoStepComponentProps {
  data?: AthleteInfo;
}
//#endregion

interface Props
  extends AthleteInfoStepComponentProps,
    JSX.IntrinsicAttributes {}

//#region Component
export default class AthleteInfoStepComponent
  extends React.Component<Props, Partial<AthleteInfo>>
  implements RegistrationStepComponent<AthleteInfo> {
  public StepTitle = "Personal Information";

  constructor(props: Props) {
    super(props);

    if (process.env.NODE_ENV === "development") {
      this.state = props.data ?? {
        AthleteFirstName: "John",
        AthleteLastName: "Doe",
        BirthDay: new Date(2013, 1, 1),
        HeightInches: 60,
        WeightPounds: 100,
        ZipCode: "12345",
        SchoolName: "asdf",
      };
    } else {
      this.state = props.data ?? {};
    }
  }

  public getData() {
    let state: Partial<AthleteInfo> = this.state;

    if (!state.AthleteFirstName) {
      return new UserFriendlyError(
        "I've never met anyone named '' - what do people call you?"
      );
    } else if (!state.AthleteLastName) {
      return new UserFriendlyError("Please enter your last name.");
    } else if (!state.BirthDay || age(state.BirthDay) === 0) {
      return new UserFriendlyError(
        "You seem a little bit young for training. Sure you entered that right?"
      );
    } else if (!state.HeightInches) {
      return new UserFriendlyError("Enter a height, please.");
    } else if (!state.WeightPounds) {
      return new UserFriendlyError("Surely you must weigh something!");
    } else if (!state.ZipCode) {
      return new UserFriendlyError(
        "We can't find a coach for you without your zip code"
      );
    } else if (state.ZipCode.toString().length !== 5) {
      return new UserFriendlyError(
        "Your zip code doesn't look right to me. Please enter a five digit US zip code."
      );
    }

    if (!state.SchoolName || state.SchoolName.length === 0) {
      state.SchoolName = "None";
    }

    // This is used to keep typescript validation in place
    // `return state as AthleteInfo` would prevent it from warning us about forgetting to validate new properties
    return {
      AthleteFirstName: state.AthleteFirstName,
      AthleteLastName: state.AthleteLastName,
      BirthDay: state.BirthDay,
      HeightInches: state.HeightInches,
      WeightPounds: state.WeightPounds,
      ZipCode: state.ZipCode,
      SchoolName: state.SchoolName,
    };
  }

  private onChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propertyName: keyof AthleteInfo,
    preprocess?: (value: string) => any
  ) {
    if (event.currentTarget.value == null) return;

    let stateChange: any = {};
    let value = event.currentTarget.value;
    if (preprocess) {
      value = preprocess(value);
    }
    stateChange[propertyName] = value;
    this.setState(stateChange);
  }

  private getInput(field: keyof AthleteInfo) {
    let extraProps: Partial<TextFieldProps> = {};
    let inputProps: any = {};
    let preprocess: (value: string) => any;
    if (["WeightPounds", "HeightInches"].includes(field)) {
      extraProps.type = "number";
      inputProps.inputMode = "decimal";
      preprocess = (value) => parseInt(value);
    } else if (field === "ZipCode") {
      extraProps.type = "number";
      inputProps.pattern = "[0-9]{5}";
      inputProps.inputMode = "decimal";
    } else if (field.toLowerCase().includes("email")) {
      inputProps.type = "email";
    }

    if (field.toLowerCase().includes("parent")) {
      if (this.state.BirthDay && age(this.state.BirthDay) < 18) {
        extraProps.required = true;
      }
    } else if (!field.toLowerCase().includes("school")) {
      extraProps.required = true;
    }

    return (
      <TextField
        className={classes.field}
        variant="filled"
        label={toWords(field)}
        value={this.state[field]}
        onChange={(event) => this.onChange(event, field, preprocess)}
        {...extraProps}
        inputProps={inputProps}
      />
    );
  }

  render() {
    return (
      <>
        <Box className={classes.root}>
          {this.getInput("AthleteFirstName")}
          {this.getInput("AthleteLastName")}
          {this.getInput("ZipCode")}
          {this.getInput("WeightPounds")}
          <DatePicker
            required
            label="Birth Date"
            value={this.state.BirthDay}
            onChange={(date) => date && this.setState({ BirthDay: date })}
            className={classes.field}
          />
          {this.getInput("SchoolName")}
          <HeightEditor
            required
            className={classes.field}
            styles={{ minWidth: "100%" }}
            value={this.state.HeightInches}
            onChange={(newValue: number) =>
              this.setState({ HeightInches: newValue })
            }
          />
        </Box>
      </>
    );
  }
}
//#endregion
