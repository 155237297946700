export default abstract class AbstractReferenceData {
  private id: string;
  private name: string;
  private swKey: string;

  public get Id(): string {
    return this.id;
  }

  public get Name(): string {
    return this.name;
  }

  public get SWKey(): string {
    return this.swKey;
  }

  constructor(id: string, name: string, swKey: string) {
    this.id = id;
    this.name = name;
    this.swKey = swKey;
  }
}
