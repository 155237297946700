import React from "react";
import {
  Box,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { BlueGray } from "Themes/Colors";
import CoachOfferingCard from "Components/CoachOfferingCard";

//#region Properties
export interface CoachOfferingListSkeletonProps {}
interface Props
  extends CoachOfferingListSkeletonProps,
    WithStyles<typeof styles> {}
//#endregion

//#region Component
class CoachOfferingListSkeleton extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return [...Array(2)].map(() => (
      <Box>
        <Box display="flex" alignItems="center">
          <Skeleton
            variant="text"
            width="75%"
            height="3em"
            className={classes.coachName}
          />
          <Box border={`1px ${BlueGray[400]} solid`} width="100%" ml={3} />
        </Box>
        <Box display="flex" flexWrap="wrap">
          {[...Array(3)].map(() => (
            <CoachOfferingCard SuccessCallback={() => {}} />
          ))}
        </Box>
      </Box>
    ));
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    coachName: {
      borderRadius: "1em",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(
  CoachOfferingListSkeleton
);
