import React from "react";
import {
  createStyles,
  Slider,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";
import uniqueDomId from "Utils/uniqueDomId";

//#region Properties
export interface HeightSliderProps {
  className?: string;
  styles?: React.CSSProperties;
  required?: boolean;
  value?: number;
  onChange: (newValue: number) => void;
}
interface Props extends HeightSliderProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class HeightSlider extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.labelId = uniqueDomId("height-editor-label-");
  }

  private labelId;

  private generateHeightInputs(foot: number) {
    let footInches = foot * 12;
    let ret = [
      {
        value: footInches,
        label: `${foot}'`,
        display: `${foot}'`,
      },
    ];
    for (var inch = 1; inch < 12; inch++) {
      ret.push({
        value: footInches + inch,
        label: "",
        display: `${foot}'${inch}"`,
      });
    }
    return ret;
  }

  // Generate array [1..7]
  private feet = Array(7)
    .fill(0)
    .map((_, i) => i + 1);
  private heightStops = this.feet.flatMap((foot) =>
    this.generateHeightInputs(foot)
  );

  private valueLabelFormat(value: number) {
    return this.heightStops.find((mark) => mark.value === value)?.display;
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={this.props.className} style={this.props.styles}>
        <Typography id={this.labelId}>
          Height {this.props.required ? "*" : ""}
        </Typography>
        <Slider
          className={!this.props.value ? classes.hideSlider : ""}
          aria-labelledby={this.labelId}
          // defaultValue={undefined}
          step={null}
          min={this.heightStops[0].value}
          max={this.heightStops[this.heightStops.length - 1].value}
          valueLabelDisplay={this.props.value ? "on" : "off"}
          valueLabelFormat={(value) => this.valueLabelFormat(value)}
          marks={this.heightStops}
          value={this.props.value}
          onChange={(
            event: React.ChangeEvent<{}>,
            newValue: number | number[]
          ) => this.props.onChange(newValue as number)}
        />
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    hideSlider: {
      "& .MuiSlider-thumb": {
        opacity: 0,
      },
      "& .MuiSlider-thumb:focus": {
        opacity: 1,
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(HeightSlider);
