import React from "react";
import {
  createStyles,
  FormControlProps,
  FormControl,
  Select,
  SelectProps,
  Theme,
  withStyles,
  WithStyles,
  InputLabel,
} from "@material-ui/core";
import uniqueDomId from "Utils/uniqueDomId";
import toWords from "split-camelcase-to-words";

//#region Properties
export interface EnumSelectProps {
  enumeration: { [key: string]: string };
  controlProps?: Omit<FormControlProps, "children">;
  selectProps?: Omit<SelectProps, "children">;
  label?: string;
  className?: string;
  required?: boolean;
  value?: string;
  onChange?: SelectProps["onChange"];
  exactName?: boolean;
}

interface Props extends EnumSelectProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class EnumSelect extends React.Component<Props> {
  render() {
    let inputId =
      this.props.selectProps?.inputProps?.id ?? uniqueDomId("enum-select-");
    // const { classes } = this.props;
    return (
      <FormControl
        variant="filled"
        {...this.props.controlProps}
        className={this.props.className}
        required={this.props.required}
      >
        {this.props.label ? (
          <InputLabel htmlFor={inputId}>{this.props.label}</InputLabel>
        ) : (
          ""
        )}
        <Select
          native
          required={this.props.required}
          variant="filled"
          value={this.props.value}
          onChange={this.props.onChange}
          inputProps={{ id: inputId }}
          {...this.props.selectProps}
        >
          <option value={undefined}></option>
          {Object.keys(this.props.enumeration).map((key) => {
            let givenName = this.props.enumeration[key];
            let name = this.props.exactName ? givenName : toWords(givenName);
            return <option value={givenName}>{name}</option>;
          })}
        </Select>
      </FormControl>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(EnumSelect);
