import apm from "./elasticRum";

function processError(error: Error) {
  try {
    apm.captureError(error);
  } catch (e) {
    console.error("Error logging to APM: " + e.toString());
  }
}

export default processError;
