import UserType from "../Enums/UserType";
import PlayingLevel from "../Reference/PlayingLevel";
import User, { IUser } from "./User";

export interface IPlayer extends IUser {
  BirthDate: Date;
  TrainingLevel: PlayingLevel;
  InchesHeight: number;
  PoundsWeight: number;
  GraduationYear: number;
}

export default class Player extends User implements IPlayer {
  private birthDate: Date;
  private trainingLevel: PlayingLevel;
  private inchesHeight: number;
  private poundsWeight: number;
  private graduationYear: number;

  //#region Props
  public get BirthDate(): Date {
    return this.birthDate;
  }
  public set BirthDate(value: Date) {
    this.birthDate = value;
  }

  public get TrainingLevel(): PlayingLevel {
    return this.trainingLevel;
  }
  public set TrainingLevel(value: PlayingLevel) {
    this.trainingLevel = value;
  }

  public get InchesHeight(): number {
    return this.inchesHeight;
  }
  public set InchesHeight(value: number) {
    this.inchesHeight = value;
  }

  public get PoundsWeight(): number {
    return this.poundsWeight;
  }
  public set PoundsWeight(value: number) {
    this.poundsWeight = value;
  }

  public get GraduationYear(): number {
    return this.graduationYear;
  }
  public set GraduationYear(value: number) {
    this.graduationYear = value;
  }
  //#endregion

  // BirthDate: Date;
  // TrainingLevel: PlayingLevel;
  // InchesHeight: number;
  // PoundsWeight: number;
  // GraduationYear: number;
  constructor(
    emailAddress: string,
    firstName: string,
    lastName: string,
    primaryOrganizationName: string,
    zipCode: string,
    signupComplete: boolean,
    birthDate: Date,
    trainingLevel: PlayingLevel,
    inchesHeight: number,
    poundsWeight: number,
    graduationYear: number,
    avatar?: string,
    avatarThumb?: string
  ) {
    super(
      UserType.Athlete,
      emailAddress,
      firstName,
      lastName,
      primaryOrganizationName,
      zipCode,
      signupComplete,
      avatar,
      avatarThumb
    );

    this.birthDate = birthDate;
    this.trainingLevel = trainingLevel;
    this.inchesHeight = inchesHeight;
    this.poundsWeight = poundsWeight;
    this.graduationYear = graduationYear;
  }
}
