import Endpoints from "Sdk/Config/Endpoints";
import { ICoach } from "Sdk/Data/Models/Coach";
import ApiResponse from "Sdk/Utils/ApiResponse";
import Request from "Sdk/Services/Request";
import CoachPosition from "Sdk/Data/Models/CoachPosition";
import Position from "Sdk/Data/Reference/Position";

async function getCoachPositionsForCoach(
  coach: ICoach
): Promise<ApiResponse<CoachPosition[]>> {
  let endpoint = Endpoints.positions.getCoach_Positions.clone();
  endpoint.Url.searchParams.append("coachId", coach.Id!);

  return await Request.get<CoachPosition[]>(endpoint);
}

async function getSupportedPositionsForOrganization(
  orgId: string
): Promise<ApiResponse<Position[]>> {
  let endpoint = Endpoints.positions.getSupportedPositionsForOrganization.clone();
  endpoint.Url = new URL(orgId!, endpoint.Url);

  return await Request.get<Position[]>(endpoint);
}

const PositionsController = {
  getCoachPositionsForCoach,
  getSupportedPositionsForOrganization,
};

export default PositionsController;
