export interface Hue {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export interface AccentableHue extends Hue {
  A100: string;
  A200: string;
  A300: string;
  A400: string;
  A500: string;
}

// https://noeldelgado.github.io/shadowlord
export const BlueGray: Hue = {
  50: "#e1e1e5", // lighten 84%
  100: "#c7c8ce", // lighten 70%
  200: "#8f909d", // lighten 40%
  300: "#696b7d", // lighten 20%
  400: "#57596c", // lighten 10%
  500: "#44465c", // base
  600: "#3d3f53", // darken 10.2%
  700: "#37394b", // darken 19%
  800: "#323343", // darken 27%
  900: "#282936", // darken 41%
};

export const DisabledGray: Hue = {
  50: "#d5d5d5", // lighten
  100: "#c3c3c3", // lighten xx%
  200: "#bdbdbd", // lighten xx%
  300: "#a8a8a8", // lighten xx%
  400: "#939393", // lighten xx%
  500: "#7e7e7e", // base
  600: "#717171", // darken xx%
  700: "#646464", // darken xx%
  800: "#585858", // darken xx%
  900: "#4b4b4b", // darken
};

export const KanoGreen: Hue = {
  50: "#f9fbe6", // logo-top lighten 43%
  100: "#f0f5c3", // logo-top lighten 35%
  200: "#e5ed97", // logo-top lighten 25%
  300: "#dae66b", // lgoo-top lighten 15%
  400: "#d5e256", // logo-top lighten 10%
  500: "#cadb2a", // logo-top
  600: "#aecb2f", // interpolated midpoint
  700: "#94bb34", // logo-bottom
  800: "#84a72e", // logo-bottom darken 5%
  900: "#6b8726", // logo-bottom darken 13%
};
