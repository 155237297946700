import AuthenticationType from "../Data/Enums/AuthenticationType";
import _clonedeep from "lodash-es/cloneDeep";
import config, { EnvironmentType } from "./index";

let baseUrl: URL;
if (config.environment === EnvironmentType.prod) {
  baseUrl = new URL("https://train.kanoperformance.com/api/");
} else if (config.environment === EnvironmentType.demo) {
  baseUrl = new URL("https://demo.train.kanoperformance.com/api/");
} else if (config.environment === EnvironmentType.dev) {
  baseUrl = new URL("http://server.local:8000/api/");
} else {
  throw new Error(`Unrecognized environment type, ${config.environment}`);
}

export interface IApiEndpoint {
  Url: URL;
  AuthenticationType: AuthenticationType;
  clone: () => IApiEndpoint;
}

class ApiEndpoint implements IApiEndpoint {
  public Url: URL;
  public AuthenticationType: AuthenticationType;
  constructor(url: URL, authenticationType?: AuthenticationType) {
    this.Url = url;
    this.AuthenticationType =
      authenticationType ?? AuthenticationType.UserToken;
  }

  clone() {
    let result = _clonedeep(this);
    // Apparently, URLs don't work right with _.cloneDeep
    result.Url = new URL(this.Url.toString());
    return result;
  }
}

interface ApiEndpoints {
  [key: string]: IApiEndpoint;
}
interface URLs {
  [key: string]: URL;
}

const waitlist: ApiEndpoints = {
  root: new ApiEndpoint(new URL("WaitList/", baseUrl), AuthenticationType.None),
};

const accountRootUrl = new URL("Users/", baseUrl);
const account: ApiEndpoints = {
  root: new ApiEndpoint(accountRootUrl),
  signUp: new ApiEndpoint(accountRootUrl, AuthenticationType.DeviceToken),
};

const playerPositionRootUrl = new URL("Player_Positions/", baseUrl);
const playerPosition: ApiEndpoints = {
  root: new ApiEndpoint(playerPositionRootUrl),
};

const organizationRootUrl = new URL("Organizations/", baseUrl);
const organization: ApiEndpoints = {
  root: new ApiEndpoint(organizationRootUrl),
  getReleaseWaiver: new ApiEndpoint(
    new URL("GetReleaseWaiver", organizationRootUrl),
    AuthenticationType.None
  ),
};

const authRootUrl = new URL(baseUrl + "auth/");
const auth: ApiEndpoints = {
  root: new ApiEndpoint(authRootUrl),
  getDeviceToken: new ApiEndpoint(
    new URL("GetDeviceToken/", authRootUrl),
    AuthenticationType.None
  ),
  refreshUserToken: new ApiEndpoint(
    new URL("RenewToken/", authRootUrl),
    AuthenticationType.DeviceToken
  ),
  login: new ApiEndpoint(
    new URL("Login/", authRootUrl),
    AuthenticationType.DeviceToken
  ),
};

const trainingCourseRootUrl = new URL(baseUrl + "TrainingCourses");
const trainingCourse: ApiEndpoints = {
  root: new ApiEndpoint(trainingCourseRootUrl),
};

const trainingCourseSessionPackageRootUrl = new URL(
  baseUrl + "TrainingCourseSessionPackages"
);
const trainingCourseSessionPackage: ApiEndpoints = {
  root: new ApiEndpoint(trainingCourseSessionPackageRootUrl),
};

const trainingCampRootUrl = new URL(baseUrl + "TrainingCamps/");
const trainingCamp: ApiEndpoints = {
  root: new ApiEndpoint(trainingCampRootUrl),
  getStripeToken: new ApiEndpoint(
    new URL("GetStripeToken/", trainingCampRootUrl),
    AuthenticationType.None
  ),
  getCampsForCoach: new ApiEndpoint(new URL("ForCoach/", trainingCampRootUrl)),
};

const trainingCampPackagesRootUrl = new URL(baseUrl + "TrainingCampPackages/");
const trainingCampPackages: ApiEndpoints = {
  root: new ApiEndpoint(trainingCampPackagesRootUrl),
  getTrainingCampPackages: new ApiEndpoint(
    new URL("ForCamp/", trainingCampPackagesRootUrl)
  ),
};

const trainingCampEventsRootUrl = new URL(baseUrl + "TrainingCampEvents/");
const trainingCampEvents: ApiEndpoints = {
  root: new ApiEndpoint(trainingCampEventsRootUrl),
  getEventsForCamp: new ApiEndpoint(
    new URL("ForCamp/", trainingCampEventsRootUrl)
  ),
};

const userRootUrl = new URL("Users/", baseUrl);
const user: ApiEndpoints = {
  root: new ApiEndpoint(userRootUrl),
  getNearbyCoaches: new ApiEndpoint(new URL("GetNearbyCoaches/", userRootUrl)),
};

const playerRootUrl = new URL("Players/", baseUrl);
const player: ApiEndpoints = {
  root: new ApiEndpoint(playerRootUrl),
};

const coachRootUrl = new URL(baseUrl + "Coaches/");
const coach: ApiEndpoints = {
  root: new ApiEndpoint(coachRootUrl),
  getCoachFromEmail: new ApiEndpoint(
    new URL("GetCoachFromEmail/", coachRootUrl)
  ),
  getStripeToken: new ApiEndpoint(new URL("GetStripeToken/", coachRootUrl)),
};

const playerCoachRootUrl = new URL(baseUrl + "Player_Coaches/");
const playerCoach: ApiEndpoints = {
  root: new ApiEndpoint(playerCoachRootUrl),
  forPlayer: new ApiEndpoint(new URL("ForPlayer/", playerCoachRootUrl)),
};

const subscriptionRootUrl = new URL(baseUrl + "Subscriptions/");
const subscription: ApiEndpoints = {
  root: new ApiEndpoint(subscriptionRootUrl),
  purchaseSessionPackageWithoutCheckout: new ApiEndpoint(
    new URL("PurchaseSessionPackageWithoutCheckout/", subscriptionRootUrl)
  ),
  purchaseCampPackageWithoutCheckout: new ApiEndpoint(
    new URL("PurchaseTrainingCampPackageWithoutCheckout/", subscriptionRootUrl)
  ),
};

const imageBackendRootUrl = new URL(
  "https://kanostorage.blob.core.windows.net/"
);
const imageBackend: URLs = {
  root: imageBackendRootUrl,
  trainingCourse: new URL("coursemedia/", imageBackendRootUrl),
};

const positionsRootUrl = new URL(baseUrl + "Positions/");
const positions: ApiEndpoints = {
  root: new ApiEndpoint(positionsRootUrl),
  getCoach_Positions: new ApiEndpoint(
    new URL("CoachPositions", positionsRootUrl)
  ),
  getSupportedPositionsForOrganization: new ApiEndpoint(
    new URL("ForOrganization/", positionsRootUrl),
    AuthenticationType.None
  ),
};

const referenceDataRootUrl = new URL(baseUrl + "ReferenceData/");
const referenceData: ApiEndpoints = {
  root: new ApiEndpoint(referenceDataRootUrl),
  getPositions: new ApiEndpoint(
    new URL("Position", referenceDataRootUrl),
    AuthenticationType.None
  ),
};

function AddToRoute(endpoint: IApiEndpoint, value: string) {
  endpoint.Url = new URL(value, endpoint.Url);
}

function AddToQuery(endpoint: IApiEndpoint, key: string, value: string) {
  endpoint.Url.searchParams.append(key, value);
}

const Endpoints = {
  waitlist,
  account,
  playerPosition,
  organization,
  auth,
  user,
  player,
  coach,
  playerCoach,
  trainingCourse,
  trainingCourseSessionPackage,
  trainingCamp,
  trainingCampPackages,
  trainingCampEvents,
  subscription,
  imageBackend,
  positions,
  referenceData,
  addToRoute: AddToRoute,
  addToQuery: AddToQuery,
};

export default Endpoints;
