import AbstractOrderedReferenceData from "./AbstractOrderedReferenceData";

export type ScholarshipLevelOptions = "D3" | "D2" | "D1" | "Ivy" | "Power5";

export default class ScholarshipLevel extends AbstractOrderedReferenceData {
  // Organized like this to ease transition to enum
  public static D3 = new ScholarshipLevel(
    "f4194e5e-c2b5-4b8d-b5f0-c68391aad7ae",
    "D-III",
    "D-III",
    10
  );
  public static D2 = new ScholarshipLevel(
    "7a36d1d5-cd48-4d02-ae09-85b4d3dddcba",
    "D-II",
    "D-II",
    20
  );
  public static D1 = new ScholarshipLevel(
    "61750c76-a97f-481e-ac3c-09f232629d8d",
    "D-I",
    "D-I",
    30
  );
  public static Ivy = new ScholarshipLevel(
    "ba856c23-90c1-4da6-b0ea-78f1cf387af3",
    "FCS / Ivy",
    "FCS/IVY",
    40
  );
  public static Power5 = new ScholarshipLevel(
    "da645a3b-fa24-4bca-8382-f754bf1cef69",
    "Power 5",
    "Power5",
    50
  );

  // Convenience methods
  public static get "D-III"() {
    return this.D3;
  }
  public static get "D-II"() {
    return this.D2;
  }
  public static get "D-I"() {
    return this.D1;
  }
  public static get "FCS / Ivy"() {
    return this.Ivy;
  }
  public static get "Power 5"() {
    return this.Power5;
  }
}
