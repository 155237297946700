// Adapted from https://stackoverflow.com/a/7091965/2230115
export default function age(birthdate: Date) {
  let today = new Date();
  let age = today.getFullYear() - birthdate.getFullYear();
  var m = today.getMonth() - birthdate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age -= 1;
  }

  return age;
}
