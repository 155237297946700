import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { BlueGray, KanoGreen } from "./Colors";

const DarkTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      paper: BlueGray[600],
    },
    mainBackground: `radial-gradient(ellipse at top left, ${BlueGray[400]} 5%, ${BlueGray[600]} 40%, ${BlueGray[800]} 80%)`,
    primary: {
      main: KanoGreen[700],
      light: KanoGreen[500],
      dark: KanoGreen[800],
    },
  },
});

export default responsiveFontSizes(DarkTheme);
