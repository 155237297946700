import UserType from "../Enums/UserType";
import { IOrganization } from "./Organization";
import { jsonIgnore } from "json-ignore";

export interface IUser {
  Id?: string;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  FullName: string;
  PrimaryOrganizationName: string;
  ZipCode: string;
  SignupComplete: boolean;
  Avatar?: string;
  AvatarThumb?: string;
  Organization?: IOrganization;
  Discriminator: UserType;
}

export default class User implements IUser {
  private id?: string;
  private emailAddress: string;
  private firstName: string;
  private lastName: string;
  private primaryOrganizationName: string;
  private zipCode: string;
  private signupComplete: boolean;
  private avatar?: string;
  private avatarThumb?: string;
  private organization?: IOrganization;
  private discriminator: UserType;

  //#region Props
  public get Id(): string | undefined {
    return this.id;
  }
  public set Id(value: string | undefined) {
    this.id = value;
  }

  public get EmailAddress(): string {
    return this.emailAddress;
  }
  public set EmailAddress(value: string) {
    this.emailAddress = value;
  }

  public get FirstName(): string {
    return this.firstName;
  }
  public set FirstName(value: string) {
    this.firstName = value;
  }

  public get LastName(): string {
    return this.lastName;
  }
  public set LastName(value: string) {
    this.lastName = value;
  }

  @jsonIgnore()
  public get FullName(): string {
    return `${this.FirstName} ${this.LastName}`;
  }

  public get PrimaryOrganizationName(): string {
    return this.primaryOrganizationName;
  }
  public set PrimaryOrganizationName(value: string) {
    this.primaryOrganizationName = value;
  }

  public get ZipCode(): string {
    return this.zipCode;
  }
  public set ZipCode(value: string) {
    this.zipCode = value;
  }

  public get Avatar(): string | undefined {
    return this.avatar;
  }
  public set Avatar(value: string | undefined) {
    this.avatar = value;
  }

  public get AvatarThumb(): string | undefined {
    return this.avatarThumb;
  }
  public set AvatarThumb(value: string | undefined) {
    this.avatarThumb = value;
  }

  public get SignupComplete(): boolean {
    return this.signupComplete;
  }
  public set SignupComplete(value: boolean) {
    this.signupComplete = value;
  }

  public get Organization(): IOrganization | undefined {
    return this.organization;
  }
  public set Organization(value: IOrganization | undefined) {
    this.organization = value;
  }

  public get Discriminator(): UserType {
    return this.discriminator;
  }
  public set Discriminator(value: UserType) {
    this.discriminator = value;
  }
  //#endregion

  public static createFromInterface(user: IUser): User {
    // TODO: implement https://github.com/typestack/class-transformer
    let result = new this(
      user.Discriminator,
      user.EmailAddress,
      user.FirstName,
      user.LastName,
      user.PrimaryOrganizationName,
      user.ZipCode,
      user.SignupComplete,
      user.Avatar,
      user.AvatarThumb,
      user.Organization
    );

    result.Id = user.Id;
    return result;
  }

  constructor(
    discriminator: UserType,
    emailAddress: string,
    firstName: string,
    lastName: string,
    primaryOrganizationName: string,
    zipCode: string,
    signupComplete: boolean,
    avatar?: string,
    avatarThumb?: string,
    organization?: IOrganization
  ) {
    this.emailAddress = emailAddress;
    this.firstName = firstName;
    this.lastName = lastName;
    this.primaryOrganizationName = primaryOrganizationName;
    this.zipCode = zipCode;
    this.signupComplete = signupComplete;
    this.avatar = avatar;
    this.avatarThumb = avatarThumb;
    this.organization = organization;
    this.discriminator = discriminator;
  }
}
