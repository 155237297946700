import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";
import KanoPaperCircle from "./KanoPaperCircle";
import { BlueGray } from "Themes/Colors";
import ResizeObserver from "react-resize-observer";
import QbOne from "Images/qb-1.jpg";
import TrainTired from "Images/train-tired.jpg";
import QbTwo from "Images/qb-2.jpg";

export interface KanoParalaxCirclesProps {}

interface Props extends WithStyles<typeof styles>, KanoParalaxCirclesProps {}

class KanoParalaxCircles extends React.Component<
  Props,
  { width: number; height: number }
> {
  private domRef: React.RefObject<HTMLElement>;

  constructor(props: Props) {
    super(props);

    this.domRef = React.createRef();
    this.state = { width: 0, height: 0 };
  }

  private setDimensions() {
    if (this.domRef.current == null) return;

    let fontSizeProp = window
      .getComputedStyle(this.domRef.current, null)
      .getPropertyValue("font-size");
    let fontSize = parseInt(fontSizeProp);
    this.setState({
      width: (this.domRef.current?.clientWidth ?? 0) / fontSize,
      height: (this.domRef.current?.clientHeight ?? 0) / fontSize,
    });
  }

  componentDidMount() {
    if (this.domRef.current != null) {
      this.setDimensions();
    }
  }

  render() {
    const { classes } = this.props;
    let size = Math.min(this.state.width, this.state.height - 2);

    return (
      <section aria-hidden className={classes.root} ref={this.domRef}>
        <ResizeObserver
          onResize={() => requestAnimationFrame(() => this.setDimensions())}
        />
        {/* start rings */}
        <KanoPaperCircle
          diameter={0.5 * size}
          borderColor={BlueGray[400]}
          className={classes.circle}
          style={{ top: "-5%", left: "40%" }}
        />
        <KanoPaperCircle
          diameter={0.65 * size}
          borderColor={BlueGray[400]}
          className={classes.circle}
          style={{ top: "-5%", left: "3%" }}
        />
        <KanoPaperCircle
          diameter={0.33 * size}
          borderColor={BlueGray[400]}
          className={classes.circle}
          style={{ top: "50%", left: "10%" }}
        />
        <KanoPaperCircle
          diameter={0.8 * size}
          borderColor={BlueGray[400]}
          className={classes.circle}
          style={{ top: "25%", left: "20%" }}
        />
        {/* end rings */}

        <KanoPaperCircle
          diameter={0.45 * size}
          shadow={3}
          backgroundColor={BlueGray[500]}
          className={classes.circle}
          style={{ top: "0%", left: "25%" }}
        />
        <KanoPaperCircle
          diameter={0.2 * size}
          shadow={12}
          background={`center/contain url(${TrainTired})`}
          className={classes.circle}
          style={{ top: "5%", left: "52%" }}
        />
        <KanoPaperCircle
          diameter={0.3 * size}
          shadow={14}
          backgroundColor={BlueGray[500]}
          className={classes.circle}
          style={{ top: "17%", left: "60%" }}
        />
        <KanoPaperCircle
          diameter={0.3 * size}
          shadow={15}
          background={`center/contain url(${QbTwo})`}
          className={classes.circle}
          style={{ top: "5%", left: "12%" }}
        />
        <KanoPaperCircle
          diameter={0.6 * size}
          shadow={20}
          background={`center/contain url(${QbOne})`}
          className={classes.circle}
          style={{ top: "35%", left: "30%" }}
        />
        <KanoPaperCircle
          diameter={0.3 * size}
          shadow={24}
          backgroundColor={BlueGray[500]}
          className={classes.circle}
          style={{ top: "30%", left: "10%" }}
        />
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      width: "100%",
      height: "100%",
    },
    circle: {
      position: "absolute",
    },
  });

export default withStyles(styles, { withTheme: true })(KanoParalaxCircles);
