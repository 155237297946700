import OrganizationType from "../Reference/OrganizationType";

export interface IOrganization {
  Id?: string;
  OrganizationType: OrganizationType;
  Name: string;
  Abbreviation: string;
  ParentOrganization?: IOrganization;
  Address: string;
  OrganizationEmail: string;
  OrganizationPhoneNumber: string;
  DefaultPolicy: string;
}

export default class Organization implements IOrganization {
  private id?: string;
  private organizationType: OrganizationType;
  private name: string;
  private abbreviation: string;
  private parentOrganization?: IOrganization;
  private address: string;
  private organizationEmail: string;
  private organizationPhoneNumber: string;
  private defaultPolicy: string;

  //#region Props
  public get Id(): string | undefined {
    return this.id;
  }
  public set Id(value: string | undefined) {
    this.id = value;
  }

  public get OrganizationType(): OrganizationType {
    return this.organizationType;
  }
  public set OrganizationType(value: OrganizationType) {
    this.organizationType = value;
  }

  public get Name(): string {
    return this.name;
  }
  public set Name(value: string) {
    this.name = value;
  }

  public get Abbreviation(): string {
    return this.abbreviation;
  }
  public set Abbreviation(value: string) {
    this.abbreviation = value;
  }

  public get ParentOrganization(): IOrganization | undefined {
    return this.parentOrganization;
  }
  public set ParentOrganization(value: IOrganization | undefined) {
    this.parentOrganization = value;
  }

  public get Address(): string {
    return this.address;
  }
  public set Address(value: string) {
    this.address = value;
  }

  public get OrganizationEmail(): string {
    return this.organizationEmail;
  }
  public set OrganizationEmail(value: string) {
    this.organizationEmail = value;
  }

  public get OrganizationPhoneNumber(): string {
    return this.organizationPhoneNumber;
  }
  public set OrganizationPhoneNumber(value: string) {
    this.organizationPhoneNumber = value;
  }

  public get DefaultPolicy(): string {
    return this.defaultPolicy;
  }
  public set DefaultPolicy(value: string) {
    this.defaultPolicy = value;
  }
  //#endregion

  constructor(
    name: string,
    organizationType: OrganizationType,
    abbreviation: string,
    address: string,
    organizationEmail: string,
    organizationPhoneNumber: string,
    defaultPolicy: string,
    parentOrganization?: IOrganization
  ) {
    this.name = name;
    this.organizationType = organizationType;
    this.abbreviation = abbreviation;
    this.address = address;
    this.organizationEmail = organizationEmail;
    this.organizationPhoneNumber = organizationPhoneNumber;
    this.defaultPolicy = defaultPolicy;
    this.parentOrganization = parentOrganization;
  }
}
