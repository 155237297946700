import Endpoints from "../Config/Endpoints";
import ApiResponse from "Sdk/Utils/ApiResponse";
import Request from "../Services/Request";
import { IUser } from "Sdk/Data/Models/User";
import UserPassword from "Sdk/Data/Models/UserPassword";
import { UserToken } from "Sdk/Data/Models/UserToken";

async function requestDeviceToken(
  clientName: string,
  clientId: string
): Promise<ApiResponse<string>> {
  return await Request.post<string>(Endpoints.auth.getDeviceToken, {
    Client: clientName,
    Key: clientId,
  });
}

async function refreshUserToken(
  oldToken: string
): Promise<ApiResponse<{ token: string }>> {
  return await Request.post<{ token: string }>(
    Endpoints.auth.refreshUserToken,
    oldToken
  );
}

async function login(
  user: IUser,
  password: string
): Promise<ApiResponse<UserToken>> {
  return await Request.post<UserToken>(
    Endpoints.auth.login,
    new UserPassword(user, password)
  );
}

const AuthenticationController = {
  requestDeviceToken,
  refreshUserToken,
  login,
};

export default AuthenticationController;
