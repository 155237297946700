import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";

//#region Properties
export interface DatePickerProps {
  required?: boolean;
  label?: string;
  value?: Date;
  onChange: KeyboardDatePickerProps["onChange"];
  className?: string;
}
interface Props extends DatePickerProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class DatePicker extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <KeyboardDatePicker
        required={this.props.required}
        className={`${this.props.className} ${classes.root}`}
        inputProps={{ variant: "filled", inputMode: "decimal" }}
        placeholder="mm/dd/yyyy"
        margin="normal"
        label={this.props.label}
        format="MM/dd/yyyy"
        value={this.props.value ?? null}
        onChange={this.props.onChange}
      />
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  let light = theme.palette.type === "light";
  let backgroundColor = light
    ? "rgba(0, 0, 0, 0.09)"
    : "rgba(255, 255, 255, 0.09)";
  let highlightBackgroundColor = light
    ? "rgba(0, 0, 0, 0.13)"
    : "rgba(255, 255, 255, 0.13)";

  return createStyles({
    root: {
      position: "relative",
      transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      margin: 0,

      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "-36px",
      },
      "& .MuiInput-formControl": {
        paddingTop: "24px",
        marginTop: 0,
        backgroundColor: backgroundColor,
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      },
      "& .MuiInput-formControl:hover:not($disabled):before": {
        borderBottom: `1px solid ${theme.palette.text.primary}`,
      },
      "& label": {
        transform: "translate(12px, 20px) scale(1)",
      },
      "& label.MuiInputLabel-shrink": {
        transform: "translate(12px, 10px) scale(0.75)",
      },
      "& input": {
        paddingLeft: "12px",
      },
      "& .MuiFormHelperText-filled": {
        backgroundColor: "transparent",
      },
      "& button": {
        marginBottom: "20px",
      },
      "& .MuiInput-formControl:hover": {
        backgroundColor: highlightBackgroundColor,
        "@media (hover: none)": {
          backgroundColor,
        },
      },
      "& .MuiInput-formControl:focus-within": {
        backgroundColor: backgroundColor,
      },
      "&$disabled .MuiInput-formControl": {
        backgroundColor: backgroundColor,
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(DatePicker);
