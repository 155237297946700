import Endpoints from "Sdk/Config/Endpoints";
import { ITrainingCourse } from "Sdk/Data/Models/TrainingCourse";
import { ITrainingCourseSessionPackage } from "Sdk/Data/Models/TrainingCourseSessionPackage";
import Request from "Sdk/Services/Request";
import ApiResponse from "Sdk/Utils/ApiResponse";

async function getPurchasingOptions(
  course: ITrainingCourse
): Promise<ApiResponse<ITrainingCourseSessionPackage[]>> {
  let endpoint = Endpoints.trainingCourseSessionPackage.root.clone();
  endpoint.Url.searchParams.set("trainingCourseId", course.Id!);

  return await Request.get<ITrainingCourseSessionPackage[]>(endpoint);
}

const TrainingCourseController = {
  getPurchasingOptions,
};

export default TrainingCourseController;
