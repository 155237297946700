import React from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Box,
  CardActions,
  CardHeader,
  WithTheme,
  withTheme,
} from "@material-ui/core";
import CoachesController from "Sdk/Controllers/CoachesController";
import AccountController from "Sdk/Controllers/AccountController";
import PrimaryButton from "Components/PrimaryButton";
import SmallBranding from "Components/SmallBranding";
import Coach, { ICoach } from "Sdk/Data/Models/Coach";
import UserList from "Components/UserList";
import { IUser } from "Sdk/Data/Models/User";
import { IPlayer } from "Sdk/Data/Models/Player";
import LoadCoachesResult from "Sdk/Data/Local/LoadCoachesResult";

function noop() {}
function defaultAlert(message: string) {
  window.alert(message);
}

//#region Properties
export interface CoachSelectionProps {
  /** Display the Kano Train branding even if your licensing permits it to be removed */
  ForceDisplayBranding?: boolean;
  alertProvider?: (message: string) => void;
  successCallback?: () => void;
  overrideLoadCoachesFunction?: () => Promise<LoadCoachesResult>;
  OrganizationId?: string;
  hideCourses?: boolean;
}
interface Props
  extends CoachSelectionProps,
    WithStyles<typeof styles>,
    WithTheme {}
//#endregion

interface State {
  Coaches: ICoach[];
  SelectedUserCount: number;
  ChipText: (string | undefined)[];
}

//#region Component
class CoachSelection extends React.Component<Props, State> {
  private alertProvider: (message: string) => void;
  private successCallback: () => void;

  constructor(props: Props) {
    super(props);
    this.state = { Coaches: [], SelectedUserCount: 0, ChipText: [] };

    this.alertProvider = props.alertProvider ?? defaultAlert;
    this.successCallback = props.successCallback ?? noop;
  }

  private async submitData(): Promise<boolean> {
    let primaryCoachId = this.SelectedUsers.keys().next().value;

    for (let [, user] of this.SelectedUsers) {
      var response = await AccountController.addCoach(
        AccountController.ActiveUser()! as IPlayer,
        user as ICoach,
        user.Id === primaryCoachId
      );

      if (!response.wasSuccess) {
        this.alertProvider(response.error!.message);
        return false;
      }
    }

    this.successCallback();
    return true;
  }

  async loadCoaches(): Promise<LoadCoachesResult> {
    if (this.props.overrideLoadCoachesFunction !== undefined) {
      return await this.props.overrideLoadCoachesFunction!();
    } else {
      return await this.defaultLoadCoaches();
    }
  }

  async defaultLoadCoaches(): Promise<LoadCoachesResult> {
    let coaches = await CoachesController.getNearbyCoaches(
      AccountController.ActiveUser()!.ZipCode,
      10000,
      this.props.OrganizationId
    );
    if (!coaches.wasSuccess) {
      return {
        Success: false,
        Error: coaches.error,
      };
    } else {
      return {
        Success: true,
        Coaches: coaches.data!.map((x) => x.User),
      };
    }
  }

  async componentDidMount() {
    let coachesResult = await this.loadCoaches();

    if (!coachesResult.Success) {
      this.alertProvider(coachesResult.Error?.message!);
      return;
    }

    if (coachesResult.Coaches!.length === 1) {
      // if there's only 1 coach in the organization we don't need the user to pick which one to link with, we should just link to them
      let coach = coachesResult.Coaches![0];

      var response = await AccountController.addCoach(
        AccountController.ActiveUser()! as IPlayer,
        coach,
        true
      );

      if (!response.wasSuccess) {
        this.alertProvider(response.error!.message);
        return false;
      } else {
        this.successCallback();
      }
    } else {
      this.setState({
        Coaches: coachesResult.Coaches!.map((ud) =>
          Coach.createFromInterface(ud)
        ),
      });
    }
  }

  // Javascript map is ordered - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  private SelectedUsers: Map<string, IUser> = new Map<string, IUser>();

  onSelectedChanged(user: IUser, isSelected: boolean): void {
    if (isSelected) {
      this.SelectedUsers.set(user.Id!, user);
    } else {
      this.SelectedUsers.delete(user.Id!);
    }
    this.setState({ SelectedUserCount: this.SelectedUsers.size });

    let chipText: (string | undefined)[] = [];
    let firstUserId = this.SelectedUsers.keys().next().value;
    this.state.Coaches.forEach((coach) => {
      if (coach.Id === firstUserId) {
        chipText.push("primary");
      } else {
        chipText.push(undefined);
      }
    });
    this.setState({ ChipText: chipText });
  }

  render() {
    const { classes, hideCourses } = this.props;
    const { Coaches, ChipText } = this.state;

    return (
      <Box boxShadow={12} height="fit-content" className={classes.root}>
        <Card className={classes.card}>
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              await this.submitData();
            }}
          >
            <CardHeader
              title="Choose the coaches you'd like to train with."
              style={{ textAlign: "center" }}
            />
            <CardContent>
              <UserList
                Users={Coaches}
                ChipText={ChipText}
                onSelectedChanged={(user, isSelected) =>
                  this.onSelectedChanged(user, isSelected)
                }
                hideCourses={hideCourses}
              />
            </CardContent>
            <CardActions className={classes.actionArea}>
              <Box>
                <PrimaryButton
                  type="submit"
                  text="Submit"
                  disabled={this.state.SelectedUserCount === 0}
                />
              </Box>
              <SmallBranding forceDisplay={this.props.ForceDisplayBranding} />
            </CardActions>
          </form>
        </Card>
      </Box>
    );
  }
}
//#endregion

//#region Stylesr
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
    },
    card: {
      padding: "0.25em",
    },
    actionArea: {
      justifyContent: "space-between",
    },
  });
};
//#endregion

export default withTheme(
  withStyles(styles, { withTheme: true })(CoachSelection)
);
