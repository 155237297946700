import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";
import { Clear } from "@material-ui/icons";
import CorrelatedPair from "Components/CorrelatedPair";
import SecondaryButton from "Components/SecondaryButton";
import PrimaryButton from "Components/PrimaryButton";
import WaitList from "Sdk/Controllers/WaitListController";
import * as EmailValidator from "email-validator";
import { withSnackbar, WithSnackbarProps } from "notistack";
import InviteCode from "Sdk/Controllers/InviteCodeController";
import history from "Utils/history";
import UserType from "Sdk/Data/Enums/UserType";

//#region Properties
export interface SignUpProps {
  onCloseTapped: () => void;
  signupType: UserType;
}
interface Props
  extends SignUpProps,
    WithStyles<typeof styles>,
    WithSnackbarProps {}
//#endregion

//#region State
interface SignUpState {
  inviteCode?: string;
  emailAddress?: string;
  joinWaitlist: boolean;
  emailError: boolean;
  inviteCodeError: boolean;
}
//#endregion

//#region Component
class SignUp extends React.Component<Props, SignUpState> {
  private signupType: UserType;
  private inviteCodeRef: React.RefObject<HTMLInputElement>;
  private emailRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    if (this.props.signupType == null) {
      throw new Error("A signup type is required");
    }
    this.signupType = this.props.signupType;
    this.state = {
      inviteCode: "",
      joinWaitlist: false,
      emailAddress: "",
      emailError: false,
      inviteCodeError: false,
    };

    this.inviteCodeRef = React.createRef<HTMLInputElement>();
    this.emailRef = React.createRef<HTMLInputElement>();
  }

  private async joinWaitList() {
    let email = this.state.emailAddress ?? "";
    if (!EmailValidator.validate(email)) {
      this.props.enqueueSnackbar(
        "That email address doesn't look quite right to me 🤔",
        {
          variant: "error",
        }
      );
      this.setState({ emailError: true });
      this.emailRef.current?.focus();
      return;
    }
    let result = await WaitList.join(email, this.signupType);
    if (result.wasSuccess) {
      this.props.enqueueSnackbar(
        "You've joined the wait list! Can't wait to bring you on 😁",
        { variant: "success" }
      );
    } else {
      this.props.enqueueSnackbar(result.error, { variant: "error" });
    }
  }

  private async proceedToSignup() {
    let inviteCode = this.state.inviteCode ?? "";
    if (!(await InviteCode.check(inviteCode))) {
      this.props.enqueueSnackbar(
        `"${this.state.inviteCode}" is not a valid invite code. Ask your coach for one to get started!`,
        { variant: "error" }
      );
      this.setState({ inviteCodeError: true });
      this.inviteCodeRef.current?.focus();
      return;
    } else {
      this.props.enqueueSnackbar("Welcome to Kano Train!", {
        variant: "success",
      });
      history.push(`signup/${inviteCode}`);
    }
  }

  private getSecondaryText() {
    if (this.signupType === UserType.Athlete) {
      return (
        <React.Fragment>
          If you have an invite code from your coach, you can use it to get
          access.
          <br />
          Otherwise, join the waitlist and we'll keep you up to date.
        </React.Fragment>
      );
    } else if (this.signupType === UserType.Coach) {
      return (
        <React.Fragment>
          Due to high demand, we are not currently accepting new beta program
          members.
          <br />
          Join the waitlist and we'll keep you up to date.
        </React.Fragment>
      );
    }
  }

  private getInviteCodeInputAndButton() {
    return (
      <React.Fragment>
        <TextField
          inputRef={this.emailRef}
          label="email address"
          error={this.state.emailError}
          onChange={(event) =>
            this.setState({
              emailAddress: event.target.value ?? undefined,
              emailError: false,
            })
          }
        />
        <PrimaryButton type="submit" text="Join the waitlist" />
      </React.Fragment>
    );
  }

  private async submit(event: React.FormEvent) {
    event.preventDefault();
    if (this.signupType === UserType.Coach || this.state.joinWaitlist) {
      this.joinWaitList();
    } else {
      if ((this.state.inviteCode?.length ?? 0) > 0) {
        await this.proceedToSignup();
      } else {
        this.setState({ joinWaitlist: true });
      }
    }
  }

  private getWaitlistInputAndButton() {
    return (
      <React.Fragment>
        <TextField
          inputRef={this.inviteCodeRef}
          label="invite code"
          helperText="ask your coach for an invitation"
          error={this.state.inviteCodeError}
          onChange={(event) =>
            this.setState({
              inviteCode: event.target.value ?? undefined,
              inviteCodeError: false,
            })
          }
        />
        {(this.state.inviteCode?.length ?? 0) > 0 ? (
          <PrimaryButton type="submit" text="Submit invite code" />
        ) : (
          <SecondaryButton type="submit" text="Join the waitlist instead" />
        )}
      </React.Fragment>
    );
  }

  private getInputAndButton() {
    if (this.signupType === UserType.Coach || this.state.joinWaitlist) {
      return this.getInviteCodeInputAndButton();
    } else {
      return this.getWaitlistInputAndButton();
    }
  }

  render() {
    const { classes, onCloseTapped } = this.props;

    return (
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          action={
            <IconButton
              aria-label="close"
              color="secondary"
              onClick={onCloseTapped}
            >
              <Clear />
            </IconButton>
          }
        />

        <CardContent className={classes.content}>
          <Typography variant="h4" color="textPrimary">
            Kano Train is in closed beta.
          </Typography>
          <Typography color="textSecondary">
            {this.getSecondaryText()}
          </Typography>
        </CardContent>

        <CardActions>
          <form
            onSubmit={(event) => this.submit(event)}
            action=""
            style={{ width: "100%" }}
          >
            <CorrelatedPair breakpoint="md">
              {this.getInputAndButton()}
            </CorrelatedPair>
          </form>
        </CardActions>
      </Card>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "5em",
        paddingRight: "5em",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1.25em",
        paddingRight: "1.25em",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "3em",
        paddingRight: "3em",
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "1em",
        paddingRight: "1em",
      },
    },
    content: {
      width: "100%",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(withSnackbar(SignUp));
