import React from "react";
import {
  Box,
  CardActionArea,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { IUser } from "Sdk/Data/Models/User";
import UserCardSkeleton from "./UserCardSkeleton";
import UserCardComponent from "./UserCardComponent";

//#region Properties
export interface UserCardProps {
  User?: IUser;
  ChipText?: string;
  onSelectedChanged?: (user: IUser, isSelected: boolean) => void;
  hideCourses?: boolean;
}
interface Props extends UserCardProps, WithStyles<typeof styles> {}
//#endregion

interface State {
  IsSelected: boolean;
  IsHovered: boolean;
}

//#region Component
class UserCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { IsSelected: false, IsHovered: false };
  }

  onClick(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();

    const { IsSelected } = this.state;
    const { User, onSelectedChanged } = this.props;

    if (onSelectedChanged && User) {
      this.setState({ IsSelected: !IsSelected });
      onSelectedChanged(User, !IsSelected);
    }
  }

  render() {
    const { classes, User, ChipText, hideCourses } = this.props;
    const { IsSelected, IsHovered } = this.state;
    const IsLoaded = User !== undefined;

    return (
      <Box
        width="25em"
        margin={2}
        boxShadow={(IsSelected || IsHovered) && IsLoaded ? 12 : 8}
        className={`${classes.root} ${IsSelected ? classes.selected : ""} ${
          IsLoaded ? classes.loaded : ""
        }`}
        onClick={(e) => this.onClick(e)}
        onMouseEnter={() => this.setState({ IsHovered: true })}
        onMouseLeave={() => this.setState({ IsHovered: false })}
      >
        {IsLoaded ? (
          <CardActionArea style={{ height: "100%" }}>
            <UserCardComponent
              User={User!}
              ChipText={ChipText}
              hideCourses={hideCourses}
            />
          </CardActionArea>
        ) : (
          <UserCardSkeleton />
        )}
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      transition: "all 200ms ease",
      "&:hover": {
        "&$loaded": {
          transform: "translatey(-10px)",
        },
      },
    },
    loaded: {},
    selected: {
      transform: "translatey(-10px)",
      filter: "brightness(130%)",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(UserCard);
