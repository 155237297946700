import React from "react";
import {
  Box,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import UserCard from "Components/UserCard";
import { IUser } from "Sdk/Data/Models/User";

//#region Properties
export interface UserListProps {
  Users: IUser[];
  ChipText?: (string | undefined)[];
  hideCourses?: boolean;
  onSelectedChanged?: (user: IUser, isSelected: boolean) => void;
}
interface Props extends UserListProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class UserList extends React.Component<Props> {
  render() {
    const { Users, ChipText, onSelectedChanged, hideCourses } = this.props;
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        flexWrap="wrap"
      >
        {Users.length === 0
          ? [...Array(4)].map(() => <UserCard />)
          : Users.map((user, index) => (
              <UserCard
                User={user}
                ChipText={ChipText?.[index]}
                onSelectedChanged={(user, isSelected) =>
                  onSelectedChanged?.(user, isSelected)
                }
                hideCourses={hideCourses}
              />
            ))}
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(UserList);
