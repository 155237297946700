import React from "react";
import "./App.css";
import KanoNavigationArea, {
  NavigationItem,
} from "./Components/KanoNavigationArea";
import Home from "@material-ui/icons/Home";
import Today from "@material-ui/icons/Today";
import Logo from "Images/logo.svg";
import createMuiTheme, { Theme } from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import DarkTheme from "./Themes/DarkTheme";
import LandingPage from "Pages/LandingPage";
import NotificationProvider from "Components/NotificationProvider";
import history from "Utils/history";
import { Router, Switch, Route } from "react-router-dom";
import SignupPage from "Pages/SignupPage";
import { CssBaseline } from "@material-ui/core";
import "fontsource-roboto";
import ContactPage from "Pages/ContactPage";
import DeleteAccountPage from "Pages/DeleteAccountPage";

class App extends React.Component {
  private navigationItems = new Array<NavigationItem>();
  private theme: Theme;

  constructor(props: any) {
    super(props);
    this.theme = createMuiTheme(DarkTheme);

    this.navigationItems.push({
      icon: Home,
      text: "Home",
      route: "/home",
    });
    this.navigationItems.push({
      icon: Today,
      text: "Schedule",
      route: "/schedule",
    });
  }

  render() {
    return (
      <ThemeProvider theme={this.theme}>
        <CssBaseline />
        <NotificationProvider>
          <KanoNavigationArea
            navigationItems={this.navigationItems}
            logo={Logo}
            logoOnClick={() => history.push("/")}
          >
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route
                  path="/signup/:inviteCode"
                  render={({ match }) => {
                    return <SignupPage inviteCode={match.params.inviteCode} />;
                  }}
                />
                <Route path="/contact" render={() => <ContactPage />} />
                <Route path="/delete" render={() => <DeleteAccountPage />} />
              </Switch>
            </Router>
          </KanoNavigationArea>
        </NotificationProvider>
      </ThemeProvider>
    );
  }
}

export default App;
