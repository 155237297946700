import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import SportsFootball from "@material-ui/icons/SportsFootball";
import Assignment from "@material-ui/icons/Assignment";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Timeline from "@material-ui/icons/Timeline";
import Whatshot from "@material-ui/icons/Whatshot";
import CorrelatedPair from "Components/CorrelatedPair";
import PrimaryButton from "Components/PrimaryButton";
import SecondaryButton from "Components/SecondaryButton";
import MultiText from "Components/MultiText";
import { Hidden } from "@material-ui/core";

export interface MainLandingProps {
  onAthleteSignup: () => void;
  onCoachSignup: () => void;
}

interface Props extends MainLandingProps, WithStyles<typeof styles> {}

class MainLanding extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Box m="auto" className={classes.root}>
        <Typography color="textPrimary" variant="h2">
          It's time to <b>Train!</b>
        </Typography>
        <Typography color="textSecondary" variant="h6">
          <MultiText
            className={this.props.classes.secondaryText}
            text={[
              "Your training starts here.",
              "Take the hustle out of private coaching.",
              "Track your training progress.",
              "Show off by winning badges.",
            ]}
            intervalSeconds={5}
          />
        </Typography>
        <Box p={2} />
        {/* Buttons */}
        <CorrelatedPair>
          <PrimaryButton
            text="Sign up to train"
            icon={SportsFootball}
            onClick={this.props.onAthleteSignup}
          />
          <SecondaryButton
            text="Sign up to coach"
            icon={Assignment}
            onClick={this.props.onCoachSignup}
          />
        </CorrelatedPair>
        {/* End Buttons */}
        <Divider variant="middle" className={classes.line} />
        {/* Features */}
        <Grid spacing={3} direction="row" container>
          <Grid
            sm={4}
            xs={6}
            alignItems="flex-start"
            justify="center"
            item
            container
          >
            <Fingerprint
              color="action"
              fontSize="large"
              className={classes.featureIcon}
            />
            <Typography color="textSecondary" align="center">
              Reach your full potential with personalized training
            </Typography>
          </Grid>
          <Grid
            sm={4}
            xs={6}
            alignItems="flex-start"
            justify="center"
            item
            container
          >
            <Timeline
              color="action"
              fontSize="large"
              className={classes.featureIcon}
            />
            <Typography color="textSecondary" align="center">
              Track your progress with detailed training reports
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid
              sm={4}
              alignItems="flex-start"
              justify="center"
              item
              container
            >
              <Whatshot
                color="action"
                fontSize="large"
                className={classes.featureIcon}
              />
              <Typography color="textSecondary" align="center">
                Compete for the top spot with Kano Score
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
        {/* End Features */}
      </Box>
    );
  }
}

//#region Styling
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "1.75em",
        paddingRight: "1.75em",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1em",
        paddingRight: "1em",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "5em",
        paddingRight: "5em",
      },
    },
    secondaryText: {
      fontSize: "100%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "90%",
      },
    },
    line: {
      [theme.breakpoints.down("xs")]: {
        marginTop: "2em",
        marginBottom: "2em",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "3em",
        marginBottom: "3em",
      },
    },
    featureIcon: {
      marginBottom: ".5em",
    },
  });
};

export default withStyles(styles, { withTheme: true })(MainLanding);
