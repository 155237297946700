import { jsonIgnore } from "json-ignore";
import ColorHelpers from "Sdk/Utils/ColorHelpers";
import TrainingType from "../Reference/TrainingType";
import { IAddress } from "./Address";
import { ICoach } from "./Coach";

export interface ITrainingCourse {
  Id?: string;
  TrainingType: TrainingType;
  Coach: ICoach;
  Enabled: boolean;
  HighlightColor: string;
  HighlightColorArgb: number;
  Name: string;
  CoursePolicy: string;
  Description: string;
  Address: IAddress;
  StartDate: Date;
  StopDate: Date;
  MinutesSessionLength: number;
  HoursSchedulingNotice: number;
  ReschedulingTimeLimit: number;
  MaxPlayersPerSession: number;
  MinAge: number;
  MaxAge: number;
  HeaderImageUrl?: string;
  StripeId?: string;
}

export default class TrainingCourse implements ITrainingCourse {
  private id?: string;
  private trainingType: TrainingType;
  private coach: ICoach;
  private enabled: boolean;
  private highlightColorArgb: number;
  private name: string;
  private coursePolicy: string;
  private description: string;
  private address: IAddress;
  private startDate: Date;
  private stopDate: Date;
  private minutesSessionLength: number;
  private hoursSchedulingNotice: number;
  private reschedulingTimeLimit: number;
  private maxPlayersPerSession: number;
  private minAge: number;
  private maxAge: number;
  private headerImageUrl?: string;
  private stripeId?: string;

  //#region Props
  public get Id(): string | undefined {
    return this.id;
  }
  public set Id(value: string | undefined) {
    this.id = value;
  }

  public get TrainingType(): TrainingType {
    return this.trainingType;
  }
  public set TrainingType(value: TrainingType) {
    this.trainingType = value;
  }

  public get Coach(): ICoach {
    return this.coach;
  }
  public set Coach(value: ICoach) {
    this.coach = value;
  }

  public get Enabled(): boolean {
    return this.enabled;
  }
  public set Enabled(value: boolean) {
    this.enabled = value;
  }

  @jsonIgnore()
  public get HighlightColor(): string {
    return ColorHelpers.toColor(this.HighlightColorArgb);
  }
  public set HighlightColor(value: string) {
    throw new Error("Setting Highlight Color is not yet implemented");
  }

  public get HighlightColorArgb(): number {
    return this.highlightColorArgb;
  }
  public set HighlightColorArgb(value: number) {
    this.highlightColorArgb = value;
  }

  public get Name(): string {
    return this.name;
  }
  public set Name(value: string) {
    this.name = value;
  }

  public get CoursePolicy(): string {
    return this.coursePolicy;
  }
  public set CoursePolicy(value: string) {
    this.coursePolicy = value;
  }

  public get Description(): string {
    return this.description;
  }
  public set Description(value: string) {
    this.description = value;
  }

  public get Address(): IAddress {
    return this.address;
  }
  public set Address(value: IAddress) {
    this.address = value;
  }

  public get StartDate(): Date {
    return this.startDate;
  }
  public set StartDate(value: Date) {
    this.startDate = value;
  }

  public get StopDate(): Date {
    return this.stopDate;
  }
  public set StopDate(value: Date) {
    this.stopDate = value;
  }

  public get MinutesSessionLength(): number {
    return this.minutesSessionLength;
  }
  public set MinutesSessionLength(value: number) {
    this.minutesSessionLength = value;
  }

  public get HoursSchedulingNotice(): number {
    return this.hoursSchedulingNotice;
  }
  public set HoursSchedulingNotice(value: number) {
    this.hoursSchedulingNotice = value;
  }

  public get ReschedulingTimeLimit(): number {
    return this.reschedulingTimeLimit;
  }
  public set ReschedulingTimeLimit(value: number) {
    this.reschedulingTimeLimit = value;
  }

  public get MaxPlayersPerSession(): number {
    return this.maxPlayersPerSession;
  }
  public set MaxPlayersPerSession(value: number) {
    this.maxPlayersPerSession = value;
  }

  public get MinAge(): number {
    return this.minAge;
  }
  public set MinAge(value: number) {
    this.minAge = value;
  }

  public get MaxAge(): number {
    return this.maxAge;
  }
  public set MaxAge(value: number) {
    this.maxAge = value;
  }

  public get HeaderImageUrl(): string | undefined {
    return this.headerImageUrl;
  }
  public set HeaderImageUrl(value: string | undefined) {
    this.headerImageUrl = value;
  }

  public get StripeId(): string | undefined {
    return this.stripeId;
  }
  public set StripeId(value: string | undefined) {
    this.stripeId = value;
  }
  //#endregion

  public static createFromInterface(course: ITrainingCourse) {
    return new this(
      course.TrainingType,
      course.Coach,
      course.Enabled,
      course.HighlightColorArgb,
      course.Name,
      course.CoursePolicy,
      course.Description,
      course.Address,
      course.StartDate,
      course.StopDate,
      course.MinutesSessionLength,
      course.HoursSchedulingNotice,
      course.ReschedulingTimeLimit,
      course.MaxPlayersPerSession,
      course.MinAge,
      course.MaxAge,
      course.HeaderImageUrl,
      course.StripeId,
      course.Id
    );
  }

  constructor(
    trainingType: TrainingType,
    coach: ICoach,
    enabled: boolean,
    highlightColorArgb: number,
    name: string,
    coursePolicy: string,
    description: string,
    address: IAddress,
    startDate: Date,
    stopDate: Date,
    minutesSessionLength: number,
    hoursSchedulingNotice: number,
    reschedulingTimeLimit: number,
    maxPlayersPerSession: number,
    minAge: number,
    maxAge: number,
    headerImageUrl?: string,
    stripeId?: string,
    id?: string
  ) {
    this.trainingType = trainingType;
    this.coach = coach;
    this.enabled = enabled;
    this.highlightColorArgb = highlightColorArgb;
    this.name = name;
    this.coursePolicy = coursePolicy;
    this.description = description;
    this.address = address;
    this.startDate = startDate;
    this.stopDate = stopDate;
    this.minutesSessionLength = minutesSessionLength;
    this.hoursSchedulingNotice = hoursSchedulingNotice;
    this.reschedulingTimeLimit = reschedulingTimeLimit;
    this.maxPlayersPerSession = maxPlayersPerSession;
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.headerImageUrl = headerImageUrl;
    this.stripeId = stripeId;
    this.id = id;
  }
}
