import React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import KanoParalaxCircles from "Components/KanoParalaxCircles";
import MainLanding from "./MainLanding";
import SignUp from "./SignUp";
import UserType from "Sdk/Data/Enums/UserType";
import { DisplayTypes } from "./DisplayType";
import DownloadPrompt from "./DownloadPrompt";

//#region Component
class LandingPage extends React.Component<
  WithStyles<typeof styles>,
  {
    displayType: DisplayTypes;
    signupType?: UserType;
  }
> {
  constructor(props: WithStyles<typeof styles>) {
    super(props);

    this.state = { displayType: DisplayTypes.Landing };
  }

  render() {
    const { classes } = this.props;

    var displayComponent: JSX.Element;
    switch (this.state.displayType) {
      case DisplayTypes.ShowSignup:
        displayComponent = (
          <SignUp
            onCloseTapped={() =>
              this.setState({
                displayType: DisplayTypes.Landing,
                signupType: undefined,
              })
            }
            signupType={this.state.signupType!}
          />
        );
        break;
      case DisplayTypes.Landing:
      default:
        displayComponent = (
          <MainLanding
            onAthleteSignup={() =>
              this.setState({
                displayType: DisplayTypes.Download,
                signupType: UserType.Athlete,
              })
            }
            onCoachSignup={() =>
              this.setState({
                displayType: DisplayTypes.ShowSignup,
                signupType: UserType.Coach,
              })
            }
          />
        );
        break;
      case DisplayTypes.Download:
        displayComponent = (
          <DownloadPrompt
            onCloseTapped={() =>
              this.setState({
                displayType: DisplayTypes.Landing,
              })
            }
          />
        );
        break;
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.mainGrid} direction="row">
          {/* Left Side / Top */}
          <Grid sm={12} md={6} alignItems="center" item container>
            {displayComponent}
          </Grid>
          {/* End Left Side / Top */}
          <Hidden smDown>
            <Grid sm={12} md={6} item container>
              <KanoParalaxCircles />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
}
//#endregion

//#region Styling
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      padding: "1.5em",
      [theme.breakpoints.down("xs")]: {
        padding: ".75em",
      },
    },
    mainGrid: {
      height: "100%",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(LandingPage);
