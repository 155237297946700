import React from "react";
import UserFriendlyError from "Utils/UserFriendlyError";
import ProfileInfo from "../../Data/ProfileInfo";
import RegistrationStep from "../../RegistrationStep";
import ProfileInfoStepComponent from "./ProfileInfoStepComponent";

export default class LoginInfoStep implements RegistrationStep<ProfileInfo> {
  public StepTitle = "Profile Information";
  private componentRef: React.RefObject<ProfileInfoStepComponent> = React.createRef();
  private name?: string;
  private data?: ProfileInfo;

  public setName(name: string) {
    this.name = name;
  }

  public getComponent() {
    return (
      <ProfileInfoStepComponent ref={this.componentRef} name={this.name!} />
    );
  }

  componentWillUnmount() {
    // Make sure we've always got the latest before it's inaccessible
    this.getData();
  }

  public getData() {
    let component = this.componentRef.current;
    if (component == null) {
      if (this.data == null)
        return new UserFriendlyError("There was a problem");
      return this.data;
    }

    let data = component.getData();
    if (!(data as UserFriendlyError).message) {
      this.data = data as ProfileInfo;
    }
    return data;
  }
}
