import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

const LinkToApp = () => {
  const theme = useTheme();
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Paper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={3}
        style={{ textAlign: "center" }}
      >
        <Typography variant="h4">Time to schedule your training!</Typography>
        <Box m={1} />
        <Typography variant="h5">
          Download the Kano Train&#8482; app to continue. Use the login you just
          created.
        </Typography>

        <Box p={2} />

        {/* App Icon */}
        <Box
          boxShadow={4}
          style={{
            width: "170px",
            height: "170px",
            borderRadius: "22%",
            overflow: "hidden",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <img
            src="app_icon.png"
            alt="Kano Train"
            style={{
              width: "170px",
              height: "170px",
              borderRadius: "22%",
              overflow: "hidden",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          />
        </Box>

        <Box p={2} />

        <Grid container spacing={3}>
          <Grid
            container
            item
            sm={12}
            md={6}
            justify={isMdOrLarger ? "flex-end" : "center"}
          >
            <a
              href="https://apps.apple.com/us/app/kano-train/id1469823586?itsct=apps_box_badge&amp;itscg=30200"
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: "13px",
                width: "250px",
                height: "83px",
              }}
            >
              <img
                src="app_store_badge.svg"
                alt="Download on the App Store"
                style={{ borderRadius: "13px", width: "250px", height: "83px" }}
              />
            </a>
          </Grid>
          <Grid
            container
            item
            sm={12}
            md={6}
            justify={isMdOrLarger ? "flex-start" : "center"}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.kanoperformance.train&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: "13px",
                width: "250px",
                height: "83px",
              }}
            >
              <img
                alt="Get it on Google Play"
                src="play_store_badge.png"
                style={{ borderRadius: "13px", width: "250px", height: "83px" }}
              />
            </a>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default LinkToApp;
