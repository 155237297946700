import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { ITrainingCourse } from "Sdk/Data/Models/TrainingCourse";
import Endpoints from "Sdk/Config/Endpoints";
import { ITrainingCourseSessionPackage } from "Sdk/Data/Models/TrainingCourseSessionPackage";
import TrainingCourseController from "Sdk/Controllers/TrainingCourseController";
import { sortBy as _sortBy } from "lodash-es";
import { BlueGray } from "Themes/Colors";
import PurchaseSessionPackage from "Components/PurchaseSessionPackage";
import SecondaryButton from "Components/SecondaryButton";

//#region Properties
export interface TrainingCourseCardComponentProps {
  Course: ITrainingCourse;
  IsOpen: boolean;
  BackCallback: (e: React.MouseEvent) => void;
  SuccessCallback: () => void;
}
interface Props
  extends TrainingCourseCardComponentProps,
    WithStyles<typeof styles> {}
interface State {
  SessionPackages: ITrainingCourseSessionPackage[];
  Error: string;
  SelectedPurchaseOption?: ITrainingCourseSessionPackage;
}
//#endregion

//#region Component
class TrainingCourseCardComponent extends React.Component<Props, State> {
  private currencyFormatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  constructor(props: Props) {
    super(props);

    this.state = { SessionPackages: [], Error: "" };
  }

  async componentDidMount() {
    let sessionPackageResponse = await TrainingCourseController.getPurchasingOptions(
      this.props.Course
    );
    if (!sessionPackageResponse.wasSuccess) {
      this.setState({ Error: sessionPackageResponse.error!.message });
      return;
    } else {
      this.setState({
        SessionPackages: _sortBy(
          sessionPackageResponse.data!,
          (sp) => sp.NumberOfSessions
        ),
      });
    }
  }

  onClickPurchaseOption(
    e: React.MouseEvent<HTMLElement>,
    purchaseOption: ITrainingCourseSessionPackage
  ) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ SelectedPurchaseOption: purchaseOption });
  }

  onBackFromPurchaseOption() {
    this.setState({ SelectedPurchaseOption: undefined });
  }

  // TODO: It would be cool if this were more fun on mobile - e.g. swiping down keeps the card with your finger, returns to original position on release
  render() {
    const { classes, Course, IsOpen } = this.props;
    const { SessionPackages, SelectedPurchaseOption } = this.state;

    return (
      <Card className={`${classes.root} ${IsOpen ? classes.scrollable : ""}`}>
        {Course.HeaderImageUrl ? (
          <CardMedia
            image={new URL(
              Course.HeaderImageUrl,
              Endpoints.imageBackend.trainingCourse
            ).toString()}
            style={{ minHeight: "10em", height: "40%" }}
          />
        ) : (
          <CardMedia
            image="fallback_image.svg"
            style={{ minHeight: "10em", height: "40%" }}
          />
        )}
        <CardContent>
          <Box>
            <Box display="inline-block">
              <Typography variant={IsOpen ? "h3" : "h4"}>
                {Course.Name}
              </Typography>
              <hr
                className={classes.coloredUnderline}
                color={Course.HighlightColor}
                style={{
                  color: Course.HighlightColor,
                  backgroundColor: Course.HighlightColor,
                  borderColor: Course.HighlightColor,
                }}
              />
            </Box>
            <Typography color="textSecondary">{Course.Description}</Typography>
            <Box m={2} />
            {IsOpen ? (
              <>
                <Typography variant="h4">Course Details</Typography>
                <Box m={1} />
              </>
            ) : (
              ""
            )}
            <Typography gutterBottom>
              <b>Session Length:</b> {Course.MinutesSessionLength} minutes
            </Typography>
            <Typography gutterBottom>
              <b>Max Athletes:</b> {Course.MaxPlayersPerSession}
            </Typography>
            <Typography gutterBottom>
              <b>Scheduling Notice:</b> {Course.HoursSchedulingNotice} hours
            </Typography>
            <Typography gutterBottom>
              <b>Cancellation Notice:</b> {Course.ReschedulingTimeLimit} hours
            </Typography>
            <Box m={2} />
            {IsOpen ? (
              SelectedPurchaseOption ? (
                <PurchaseSessionPackage
                  Package={SelectedPurchaseOption}
                  OnBack={() => this.onBackFromPurchaseOption()}
                  SuccessCallback={() => this.props.SuccessCallback()}
                />
              ) : (
                <>
                  <Typography variant="h4">
                    Choose a purchasing option
                  </Typography>
                  <Box display="flex" flexWrap="wrap">
                    {!this.state.Error
                      ? SessionPackages.map((purchaseOption) => (
                          <Box
                            m={3}
                            borderColor={Course.HighlightColor}
                            className={classes.purchaseOption}
                            boxShadow={8}
                            onClick={(e) =>
                              this.onClickPurchaseOption(e, purchaseOption)
                            }
                          >
                            <CardActionArea>
                              <Box p={2}>
                                <Typography>
                                  Sessions: {purchaseOption.NumberOfSessions}
                                </Typography>
                                <Typography>
                                  Price:{" "}
                                  {this.currencyFormatter.format(
                                    purchaseOption.Price * 1.12
                                  )}
                                </Typography>
                                <Box m={1} />
                                <Typography color="textSecondary">
                                  That's{" "}
                                  {this.currencyFormatter.format(
                                    (purchaseOption.Price * 1.12) /
                                      purchaseOption.NumberOfSessions
                                  )}{" "}
                                  each
                                </Typography>
                              </Box>
                            </CardActionArea>
                          </Box>
                        ))
                      : this.state.Error}
                  </Box>

                  <SecondaryButton
                    text="Back"
                    onClick={(e) => this.props.BackCallback(e)}
                  />
                </>
              )
            ) : (
              ""
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: "100%",
    },
    scrollable: {
      overflowY: "auto",
    },
    coloredUnderline: {
      height: "4px",
      borderRadius: "2px",
    },
    purchaseOption: {
      borderRadius: "5px",
      borderStyle: "solid",
      borderWidth: "3px",
      "&:hover": {
        filter: "brightness(150%)",
        backgroundColor: BlueGray[500],
        transform: "translatey(-1px)",
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(
  TrainingCourseCardComponent
);
