import React from "react";
import {
  Box,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";

//#region Properties
export interface ContactPageProps {}
interface Props extends ContactPageProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class ContactPage extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Box
        width="100%"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <h1>How to delete your Kano Train account</h1>
        <ol>
          <li>Download the Kano Train app on your iOS or Android Device</li>
          <li>Login</li>
          <li>Navigate to the Account tab</li>
          <li>Tap the "Account Deletion" button</li>
          <li>
            Follow the listed instructions to disable or delete your account.
          </li>
        </ol>

        <h2>Don't want to use the app?</h2>
        <p> You can also contact support for assistance in account deletion</p>
        <p>
          Please email us at{" "}
          <a className={classes.link} href="mailto:support@kanoperformance.com">
            support@kanoperformance.com
          </a>
        </p>
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    link: {
      color: "#ccf",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(ContactPage);
