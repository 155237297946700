export enum EnvironmentType {
  dev,
  demo,
  prod,
}

let environment: EnvironmentType;
if (window.location.hostname === "www.kanotrain.com") {
  environment = EnvironmentType.prod;
} else if (process.env.NODE_ENV === "production") {
  environment = EnvironmentType.demo;
} else {
  environment = EnvironmentType.dev;
}

let stripeKey: string;
if (environment === EnvironmentType.prod) {
  stripeKey =
    "pk_live_51EgIRxDBVLHF3HWLZWtXVzI0K4gPJcHIdVsz9bYbqrTJ6k4ErWrSsI2uuDkIYn9W6fhFYfcvQ3p4xcQUEzquPWyS000enarAIg";
} else if (environment === EnvironmentType.demo) {
  stripeKey =
    "pk_test_51EgIRxDBVLHF3HWL8w8XgUN8MZGeY0RFCfQ5H2UYMP43DVLQnPAwesuFWczf0ou2BbVaYs6HjdDpyYBDtPsfB9sz006U1baazA";
} else if (environment === EnvironmentType.dev) {
  stripeKey =
    "pk_test_51HfAyxDHa45iWCwQZC7ru9YM4eWDT01ZH9DyzktdDPlCAbl4ez7y5B4sIPcFbN1ra7nF04eG7JEqoX1ex6ani9ri00iMaDxG06";
} else {
  throw new Error(`Unrecognized environment ${environment}`);
}

const config = {
  environment,
  stripeKey,
};

export default config;
