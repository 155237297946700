import React from "react";
import {
  Box,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { ICoach } from "Sdk/Data/Models/Coach";
import { BlueGray } from "Themes/Colors";
import CoachOfferingCard from "Components/CoachOfferingCard";
import OfferingUnion from "Sdk/Data/Local/OfferingUnion";

//#region Properties
export interface CoachOfferingListComponentProps {
  Offerings: Map<ICoach, OfferingUnion[]>;
  SuccessCallback: () => void;
}
interface Props
  extends CoachOfferingListComponentProps,
    WithStyles<typeof styles> {}
//#endregion

//#region Component
class CoachOfferingListComponent extends React.Component<Props> {
  render() {
    const { classes, Offerings } = this.props;
    return (
      <Box>
        {Array.from(Offerings, (coachOffering) => (
          <Box className={classes.root}>
            <Box display="flex" alignItems="center">
              <Box border={`1px ${BlueGray[400]} solid`} flexGrow={5} mr={3} />
              <Typography variant="h3">{coachOffering[0].FullName}</Typography>
              <Box
                border={`1px ${BlueGray[400]} solid`}
                flexGrow={100}
                ml={3}
              />
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
              {coachOffering[1]?.map((offering) => (
                <CoachOfferingCard
                  Offering={offering}
                  SuccessCallback={() => this.props.SuccessCallback()}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginTop: "2rem",
      "&:first-child": {
        marginTop: "unset",
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(
  CoachOfferingListComponent
);
