import { ICoach } from "./Coach";
import { IPlayer } from "./Player";

export interface IPlayerCoach {
  Player: IPlayer;
  Coach: ICoach;
  IsPrimaryCoach: boolean;
}

export default class PlayerCoach implements IPlayerCoach {
  private player: IPlayer;
  private coach: ICoach;
  private isPrimaryCoach: boolean;

  //#region Props
  public get Player(): IPlayer {
    return this.player;
  }
  public set Player(value: IPlayer) {
    this.player = value;
  }

  public get Coach(): ICoach {
    return this.coach;
  }
  public set Coach(value: ICoach) {
    this.coach = value;
  }

  public get IsPrimaryCoach(): boolean {
    return this.isPrimaryCoach;
  }
  public set IsPrimaryCoach(value: boolean) {
    this.isPrimaryCoach = value;
  }
  //#endregion

  constructor(player: IPlayer, coach: ICoach, isPrimaryCoach: boolean) {
    this.player = player;
    this.coach = coach;
    this.isPrimaryCoach = isPrimaryCoach;
  }
}
