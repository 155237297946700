import Endpoints from "Sdk/Config/Endpoints";
import ApiResponse from "Sdk/Utils/ApiResponse";
import Request from "Sdk/Services/Request";
import Position from "Sdk/Data/Reference/Position";

async function getPositions(): Promise<ApiResponse<Position[]>> {
  let endpoint = Endpoints.referenceData.getPositions;

  return await Request.get<Position[]>(endpoint);
}

const ReferenceDataController = {
  getPositions,
};

export default ReferenceDataController;
