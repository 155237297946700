import AbstractReferenceData from "./AbstractReferenceData";

export default class AbstractOrderedReferenceData extends AbstractReferenceData {
  private displayOrder: number;

  public get DisplayOrder(): number {
    return this.displayOrder;
  }

  constructor(id: string, name: string, swKey: string, displayOrder: number) {
    super(id, name, swKey);
    this.displayOrder = displayOrder;
  }
}
