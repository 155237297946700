import jwt_decode, { JwtPayload } from "jwt-decode";
import Storage from "./Storage";
import JwtTokenType from "../Data/Enums/JwtTokenType";
import Authentication from "Sdk/Controllers/AuthenticationController";
import Env from "../Utils/Env";
import UserFriendlyError from "Utils/UserFriendlyError";
import ErrorMessage from "../Utils/ErrorMessage";
import { IUser } from "Sdk/Data/Models/User";

const defaultUnknownError = ErrorMessage.cat(
  ErrorMessage.unknownError,
  ErrorMessage.devAlert
);

export default class TokenManager {
  /**
   * Get the user token in its object representation.
   */
  public static async getUserToken(): Promise<JwtPayload | undefined> {
    let token = await this.getUserTokenString();
    if (!token) return undefined;

    return jwt_decode(token);
  }
  /**
   * Get the user token as it was sent by the server.
   */
  public static async getUserTokenString(): Promise<string | undefined> {
    return await Storage.get(JwtTokenType.UserToken);
  }
  private static async setUserTokenString(token: string) {
    return await Storage.set(JwtTokenType.UserToken, token);
  }

  /**
   * Get the device token in its object representation.
   */
  public static async getDeviceToken(): Promise<JwtPayload | undefined> {
    let token = await this.getDeviceTokenString();
    if (!token) return undefined;

    return jwt_decode(token);
  }
  /**
   * Get the device token as it was sent by the server.
   */
  public static async getDeviceTokenString(): Promise<string | undefined> {
    return await Storage.get(JwtTokenType.DeviceToken);
  }
  private static async setDeviceTokenString(token: string) {
    return await Storage.set(JwtTokenType.DeviceToken, token);
  }

  /**
   * Make sure the device token is valid.
   *
   * If no error is returned, the device token is guaranteed to be valid for
   * at least 5 seconds.
   */
  public static async ensureDeviceTokenValid(): Promise<UserFriendlyError | void> {
    let token = await this.getDeviceToken();

    // TODO: ||
    if (!token || !this.tokenIsValid(token)) {
      return await this.refreshDeviceToken();
    }
  }

  /**
   * Make sure the user token is valid.
   *
   * If no error is returned, the user token is guaranteed to be valid for
   * at least 5 seconds.
   */
  public static async ensureUserTokenValid() {
    let token = await this.getDeviceToken();

    if (!token) {
      return new UserFriendlyError("Please log in to use this feature.");
    } else if (!this.tokenIsValid(token)) {
      return await this.refreshUserToken();
    }
  }

  public static async login(
    user: IUser,
    password: string
  ): Promise<UserFriendlyError | void> {
    let response = await Authentication.login(user, password);

    if (!response.wasSuccess) {
      return response.error;
    } else if (!response.data) {
      return new UserFriendlyError(
        ErrorMessage.cat(ErrorMessage.unknownError, ErrorMessage.devAlert)
      );
    }

    this.setUserTokenString(response.data.Token);
  }

  // We'll consider a token expired if it expires in less than this many ms.
  private static expirationBuffer = 5000;
  private static tokenIsValid(token: JwtPayload): boolean {
    // Should always be defined
    if (token.exp === undefined) return false;

    return Date.now() + 5000 < token.exp * 1000;
  }

  private static async refreshDeviceToken(): Promise<UserFriendlyError | void> {
    let result = await Authentication.requestDeviceToken(
      Env.DeviceTokenClientName,
      Env.DeviceTokenClientId
    );
    if (!result.wasSuccess) return result.error;
    if (!result.data) return new UserFriendlyError(defaultUnknownError);

    this.setDeviceTokenString(result.data);
  }

  /**
   * Refreshes the user token. Assumes there is a valid existing token.
   */
  private static async refreshUserToken(): Promise<UserFriendlyError | void> {
    let userToken = await this.getUserTokenString();
    if (!userToken) return new UserFriendlyError(defaultUnknownError);

    let result = await Authentication.refreshUserToken(userToken);
    if (!result.wasSuccess) return result.error;
    if (!result.data) return new UserFriendlyError(defaultUnknownError);

    this.setUserTokenString(result.data.token);
  }
}
