import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import CoachesController from "Sdk/Controllers/CoachesController";
import AccountController from "Sdk/Controllers/AccountController";
import { IPlayer } from "Sdk/Data/Models/Player";
import Coach, { ICoach } from "Sdk/Data/Models/Coach";
import TrainingCourse from "Sdk/Data/Models/TrainingCourse";
import SmallBranding from "Components/SmallBranding";
import CoachOfferingList from "Components/CoachOfferingList";
import TrainingCamp from "Sdk/Data/Models/TrainingCamp";
import TrainingCampsController from "Sdk/Controllers/TrainingCampsController";
import LoadOfferingsResult from "Sdk/Data/Local/LoadOfferingsResult";
import OfferingUnion from "Sdk/Data/Local/OfferingUnion";

//#region Properties
export interface OfferingPurchasingProps {
  ForceDisplayBranding?: boolean;
  alertProvider: (message: string) => void;
  successCallback?: () => void;
  overrideLoadOfferingsFunction?: () => Promise<LoadOfferingsResult>;
  overrideTitle?: string;
}
interface Props extends OfferingPurchasingProps, WithStyles<typeof styles> {}
//#endregion

interface State {
  Offerings: Map<ICoach, OfferingUnion[]>;
}

//#region Component
class OfferingPurchasing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { Offerings: new Map() };
  }

  async componentDidMount() {
    var loadResult = await this.loadOfferings();
    if (!loadResult.Success) {
      this.props.alertProvider(
        loadResult.Error?.message ?? "There was an error."
      );
      return;
    }

    this.setState({ Offerings: loadResult.Offerings! });
  }

  async loadOfferings(): Promise<LoadOfferingsResult> {
    if (this.props.overrideLoadOfferingsFunction !== undefined) {
      return await this.props.overrideLoadOfferingsFunction!();
    } else {
      return await this.defaultLoadOfferings();
    }
  }

  async defaultLoadOfferings(): Promise<LoadOfferingsResult> {
    let activeUser = (await AccountController.ActiveUser()) as IPlayer;
    let PlayerCoaches = await CoachesController.getCoachesForPlayer(activeUser);
    if (!PlayerCoaches.wasSuccess) {
      return {
        Success: false,
        Error: PlayerCoaches.error,
      };
    }

    let coaches = PlayerCoaches.data!.map<ICoach>((playerCoach) =>
      Coach.createFromInterface(playerCoach.Coach)
    );

    var offerings = new Map<ICoach, OfferingUnion[]>();
    for (const coach of coaches) {
      try {
        offerings.set(coach, await this.getOfferingsForCoach(coach));
      } catch (e: any) {
        return {
          Success: false,
          Error: e,
        };
      }
    }

    return {
      Success: true,
      Offerings: offerings,
    };
  }

  async getOfferingsForCoach(coach: ICoach): Promise<OfferingUnion[]> {
    const coursesPromise = CoachesController.getCourses(coach);
    const campsPromise = TrainingCampsController.getCampsForCoach(coach);

    const coursesResult = await coursesPromise;
    if (!coursesResult.wasSuccess) {
      throw coursesResult.error;
    }

    const campsResult = await campsPromise;
    if (!campsResult.wasSuccess) {
      throw campsResult.error;
    }

    const courses = coursesResult.data!.map<OfferingUnion>((course) => ({
      Course: TrainingCourse.createFromInterface(course),
    }));

    const camps = campsResult.data!.map<OfferingUnion>((camp) => ({
      Camp: TrainingCamp.createFromInterface(camp),
    }));

    return courses.concat(camps);
  }

  render() {
    const { classes, overrideTitle } = this.props;
    const { Offerings } = this.state;
    return (
      <Box boxShadow={12} height="fit-content" className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            title={overrideTitle ?? "Choose your first course to purchase."}
            style={{ textAlign: "center" }}
          />
          <CardContent>
            <CoachOfferingList
              Offerings={Offerings}
              SuccessCallback={() => this.props.successCallback?.()}
            />
          </CardContent>
          <CardActions className={classes.actionArea}>
            <SmallBranding forceDisplay={this.props.ForceDisplayBranding} />
          </CardActions>
        </Card>
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    card: {
      padding: "0.25em",
    },
    actionArea: {
      justifyContent: "space-between",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(OfferingPurchasing);
