import React, { ChangeEvent } from "react";
import { Box, TextField } from "@material-ui/core";
import PositionInfo from "../../Data/PositionInfo";
import UserFriendlyError from "Utils/UserFriendlyError";
import RegistrationStepComponent from "../RegistrationStepComponent";
import classes from "../Steps.module.css";
import Sport from "Sdk/Data/Reference/Sport";
import Position from "Sdk/Data/Reference/Position";
import PlayingLevel, {
  PlayingLevelOptions,
} from "Sdk/Data/Reference/PlayingLevel";
import EnumSelect from "Components/Inputs/EnumSelect";
import LabeledCheckbox from "Components/Inputs/LabeledCheckbox";
import ScholarshipLevel, {
  ScholarshipLevelOptions,
} from "Sdk/Data/Reference/ScholarshipLevel";
import ReferenceDataController from "Sdk/Controllers/ReferenceDataController";
import GenericSelect from "Components/Inputs/GenericSelect";
import PositionsController from "Sdk/Controllers/PositionsController";

function defaultAlert(message: string) {
  window.alert(message);
}

export interface PositionInfoStepComponentProps {
  OrganizationId?: string;
  alertProvider?: (message: string) => void;
  data?: PositionInfo;
}

interface Props
  extends PositionInfoStepComponentProps,
    JSX.IntrinsicAttributes {}

interface State {
  PositionInfo: Partial<PositionInfo>;
  AllPositionOptions?: Position[];
  SportOptions?: Sport[];
  CurrentPositionOptions?: Position[];
}

//#region Component
export default class PositionInfoStepComponent
  extends React.Component<Props, State>
  implements RegistrationStepComponent<PositionInfo> {
  private alertProvider: (message: string) => void;
  public StepTitle = "Personal Information";

  constructor(props: Props) {
    super(props);

    this.alertProvider = props.alertProvider ?? defaultAlert;
    let positionInfo: Partial<PositionInfo>;
    if (process.env.NODE_ENV === "development") {
      positionInfo = this.props.data ?? {
        PlayingLevel: PlayingLevel.HighSchool,
        GraduationYear: 2024,
        YearsExperience: 0,
      };
    } else {
      positionInfo = this.props.data ?? {};
    }

    this.state = { PositionInfo: positionInfo };
  }

  async componentDidMount() {
    let rawPositions: Position[] = [];

    if (!!this.props.OrganizationId) {
      // if we have an org ID, we should find the org's supported positions
      let getPositionsResponse = await PositionsController.getSupportedPositionsForOrganization(
        this.props.OrganizationId
      );
      if (!getPositionsResponse.wasSuccess) {
        this.alertProvider(getPositionsResponse.error?.message!);
      } else {
        rawPositions = getPositionsResponse.data!;
      }
    } else {
      let getPositionsResponse = await ReferenceDataController.getPositions();
      if (!getPositionsResponse.wasSuccess) {
        this.alertProvider(getPositionsResponse.error?.message!);
      } else {
        rawPositions = getPositionsResponse.data!;
      }
    }

    let uniqueSports: Sport[] = [];
    rawPositions.forEach((position) => {
      let alreadyExists: boolean = false;
      uniqueSports.forEach((existingSport) => {
        if (existingSport.Id === position.Sport!.Id) {
          alreadyExists = true;
        }
      });
      if (!alreadyExists) {
        uniqueSports.push(position.Sport);
      }
    });

    let newPositionOptions: Position[] = this.getCompatiblePositions(
      uniqueSports[0],
      rawPositions ?? []
    );

    // this logic is duplicated in setPositionInfo!!! keep consistent
    let defaultPosition: Position | undefined =
      newPositionOptions.length === 1 ? newPositionOptions[0] : undefined;

    this.setState({
      SportOptions: uniqueSports,
      AllPositionOptions: rawPositions,
      PositionInfo: {
        Sport: uniqueSports[0],
        Position: defaultPosition,
        ...this.state.PositionInfo,
      },
      CurrentPositionOptions: newPositionOptions,
    });
  }

  private getCompatiblePositions(
    sport: Sport | undefined,
    positions: Position[]
  ) {
    let selectedPositions: Position[] = [];
    if (sport === undefined) return selectedPositions;

    positions.forEach((position) => {
      if (position.Sport.Id === sport.Id) {
        selectedPositions.push(position);
      }
    });

    return selectedPositions;
  }

  private setPositionInfo(positionInfo: Partial<PositionInfo>) {
    const { Sport: newSport } = positionInfo;

    if (newSport !== undefined && newSport !== this.state.PositionInfo.Sport) {
      let newPositionOptions: Position[] = this.getCompatiblePositions(
        newSport,
        this.state.AllPositionOptions ?? []
      );

      // if there's only one position for this sport, default to it
      // this logic is duplicated in componentDidMount!!! keep consistent
      let defaultPosition: Position | undefined =
        newPositionOptions.length === 1 ? newPositionOptions[0] : undefined;

      this.setState((prevState) => ({
        CurrentPositionOptions: newPositionOptions,
        PositionInfo: {
          ...prevState.PositionInfo,
          ...positionInfo,
          Position: defaultPosition,
        },
      }));
    } else {
      this.setState(
        (prevState) => ({
          PositionInfo: {
            ...prevState.PositionInfo,
            ...positionInfo,
          },
        }),
        () => {
          console.log(this.state.PositionInfo.Position?.SWKey ?? "none");
        }
      );
    }
  }

  public getData(): PositionInfo | UserFriendlyError {
    let positionInfo: Partial<PositionInfo> = this.state.PositionInfo;
    let currentYear = new Date().getFullYear();

    if (!positionInfo.PlayingLevel) {
      return new UserFriendlyError(
        "Our coaches need to know your experience level to provide the best training experience. Please enter one."
      );
    } else if (positionInfo.YearsExperience == null) {
      return new UserFriendlyError(
        "In order to prepare the best training experience, our coaches need to know how many years you've played in this position."
      );
    } else if (!positionInfo.GraduationYear) {
      return new UserFriendlyError(
        "We believe competition is important. We use your graduation year to build some of our leaderboards. Please enter one."
      );
    } else if (positionInfo.GraduationYear > currentYear + 20) {
      return new UserFriendlyError(
        "Not much of an optimist I see. Please choose a four digit graduation year some time in the next 20 years."
      );
    } else if (positionInfo.GraduationYear < currentYear - 90) {
      return new UserFriendlyError("Please choose a valid graduation year.");
    } else if (positionInfo.Sport == null) {
      return new UserFriendlyError(
        "Several of our features depend on the sport you play. Please choose one (you can add more later)"
      );
    } else if (positionInfo.Position == null) {
      return new UserFriendlyError(
        "Several of our features depend on your position. Please choose one (you can add more later)"
      );
    }

    if (
      positionInfo.PlayingLevel === PlayingLevel.HighSchool ||
      positionInfo.PlayingLevel === PlayingLevel.College
    ) {
      if (positionInfo.IsVarsityStarter == null) {
        positionInfo.IsVarsityStarter = false;
      } else if (
        positionInfo.IsVarsityStarter &&
        positionInfo.YearsOnVarsity == null
      ) {
        return new UserFriendlyError(
          "Our coaches need to know how many years you've been on varsity in order to provide the best training experience."
        );
      }
    }

    if (positionInfo.PlayingLevel === PlayingLevel.HighSchool) {
      if (positionInfo.HasScholarshipOffers == null) {
        positionInfo.HasScholarshipOffers = false;
      } else if (
        positionInfo.HasScholarshipOffers &&
        positionInfo.ScholarshipLevel == null
      ) {
        return new UserFriendlyError(
          "Please enter your highest scholarship offer"
        );
      }
    }

    if ((positionInfo.YearsExperience ?? 0) <= 0) {
      positionInfo.YearsExperience = 0;
    }
    if (
      positionInfo.IsVarsityStarter &&
      (positionInfo.YearsOnVarsity ?? 0) <= 0
    ) {
      positionInfo.YearsOnVarsity = 0;
    }

    // This is used to keep typescript validation in place
    // `return state as PositionInfo` would prevent it from warning us if we forget to validate new properties
    return {
      PlayingLevel: positionInfo.PlayingLevel,
      YearsExperience: positionInfo.YearsExperience,
      GraduationYear: positionInfo.GraduationYear,
      Sport: positionInfo.Sport,
      Position: positionInfo.Position,
      IsVarsityStarter: positionInfo.IsVarsityStarter,
      YearsOnVarsity: positionInfo.YearsOnVarsity,
      HasScholarshipOffers: positionInfo.HasScholarshipOffers,
      ScholarshipLevel: positionInfo.ScholarshipLevel,
    };
  }

  render() {
    return (
      <>
        <Box className={classes.root}>
          <GenericSelect
            required
            label="Sport"
            value={this.state.PositionInfo.Sport?.SWKey ?? ""}
            onChange={(event) => {
              let newSport: Sport | undefined = this.state.SportOptions?.find(
                (element) => {
                  return element.SWKey === (event.target.value as string);
                }
              );

              this.setPositionInfo({
                ...this.state.PositionInfo,
                Sport: newSport,
                Position: undefined,
              });
            }}
            className={classes.field}
          >
            {this.state.SportOptions?.map((sport, index) => (
              <option value={sport.SWKey}>{sport.Name}</option>
            ))}
          </GenericSelect>
          <GenericSelect
            required
            label="Position"
            value={this.state.PositionInfo.Position?.SWKey ?? "none"}
            onChange={(event) => {
              let newPosition:
                | Position
                | undefined = this.state.CurrentPositionOptions?.find(
                (element) => {
                  return element.SWKey === (event.target.value as string);
                }
              );
              this.setPositionInfo({
                ...this.state.PositionInfo,
                Position: newPosition,
              });
            }}
            className={classes.field}
          >
            <option value="none">Select Position</option>
            {this.state.CurrentPositionOptions?.map((position, index) => (
              <option value={position.SWKey}>{position.Name}</option>
            ))}
          </GenericSelect>
          <EnumSelect
            required
            enumeration={{
              Youth: PlayingLevel.Youth.Name,
              MiddleSchool: PlayingLevel.MiddleSchool.Name,
              HighSchool: PlayingLevel.HighSchool.Name,
              College: PlayingLevel.College.Name,
              Professional: PlayingLevel.Professional.Name,
            }}
            label="Playing Level"
            value={this.state.PositionInfo.PlayingLevel?.Name}
            onChange={(event) =>
              this.setPositionInfo({
                PlayingLevel:
                  PlayingLevel[event.target.value as PlayingLevelOptions],
              })
            }
            className={classes.field}
          />
          <TextField
            required
            className={classes.field}
            variant="filled"
            label="High School Graduation Year"
            helperText={`full year - e.g. ${new Date().getFullYear()}`}
            value={this.state.PositionInfo.GraduationYear}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.setPositionInfo({
                GraduationYear: parseInt(event.target.value),
              })
            }
            inputProps={{ type: "number", inputMode: "decimal" }}
          />
          <TextField
            required
            className={classes.field}
            variant="filled"
            label="Position Experience (years)"
            value={this.state.PositionInfo.YearsExperience}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.setPositionInfo({
                YearsExperience: parseInt(event.target.value),
              })
            }
            inputProps={{ type: "number", inputMode: "decimal" }}
          />
        </Box>
        {/* Varsity Starter */}
        {this.state.PositionInfo.PlayingLevel === PlayingLevel.HighSchool ||
        this.state.PositionInfo.PlayingLevel === PlayingLevel.College ? (
          <Box className={classes.root}>
            <LabeledCheckbox
              className={classes.field}
              label="Are you a varsity starter?"
              value={this.state.PositionInfo.IsVarsityStarter ?? false}
              onChange={(value) =>
                this.setPositionInfo({ IsVarsityStarter: value })
              }
            />
            {this.state.PositionInfo.IsVarsityStarter ? (
              <TextField
                required
                className={classes.field}
                variant="filled"
                label="Years Starting"
                value={this.state.PositionInfo.YearsOnVarsity}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  this.setPositionInfo({
                    YearsOnVarsity: parseInt(event.target.value),
                  })
                }
                inputProps={{ type: "number", inputMode: "decimal" }}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}
        {/* End Varsity Starter */}
        {/* Scholarship Offers */}
        {this.state.PositionInfo.PlayingLevel === PlayingLevel.HighSchool ? (
          <Box className={classes.root}>
            <LabeledCheckbox
              className={classes.field}
              label="Do you have any public scholarship offers?"
              value={this.state.PositionInfo.HasScholarshipOffers ?? false}
              onChange={(value) =>
                this.setPositionInfo({ HasScholarshipOffers: value })
              }
            />
            {this.state.PositionInfo.HasScholarshipOffers ? (
              <EnumSelect
                required
                exactName
                enumeration={{
                  "D-I": ScholarshipLevel.D3.Name,
                  D2: ScholarshipLevel.D2.Name,
                  D3: ScholarshipLevel.D1.Name,
                  Ivy: ScholarshipLevel.Ivy.Name,
                  Power4: ScholarshipLevel.Power5.Name,
                }}
                label="Highest Scholarship Offer"
                value={this.state.PositionInfo.ScholarshipLevel?.Name}
                onChange={(event) =>
                  this.setPositionInfo({
                    ScholarshipLevel:
                      ScholarshipLevel[
                        event.target.value as ScholarshipLevelOptions
                      ],
                  })
                }
                className={classes.field}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}
        {/* End Scholarship Offers */}
      </>
    );
  }
}
//#endregion
