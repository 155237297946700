import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { IUser } from "Sdk/Data/Models/User";
import Skeleton from "@material-ui/lab/Skeleton";
import { ITrainingCourse } from "Sdk/Data/Models/TrainingCourse";
import CoachesController from "Sdk/Controllers/CoachesController";
import UserType from "Sdk/Data/Enums/UserType";
import TrainingCourse from "Sdk/Data/Models/TrainingCourse";

//#region Properties
export interface UserCardComponentProps {
  User: IUser;
  ChipText?: string;
  alertProvider?: (message: string) => void;
  onSelectedChanged?: (user: IUser, isSelected: boolean) => void;
  hideCourses?: boolean;
}
interface Props extends UserCardComponentProps, WithStyles<typeof styles> {}
//#endregion

interface State {
  Courses: ITrainingCourse[];
  IsLoaded: boolean;
}

//#region Component
class UserCardComponent extends React.Component<Props, State> {
  private alertProvider: (message: string) => void;

  constructor(props: Props) {
    super(props);

    this.state = { Courses: [], IsLoaded: false };
    this.alertProvider =
      this.props.alertProvider ?? ((message) => window.alert(message));
  }

  async componentDidMount() {
    let { User } = this.props;
    if (User.Discriminator === UserType.Coach && !this.props.hideCourses) {
      let courseResponse = await CoachesController.getCourses(User);
      if (!courseResponse.wasSuccess || courseResponse.data == null) {
        courseResponse.wasSuccess = false;
        this.alertProvider(courseResponse.error!.message);
        return;
      }

      this.setState({
        Courses: courseResponse.data.map((c) =>
          TrainingCourse.createFromInterface(c)
        ),
        IsLoaded: true,
      });
    }
  }

  renderCourses() {
    const { classes } = this.props;
    const { Courses } = this.state;

    if (this.props.hideCourses) {
      return null;
    } else if (!this.state.IsLoaded) {
      return [...Array(4)].map(() => (
        <Box padding={1}>
          <Skeleton
            animation="wave"
            variant="rect"
            width="95%"
            height={50}
            className={classes.center}
          />
        </Box>
      ));
    } else {
      return Courses.map((course) => (
        <Box
          borderRadius={5}
          height={50}
          maxHeight={50}
          width="95%"
          marginLeft="auto"
          marginRight="auto"
          marginTop={1}
          display="flex"
          alignItems="center"
          overflow="hidden"
        >
          <Box paddingRight={2}>
            <Typography style={{ color: course.HighlightColor }}>
              &#x2b24;
            </Typography>
          </Box>
          <Box maxHeight="inherit">
            <Typography color="textPrimary">{course.Name}</Typography>
            <Typography color="textSecondary" className={classes.secondary}>
              {course.Description}
            </Typography>
          </Box>
        </Box>
      ));
    }
  }

  render() {
    const { classes, User, ChipText } = this.props;
    return (
      <Card style={{ height: "100%" }}>
        <CardHeader
          avatar={
            <Avatar
              alt="Profile Picture"
              src={`https://kanostorage.blob.core.windows.net/${User.Id}/${User.AvatarThumb}`}
            >
              {User.FirstName[0] + User.LastName[0]}
            </Avatar>
          }
          title={<Typography>{User.FullName}</Typography>}
          subheader={
            <Typography color="textSecondary" className={classes.secondary}>
              {User.Organization?.Name}
            </Typography>
          }
          action={ChipText ? <Chip label={ChipText} color="primary" /> : ""}
        />
        <CardContent>
          <Box>
            <Box
              marginLeft={1}
              marginRight={1}
              marginBottom={2}
              borderBottom="1px solid #FFF2"
            />
            {this.renderCourses()}
          </Box>
        </CardContent>
      </Card>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    center: {
      margin: "auto",
    },
    secondary: {
      fontSize: "0.9rem",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(UserCardComponent);
