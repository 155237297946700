import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import AccessibleForward from "@material-ui/icons/AccessibleForward";
import Code from "@material-ui/icons/Code";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

//#region Properties
export interface KanoFooterProps {}
interface Props extends KanoFooterProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class KanoFooter extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Grid direction="column" container className={classes.root}>
        <Grid direction="row" item container justify="center">
          <Typography color="textSecondary" className={classes.text}>
            <small>
              Copyright &copy; 2020 <span aria-label="kaino">Kano</span>{" "}
              Performance, LLC
            </small>
          </Typography>
        </Grid>
        <Grid direction="row" item container justify="center">
          <Grid item sm={5} xs={12} justify="flex-end">
            <Typography color="textSecondary" className={classes.leftText}>
              <small>
                <b>
                  Handicap Accessible
                  <AccessibleForward
                    className={classes.icon}
                    fontSize="small"
                    style={{ paddingRight: 0 }}
                  />
                </b>
              </small>
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid item sm={1} container justify="center">
              <Typography color="textSecondary" className={classes.text}>
                <small>|</small>
              </Typography>
            </Grid>
          </Hidden>
          <Grid
            item
            sm={5}
            xs={12}
            container
            justify="center"
            className={classes.rightTextParent}
          >
            <Typography color="textSecondary" className={classes.rightText}>
              <small>
                <b>
                  <Code
                    className={classes.icon}
                    fontSize="small"
                    style={{ paddingLeft: 0 }}
                    aria-label="coded"
                    aria-hidden="false"
                  />
                  with
                  <FavoriteBorder
                    className={classes.icon}
                    fontSize="small"
                    aria-label="love"
                    aria-hidden="false"
                  />
                  by <span aria-label="kaino">Kano</span>
                </b>
              </small>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      verticalAlign: "middle",
      padding: ".2em",
    },
    text: {
      textAlign: "center",
    },
    leftText: {
      justifyContent: "center",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "end",
      },
    },
    rightText: {
      justifyContent: "center",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "start",
      },
    },
    rightTextParent: {
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "start",
      },
    },
    icon: {
      verticalAlign: "middle",
      paddingLeft: "0.1875em",
      paddingRight: "0.1875em",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(KanoFooter);
