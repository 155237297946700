import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import flattenChildren from "react-keyed-flatten-children";

//#region Properties
export interface CorrelatedPairProps {
  breakpoint?: Breakpoint;
  spacing?: GridProps["spacing"];
}
interface Props extends CorrelatedPairProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class CorrelatedPair extends React.Component<Props> {
  render() {
    let children =
      this.props.children == null ? null : flattenChildren(this.props.children);
    if (children == null || children.length !== 2) {
      throw new Error("Correllated pair requires two children");
    }

    const { classes } = this.props;
    let breakpoint = this.props.breakpoint ?? "xs";
    let spacing = this.props.spacing ?? 2;

    let xsColumns: GridProps["xs"] = 6;
    let smColumns: GridProps["sm"] = 6;
    let mdColumns: GridProps["md"] = 6;
    let lgColumns: GridProps["lg"] = 6;
    let xlColumns: GridProps["xl"] = 6;

    switch (breakpoint) {
      case "xl":
        xlColumns = 12;
      /* falls through */
      case "lg":
        lgColumns = 12;
      /* falls through */
      case "md":
        mdColumns = 12;
      /* falls through */
      case "sm":
        smColumns = 12;
      /* falls through */
      case "xs":
        xsColumns = 12;
        break;
      default:
        break;
    }

    let FirstChild = children[0];
    let SecondChild = children[1];

    return (
      <Grid spacing={spacing} justify="center" direction="row" container>
        <Grid
          xs={xsColumns}
          sm={smColumns}
          md={mdColumns}
          lg={lgColumns}
          xl={xlColumns}
          justify="center"
          alignItems="center"
          item
          container
          className={classes.child}
        >
          {FirstChild}
        </Grid>

        <Grid
          xs={xsColumns}
          sm={smColumns}
          md={mdColumns}
          lg={lgColumns}
          xl={xlColumns}
          justify="center"
          alignItems="center"
          item
          container
          className={classes.child}
        >
          {SecondChild}
        </Grid>
      </Grid>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    child: {
      "& > *": {
        width: "100%",
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(CorrelatedPair);
