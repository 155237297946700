import React, { ReactNode } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
// import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// import Menu from "@material-ui/icons/Menu";
// import AccountCircle from "@material-ui/icons/AccountCircle";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthDown, WithWidth } from "@material-ui/core/withWidth";

export interface KanoHeaderProps extends WithStyles<typeof styles> {
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  brandName: ReactNode;
  logoOnClick: () => void;
}

class KanoHeader extends React.Component<KanoHeaderProps & WithWidth> {
  render() {
    const { classes, logo } = this.props;
    let Logo = logo;

    return (
      <div className={classes.root}>
        <Grid alignItems="center" direction="row" container>
          <Grid xs={2} md={1} justify="center" item container>
            {/* <IconButton aria-label="open navigation drawer">
              <Menu />
            </IconButton> */}
          </Grid>
          <Grid
            xs={8}
            md={10}
            justify={
              isWidthDown("xs", this.props.width) ? "center" : "flex-start"
            }
            alignItems="center"
            direction="row"
            container
            item
          >
            <Button
              aria-label="go home"
              color="primary"
              onClick={this.props.logoOnClick}
            >
              <Logo alignmentBaseline="central" className={classes.logo} />
              <Hidden xsDown>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={classes.brandName}
                >
                  {this.props.brandName}
                </Typography>
              </Hidden>
            </Button>
          </Grid>
          <Grid xs={2} md={1} justify="center" item container>
            {/* <IconButton aria-label="log in">
              <AccountCircle fontSize="large" />
            </IconButton> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "1.5rem 0.5rem",
      [theme.breakpoints.down("xs")]: {
        padding: "0.5rem 0.5rem",
      },
    },
    logo: {
      height: "3.25em",
      [theme.breakpoints.up("sm")]: {
        paddingRight: "1em",
      },
    },
    brandName: {
      textTransform: "capitalize",
    },
  });

export default withWidth()(withStyles(styles, { withTheme: true })(KanoHeader));
