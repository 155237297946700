import URLs from "../Config/Endpoints";
import Request from "../Services/Request";
import ApiResponse from "../Utils/ApiResponse";
import WaitListEntry from "../Data/Models/WaitListEntry";
import UserType from "../Data/Enums/UserType";

async function join(
  email: string,
  userType: UserType
): Promise<ApiResponse<boolean>> {
  return await Request.post(
    URLs.waitlist.root,
    new WaitListEntry(email, userType)
  );
}

const WaitListController = {
  join,
};

export default WaitListController;
