import Box from "@material-ui/core/Box";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";

//#region Component Properties
export interface KanoPaperCircleProps {
  diameter: number; // diameter of the circle - measured in em
  backgroundColor?: string; // background color of the circle
  borderColor?: string; // border color of the circle
  background?: string; // background of the circle - overrides backgroundColor
  shadow?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24; // z-axis for shadow
}
interface Props extends WithStyles<typeof styles>, KanoPaperCircleProps {}
//#endregion

//#region Component
class KanoPaperCircle extends React.Component<
  Props & React.HTMLAttributes<HTMLDivElement>
> {
  // constructor(props: Props) {
  //     super(props);
  // }
  render() {
    const {
      classes,
      background,
      backgroundColor,
      borderColor,
      diameter,
      className,
    } = this.props;

    let style: React.CSSProperties = {};

    style.height = style.width = `${diameter}em`;
    style.backgroundColor = backgroundColor ?? "transparent";
    if (borderColor != null) {
      style.border = `solid thin ${borderColor} `;
    }

    if (background != null) {
      style.background = background;
    }
    let shadow = this.props.shadow ?? 0;
    return (
      <Box
        boxShadow={shadow}
        className={`${classes.root} ${className}`}
        style={{ ...style, ...this.props.style }}
      />
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "50%",
      backgroundSize: "contain",
    },
  });
//#endregion

export default withStyles(styles, { withTheme: true })(KanoPaperCircle);
