import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { ICoach } from "Sdk/Data/Models/Coach";
import CoachOfferingListSkeleton from "./CoachOfferingListSkeleton";
import CoachOfferingListComponent from "./CoachOfferingListComponent";
import OfferingUnion from "Sdk/Data/Local/OfferingUnion";

//#region Properties
export interface CoachOfferingListProps {
  Offerings?: Map<ICoach, OfferingUnion[]>;
  SuccessCallback: () => void;
}
interface Props extends CoachOfferingListProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class CoachOfferingList extends React.Component<Props> {
  render() {
    const { Offerings } = this.props;
    return (Offerings?.size ?? 0) === 0 ? (
      <CoachOfferingListSkeleton />
    ) : (
      <CoachOfferingListComponent
        Offerings={Offerings!}
        SuccessCallback={() => this.props.SuccessCallback()}
      />
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(CoachOfferingList);
