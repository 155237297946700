import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";
import KanoHeader from "./KanoHeader";
import { ReactComponent as Logo } from "Images/logo.svg";
import IconComponent from "./IconComponent";
import KanoFooter from "./KanoFooter";

//#region Component Properties
interface KanoNavigationAreaProps extends WithStyles<typeof styles> {
  navigationItems: Array<NavigationItem>;
  logo: string;
  children: React.ReactNode;
  logoOnClick: () => void;
}

export interface NavigationItem {
  icon: IconComponent;
  text: string;
  route: string;
}
//#endregion

//#region Component
class KanoNavigationArea extends React.Component<KanoNavigationAreaProps> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <header className={classes.header}>
          <KanoHeader
            logo={Logo}
            logoOnClick={this.props.logoOnClick}
            brandName={
              <>
                <span aria-label="kaino">Kano</span> Train
              </>
            }
          />
        </header>
        <main className={classes.content}>{this.props.children}</main>
        <footer className={classes.footer}>
          <KanoFooter />
        </footer>
      </div>
    );
  }
}
//#endregion

//#region Styling
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexFlow: "column",
      background: theme.palette.mainBackground,
      minHeight: "100%",
    },
    header: {
      flex: "0 1 auto",
    },
    content: {
      flex: "1 1 auto",
      display: "flex",
    },
    footer: {
      width: "100%",
      flex: "0 1 2em",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "0.5rem",
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(KanoNavigationArea);
