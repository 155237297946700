import React from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Box,
  CardActions,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  WithTheme,
  withTheme,
} from "@material-ui/core";
import RegistrationStep from "./RegistrationStep";
import AthleteInfo from "./Data/AthleteInfo";
import AthleteInfoStep from "./Steps/AthleteInfoStep";
import SmallBranding from "Components/SmallBranding";
import PrimaryButton from "Components/PrimaryButton";
import SecondaryButton from "Components/SecondaryButton";
import PositionInfoStep from "./Steps/PositionInfoStep";
import PositionInfo from "./Data/PositionInfo";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ProfileInfoStep from "./Steps/ProfileInfoStep";
import ProfileInfo from "./Data/ProfileInfo";
import UserFriendlyError from "Utils/UserFriendlyError";
import Account from "Sdk/Controllers/AccountController";
import history from "Utils/history";
import Player, { IPlayer } from "Sdk/Data/Models/Player";
import PlayerController from "Sdk/Controllers/PlayerController";
import TermsOfServiceStep from "./Steps/TermsOfServiceStep";
import TermsOfServiceInfo from "./Data/TermsOfServiceInfo";

function noop() {}
function defaultAlert(message: string) {
  window.alert(message);
}

//#region Properties
export interface RegistrationProps {
  /** Display the Kano Train branding even if your licensing permits it to be removed */
  ForceDisplayBranding?: boolean;
  alertProvider?: (message: string) => void;
  successCallback?: () => void;
  OrganizationId?: string;
}
interface Props
  extends RegistrationProps,
    WithStyles<typeof styles>,
    WithTheme {}
//#endregion

interface State {
  activeStep: number;
  orgWaiver: string;
}

//#region Component
class KanoTrainRegistration extends React.Component<Props, State> {
  private readonly alertProvider: (message: string) => void;
  private readonly successCallback: () => void;

  constructor(props: Props) {
    super(props);
    this.state = { activeStep: 0, orgWaiver: "" };

    this.alertProvider = props.alertProvider ?? defaultAlert;
    this.successCallback = props.successCallback ?? noop;

    this.AthleteInfoStep = new AthleteInfoStep();
    this.PositionInfoStep = new PositionInfoStep({
      organizationId: props.OrganizationId,
    });
    this.ProfileInfoStep = new ProfileInfoStep();
    this.TermsOfServiceStep = new TermsOfServiceStep(this.props.OrganizationId);

    this.steps = [
      this.AthleteInfoStep,
      this.PositionInfoStep,
      this.TermsOfServiceStep,
      this.ProfileInfoStep,
    ];
  }

  private readonly AthleteInfoStep: AthleteInfoStep;
  private readonly PositionInfoStep: PositionInfoStep;
  private readonly ProfileInfoStep: ProfileInfoStep;
  private readonly TermsOfServiceStep: TermsOfServiceStep;

  private readonly steps: RegistrationStep<
    AthleteInfo | PositionInfo | TermsOfServiceInfo | ProfileInfo
  >[];

  private async submitData(): Promise<boolean> {
    // Already been checked for validity
    let athleteInfo = this.AthleteInfoStep.getData() as AthleteInfo;
    let positionInfo = this.PositionInfoStep.getData() as PositionInfo;
    let profileInfo = this.ProfileInfoStep.getData() as ProfileInfo;

    let user = new Player(
      profileInfo.Email,
      athleteInfo.AthleteFirstName,
      athleteInfo.AthleteLastName,
      athleteInfo.SchoolName,
      athleteInfo.ZipCode,
      true,
      athleteInfo.BirthDay,
      positionInfo.PlayingLevel,
      athleteInfo.HeightInches,
      athleteInfo.WeightPounds,
      positionInfo.GraduationYear,
      profileInfo.ProfilePicture?.toString(),
      profileInfo.ProfilePictureThumb?.toString()
    );

    let newPlayerResponse = await Account.createNewPlayerAndLogin(
      user,
      profileInfo.Password
    );
    if (!newPlayerResponse.wasSuccess || !newPlayerResponse.data) {
      newPlayerResponse.wasSuccess = false;
      this.alertProvider(newPlayerResponse.error!.message);
      return false;
    }
    let player = newPlayerResponse.data as IPlayer;

    let newSchoolResponse = await Account.createSchool(athleteInfo.SchoolName);
    if (!newSchoolResponse.wasSuccess || !newSchoolResponse.data) {
      newSchoolResponse.wasSuccess = false;
      this.alertProvider(newSchoolResponse.error!.message);
      return false;
    }
    let school = newSchoolResponse.data;

    player.Organization = school;
    let updatePlayerResponse = await PlayerController.updatePlayer(player);
    if (!updatePlayerResponse.wasSuccess) {
      this.alertProvider(updatePlayerResponse.error!.message);
      return false;
    }

    let newPlayerPositionResponse = await Account.createPlayerPosition(
      player,
      school,
      positionInfo.Position,
      positionInfo.YearsExperience,
      positionInfo.YearsOnVarsity ?? 0,
      true,
      positionInfo.ScholarshipLevel
    );
    if (!newPlayerPositionResponse.wasSuccess) {
      this.alertProvider(newPlayerPositionResponse.error!.message);
      return false;
    }

    return true;
  }

  private backClicked() {
    // TODO: Test this in iframe!
    if (this.state.activeStep === 0) history.goBack();

    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  private isLastStep() {
    return this.state.activeStep === this.steps.length - 1;
  }

  private getNextText() {
    return this.isLastStep() ? "Submit" : "Next";
  }

  private async nextClicked() {
    let data = this.steps[this.state.activeStep].getData();
    let errorMessage = (data as UserFriendlyError).message;
    if (errorMessage) {
      alert(errorMessage);
      return;
    }

    if (this.steps[this.state.activeStep] === this.AthleteInfoStep) {
      data = data as AthleteInfo;
      this.ProfileInfoStep.setName(
        `${data.AthleteFirstName} ${data.AthleteLastName}`
      );
    }

    if (this.isLastStep()) {
      if (await this.submitData()) {
        this.successCallback();
      }
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Box boxShadow={12} height="fit-content" className={classes.root}>
        <Card className={classes.card}>
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              await this.nextClicked();
            }}
          >
            <CardContent>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Stepper
                  orientation="vertical"
                  activeStep={this.state.activeStep}
                  className={classes.stepper}
                >
                  {this.steps.map((step) => {
                    return (
                      <Step>
                        <StepLabel>{step.StepTitle}</StepLabel>
                        <StepContent>{step.getComponent()}</StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
              </MuiPickersUtilsProvider>
            </CardContent>
            <CardActions className={classes.actionArea}>
              <Box>
                <SecondaryButton
                  text="Back"
                  onClick={() => this.backClicked()}
                />
                <Box display="inline" mr={1} />
                <PrimaryButton type="submit" text={this.getNextText()} />
              </Box>
              <SmallBranding forceDisplay={this.props.ForceDisplayBranding} />
            </CardActions>
          </form>
        </Card>
      </Box>
    );
  }
}
//#endregion

//#region Stylesr
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
    },
    card: {
      padding: "0.25em",
    },
    actionArea: {
      justifyContent: "space-between",
    },
    stepper: {
      padding: 0,
    },
  });
};
//#endregion

export default withTheme(
  withStyles(styles, { withTheme: true })(KanoTrainRegistration)
);
