import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

//#region Properties
interface Props extends WithStyles<typeof styles> {}
//#endregion

//#region Component
class UserCardSkeleton extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circle"
              width={50}
              height={50}
            />
          }
          title={
            <Skeleton animation="wave" variant="text" height={30} width="80%" />
          }
          subheader={
            <Skeleton animation="wave" variant="text" height={15} width="40%" />
          }
        />
        <CardContent>
          {[...Array(4)].map(() => (
            <Box padding={1}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={50}
                className={classes.center}
              />
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    center: {
      margin: "auto",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(UserCardSkeleton);
