import React from "react";
import UserFriendlyError from "Utils/UserFriendlyError";
import PositionInfo from "../../Data/PositionInfo";
import RegistrationStep from "../../RegistrationStep";
import PositionInfoStepComponent from "./PositionInfoStepComponent";

export interface PositionInfoStepProps {
  organizationId?: string;
}

export default class PositionInfoStep
  implements RegistrationStep<PositionInfo> {
  public StepTitle = "Position Information";
  private componentRef: React.RefObject<PositionInfoStepComponent> = React.createRef();

  private organizationId?: string;
  private data?: PositionInfo;

  constructor(props: PositionInfoStepProps) {
    this.organizationId = props.organizationId;
  }

  public getComponent() {
    return (
      <PositionInfoStepComponent
        ref={this.componentRef}
        OrganizationId={this.organizationId}
        data={this.data}
      />
    );
  }

  componentWillUnmount() {
    // Make sure we've always got the latest before it's inaccessible
    this.getData();
  }

  public getData() {
    let component = this.componentRef.current;
    if (component == null) {
      if (this.data == null)
        return new UserFriendlyError("There was a problem");
      return this.data;
    }

    let data = component.getData();
    if (!(data as UserFriendlyError).message) {
      this.data = data as PositionInfo;
    }
    return data;
  }
}
