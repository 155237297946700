import { IUser } from "./User";
import Position from "../Reference/Position";
import ScholarshipLevel from "../Reference/ScholarshipLevel";
import { IOrganization } from "./Organization";

export interface IPlayerPosition {
  User: IUser;
  Organization: IOrganization;
  HighestScholarshipOffer?: ScholarshipLevel;
  Position: Position;
  YearsExperience: number;
  YearsStarting: number;
  KanoScore: number;
  IsPrimarySport: boolean;
}

export default class PlayerPosition implements IPlayerPosition {
  private user: IUser;
  private organization: IOrganization;
  private highestScholarshipOffer?: ScholarshipLevel;
  private position: Position;
  private yearsExperience: number;
  private yearsStarting: number;
  private kanoScore: number;
  private isPrimarySport: boolean;

  //#region Props
  public get User(): IUser {
    return this.user;
  }
  public set User(value: IUser) {
    this.user = value;
  }

  public get Organization(): IOrganization {
    return this.organization;
  }
  public set Organization(value: IOrganization) {
    this.organization = value;
  }

  public get HighestScholarshipOffer(): ScholarshipLevel | undefined {
    return this.highestScholarshipOffer;
  }
  public set HighestScholarshipOffer(value: ScholarshipLevel | undefined) {
    this.highestScholarshipOffer = value;
  }

  public get Position(): Position {
    return this.position;
  }
  public set Position(value: Position) {
    this.position = value;
  }

  public get YearsExperience(): number {
    return this.yearsExperience;
  }
  public set YearsExperience(value: number) {
    this.yearsExperience = value;
  }

  public get YearsStarting(): number {
    return this.yearsStarting;
  }
  public set YearsStarting(value: number) {
    this.yearsStarting = value;
  }

  public get KanoScore(): number {
    return this.kanoScore;
  }
  public set KanoScore(value: number) {
    this.kanoScore = value;
  }

  public get IsPrimarySport(): boolean {
    return this.isPrimarySport;
  }
  public set IsPrimarySport(value: boolean) {
    this.isPrimarySport = value;
  }
  //#endregion

  constructor(
    user: IUser,
    organization: IOrganization,
    position: Position,
    yearsExperience: number,
    yearsStarting: number,
    kanoScore: number,
    isPrimarySport: boolean,
    scholarshipLevel?: ScholarshipLevel
  ) {
    this.user = user;
    this.organization = organization;
    this.highestScholarshipOffer = scholarshipLevel;
    this.position = position;
    this.yearsExperience = yearsExperience;
    this.yearsStarting = yearsStarting;
    this.kanoScore = kanoScore;
    this.isPrimarySport = isPrimarySport;
  }
}
