import Box from "@material-ui/core/Box";
import React from "react";
import UserFriendlyError from "Utils/UserFriendlyError";
import TermsOfServiceInfo from "../../Data/TermsOfServiceInfo";
import RegistrationStepComponent from "../RegistrationStepComponent";
import TermsOfServiceContent from "./TermsOfServiceContent";
import classes from "../Steps.module.css";
import LabeledCheckbox from "Components/Inputs/LabeledCheckbox";
import { Typography } from "@material-ui/core";

export interface TermsOfServiceStepComponentProps {
  data?: TermsOfServiceInfo;
  orgWaiver: string;
}

interface State extends Partial<TermsOfServiceInfo> {
  HasAgreed: boolean;
}

export default class TermsOfServiceStepComponent
  extends React.Component<TermsOfServiceStepComponentProps, State>
  implements RegistrationStepComponent<TermsOfServiceInfo> {
  public StepTitle = "Terms of Service";

  constructor(props: TermsOfServiceStepComponentProps) {
    super(props);

    let state: State = { AllowsMarketingEmails: true, HasAgreed: false };
    if (this.props.data) {
      state.HasAgreed = !!this.props.data.AgreementDateTime;
      state.AllowsMarketingEmails = this.props.data.AllowsMarketingEmails;
    }

    if (process.env.NODE_ENV === "development") {
      state.HasAgreed = true;
      state.AllowsMarketingEmails = false;
      state.AgreementDateTime = new Date();
    }

    this.state = state;
  }

  public getData(): TermsOfServiceInfo | UserFriendlyError {
    let state: State = this.state;

    if (!state.HasAgreed) {
      return new UserFriendlyError(
        "Agreement to Terms of Service is required to continue"
      );
    } else if (state.AllowsMarketingEmails === undefined) {
      this.setState({ AllowsMarketingEmails: true });

      return new UserFriendlyError(
        "There was an error. Please reselect if you'd lke to allow marketing emails"
      );
    }

    return {
      AgreementDateTime: new Date(),
      AllowsMarketingEmails: state.AllowsMarketingEmails,
    };
  }

  render() {
    return (
      <>
        <Box
          className={classes.root}
          padding={2}
          height={230}
          overflow="auto"
          bgcolor="#FFF1"
          border="1px solid #FFF3"
        >
          <Box>
            <TermsOfServiceContent />
          </Box>
        </Box>
        {this.props.orgWaiver && (
          <Box mt={2}>
            <Typography variant="h5">
              RELEASE OF LIABILITY, INDEMNITY, AND ASSUMPTION OF RISK
            </Typography>
            <Box
              className={classes.root}
              padding={2}
              maxHeight={230}
              overflow="auto"
              bgcolor="#FFF1"
              border="1px solid #FFF3"
              mt={1}
              justifyContent="start"
            >
              <Box>{this.props.orgWaiver}</Box>
            </Box>
          </Box>
        )}
        <Box className={classes.root}>
          <LabeledCheckbox
            className={classes.field}
            label={`Do you (or your parent/guardian if under 18) accept these terms of service${
              this.props.orgWaiver && " and release waiver"
            }?`}
            value={this.state.HasAgreed}
            onChange={(value) => this.setState({ HasAgreed: value })}
          />
        </Box>
        <Box className={classes.root}>
          <LabeledCheckbox
            className={classes.field}
            label="Would you like to receive non-account updates about Kano Train?"
            value={this.state.AllowsMarketingEmails ?? true}
            onChange={(value) =>
              this.setState({ AllowsMarketingEmails: value })
            }
          />
        </Box>
      </>
    );
  }
}
