import { IPlayer } from "Sdk/Data/Models/Player";
import Endpoints from "Sdk/Config/Endpoints";
import Request from "Sdk/Services/Request";
import ApiResponse from "Sdk/Utils/ApiResponse";

/**
 * Updates the player on the server.
 * ApiResponse is guaranteed to have data if there is no error.
 * @param player The player to send to the server.
 * @param force !Careful! Forces the changes (update properties to null, etc)
 */
async function updatePlayer(
  player: IPlayer,
  force?: boolean
): Promise<ApiResponse<IPlayer>> {
  let putPlayerEndpoint = Endpoints.player.root.clone();
  putPlayerEndpoint.Url = new URL(player.Id!, putPlayerEndpoint.Url);
  putPlayerEndpoint.Url.searchParams.append("force", (!!force).toString());
  let putPlayerResponse = await Request.put<IPlayer>(putPlayerEndpoint, player);
  if (!putPlayerResponse.wasSuccess) {
    return putPlayerResponse;
  }

  // TODO: Update user should just return the updated user - requires backend change
  let getPlayerEndpoint = Endpoints.player.root.clone();
  getPlayerEndpoint.Url = new URL(player.Id!, getPlayerEndpoint.Url);
  getPlayerEndpoint.Url.searchParams.append("hydrate", "true");
  let getPlayerResponse = await Request.get<IPlayer>(getPlayerEndpoint);
  if (!getPlayerResponse.wasSuccess || !getPlayerResponse.data) {
    getPlayerResponse.wasSuccess = false;
  }

  return getPlayerResponse;
}

const PlayerController = {
  updatePlayer,
};

export default PlayerController;
