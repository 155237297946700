import AbstractOrderedReferenceData from "./AbstractOrderedReferenceData";

// TODO: This should all really just be an enum - requires backend change
export type PlayingLevelOptions =
  | "Youth"
  | "MiddleSchool"
  | "Middle School"
  | "HighSchool"
  | "High School"
  | "College"
  | "Professional";

export default class PlayingLevel extends AbstractOrderedReferenceData {
  // Organized like this to ease transition to enum
  public static Youth = new PlayingLevel(
    "f353a4b4-0c47-4bb5-9be9-9989bdddc416",
    "Youth",
    "YOUTH",
    1
  );
  public static MiddleSchool = new PlayingLevel(
    "8a608e69-d9db-4206-8be7-4d7d27efae55",
    "Middle School",
    "MIDDLESCHOOL",
    2
  );
  public static HighSchool = new PlayingLevel(
    "1a90030a-ceb1-4df9-bb0d-352ef592c7df",
    "High School",
    "HIGHSCHOOL",
    3
  );
  public static College = new PlayingLevel(
    "e9edf9cb-b78b-4629-a644-442bdd7fd75e",
    "College",
    "COLLEGE",
    4
  );
  public static Professional = new PlayingLevel(
    "062a1f72-a444-4e13-bc7c-d6fcb6932612",
    "Professional",
    "PRO",
    5
  );

  // Convenience methods
  public static get "Middle School"() {
    return this.MiddleSchool;
  }
  public static get "High School"() {
    return this.HighSchool;
  }
}
