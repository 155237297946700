import React, { ReactNode } from "react";
import {
  Container,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import KanoTrainPurchasing from "Components/Sharable/KanoTrainPurchasing";

//#region Properties
export interface SignupPageProps {
  inviteCode: string;
}
interface Props extends SignupPageProps, WithStyles<typeof styles> {}
//#endregion

export interface SignupStep {
  title: string;
  content: ReactNode;
}

//#region Component
class SignupPage extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.root}>
        <KanoTrainPurchasing />
      </Container>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(SignupPage);
