import React from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { DisabledGray, KanoGreen } from "Themes/Colors";
import BaseButton, { BaseButtonProps } from "./BaseButton/BaseButton";

//#region Properties
export interface PrimaryButtonProps
  extends Omit<BaseButtonProps, "color" | "variant"> {}
interface Props extends PrimaryButtonProps, WithStyles<typeof styles> {}
//#endregion

//#region Component
class PrimaryButton extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <BaseButton
        {...this.props}
        className={`${classes.root} ${this.props.className} ${
          this.props.disabled ? classes.disabled : ""
        }`}
        variant="contained"
      />
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      background: `linear-gradient(190deg, ${KanoGreen[400]} 5%, ${KanoGreen[500]} 10%, ${KanoGreen[600]} 55%, ${KanoGreen[700]} 90%, ${KanoGreen[800]})`,
      "&:hover": {
        background: `linear-gradient(190deg, ${KanoGreen[400]} 5%, ${KanoGreen[500]} 55%, ${KanoGreen[600]} 90%, ${KanoGreen[700]})`,
      },
      "&:disabled": {
        background: `linear-gradient(190deg, ${DisabledGray[300]} 5%, ${DisabledGray[500]} 55%, ${DisabledGray[600]} 90%, ${DisabledGray[700]})`,
      },
    },
    disabled: {},
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(PrimaryButton);
