import React from "react";
import {
  Box,
  CardActionArea,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import CoachOfferingCardSkeleton from "./CoachOfferingCardSkeleton";
import TrainingCourseCardComponent from "./TrainingCourseCardComponent";
import animation from "Utils/animation";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import TrapFocus from "@material-ui/core/Unstable_TrapFocus";
import TrainingCampCardComponent from "./TrainingCampCardComponent";
import OfferingUnion from "Sdk/Data/Local/OfferingUnion";

//#region Properties
export interface CoachOfferingCardProps {
  Offering?: OfferingUnion;
  SuccessCallback: () => void;
}
interface Props extends CoachOfferingCardProps, WithStyles<typeof styles> {}

interface State {
  IsHovered: boolean;
  IsOpen: boolean;
}
//#endregion

//#region Component
class CoachOfferingCard extends React.Component<Props, State> {
  private cardRef: React.RefObject<HTMLElement>;
  private animationDuration = 250;
  constructor(props: Props) {
    super(props);

    this.cardRef = React.createRef();
    this.state = { IsHovered: false, IsOpen: false };
  }

  onClick(e: React.MouseEvent) {
    e.preventDefault();

    animation.doOutOfFlowTransition(
      this.cardRef.current as HTMLElement,
      this.props.classes.open,
      () => {
        this.forceUpdate();
      },
      this.animationDuration
    );
    if (!this.state.IsOpen) {
      this.setState({ IsHovered: false });
      disableBodyScroll(this.cardRef.current!);
    } else {
      setTimeout(() => {
        enableBodyScroll(this.cardRef.current!);
      }, this.animationDuration);
    }
    this.setState({ IsOpen: !this.state.IsOpen });
  }

  private renderCard(): JSX.Element {
    if (this.props.Offering?.Camp !== undefined) {
      return this.renderCampCard();
    } else if (this.props.Offering?.Course !== undefined) {
      return this.renderCourseCard();
    } else {
      throw Error("Bad State. Camp or Course must be defined");
    }
  }

  private renderCourseCard(): JSX.Element {
    return (
      <TrainingCourseCardComponent
        Course={this.props.Offering!.Course!}
        IsOpen={this.state.IsOpen}
        BackCallback={(e) => this.onClick(e)}
        SuccessCallback={() => this.props.SuccessCallback()}
      />
    );
  }

  private renderCampCard(): JSX.Element {
    return (
      <TrainingCampCardComponent
        Camp={this.props.Offering!.Camp!}
        IsOpen={this.state.IsOpen}
        BackCallback={(e) => this.onClick(e)}
        SuccessCallback={() => this.props.SuccessCallback()}
      />
    );
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const { classes, Offering } = this.props;
    const { IsHovered, IsOpen } = this.state;
    const IsLoaded =
      Offering?.Course !== undefined || Offering?.Camp !== undefined;

    return (
      <Box
        boxShadow={IsLoaded && IsHovered ? 12 : 8}
        className={classes.root}
        onMouseEnter={() =>
          IsOpen ? () => {} : this.setState({ IsHovered: true })
        }
        onMouseLeave={() =>
          IsOpen ? () => {} : this.setState({ IsHovered: false })
        }
        onClick={IsLoaded && !IsOpen ? (e) => this.onClick(e) : () => {}}
        // HACK: Material UI supports ref here, but it's missing from the typescript definitions
        {...{ ref: this.cardRef }}
      >
        {IsLoaded ? (
          IsOpen ? (
            <TrapFocus open getDoc={() => document} isEnabled={() => true}>
              {this.renderCard()}
            </TrapFocus>
          ) : (
            <CardActionArea disableRipple style={{ height: "100%" }}>
              {this.renderCard()}
            </CardActionArea>
          )
        ) : (
          <CoachOfferingCardSkeleton />
        )}
      </Box>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: "25em",
      height: "29em",
      margin: "1em",
      transition: "all 200ms ease-in-out",
      "&$open": {
        width: "100% !important",
        height: "100% !important",
        left: "0px !important",
        top: "0px !important",
      },
    },
    open: {},
    dontShowFocus: {
      "&:hover > .MuiCardActionArea-focusHighlight": {
        opacity: 0,
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(CoachOfferingCard);
