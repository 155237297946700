const getPosition = (elem: HTMLElement) => {
  const rect = elem.getBoundingClientRect();
  return {
    top: toPx(rect.top),
    left: toPx(rect.left),
    width: toPx(rect.width),
    height: toPx(rect.height),
  };
};

function toPx(val: number) {
  return [val, "px"].join("");
}

const savedInfo = new Map<HTMLElement, ElementInfo>();
const pendingTimeouts = new Map<HTMLElement, NodeJS.Timeout>();
interface ElementInfo {
  prevTop: string;
  prevLeft: string;
  prevWidth: string;
  prevHeight: string;
  prevPosition: string;
  prevMargin: string;
  prevZIndex: string;
  replacementElement: HTMLElement;
}

/**
 * Helper for doing out of flow transitions - i.e. one that requires switching to absolute positioning and then back.
 * This function handles changing the positioning type without jarring and setting the given class.
 * All other details are left to the caller.
 *
 * The element will be moved up in the z-index, so will appear on top.
 *
 * @param element The element to transition.
 * @param endingClass The class that represents the ending state of the transition
 * @param transitionDurationMs The duration of the transition - should match what is already on the element.
 * @param forceRefresh A function to force a refresh of the component.
 *
 * @see {@link https://stackoverflow.com/a/52766975/2230115|Inspiration from StackOverflow}
 */
function doOutOfFlowTransition(
  element: HTMLElement,
  endingClass: string,
  forceRefresh: () => void,
  transitionDurationMs: number = 250
) {
  if (element.classList.contains(endingClass)) {
    // animate back
    element.classList.remove(endingClass);
    let oldTimeout = pendingTimeouts.get(element);
    if (oldTimeout !== undefined) {
      window.clearTimeout(oldTimeout);
      pendingTimeouts.delete(element);
    }

    pendingTimeouts.set(
      element,
      setTimeout(() => {
        let info = savedInfo.get(element);
        if (info) {
          info.replacementElement.remove();
          element.style.top = info.prevTop;
          element.style.left = info.prevLeft;
          element.style.width = info.prevWidth;
          element.style.height = info.prevHeight;
          element.style.margin = info.prevMargin;
          element.style.position = info.prevPosition;
          element.style.zIndex = info.prevZIndex;
          savedInfo.delete(element);
        }
        pendingTimeouts.delete(element);
      }, transitionDurationMs + 50)
    );
  } else {
    // run animation
    clearPendingAnimationTimeout(element);
    let replacementElement = saveElementProperties(element);
    if (replacementElement !== undefined) {
      replaceInFlow(element, replacementElement);
    }
    moveOutOfFlow(element);

    // Let the browser render our changes before setting the class.
    setTimeout(() => {
      element.classList.add(endingClass);
    }, 0);
  }
}

/**
 * When moving an element out of the flow, items around it will be reflowed to take up the now-empty space.
 * This function makes the replacementElement the same size and transparent,
 * then inserts it after the target element.
 *
 * @param element The element being replaced in the flow
 * @param replacementElement The element to use as a base to replace - e.g. document.createElement('div)
 */
function replaceInFlow(element: HTMLElement, replacementElement: HTMLElement) {
  element.classList.forEach((cssClass) => {
    replacementElement.classList.add(cssClass);
  });
  element.insertAdjacentElement("afterend", replacementElement);
  replacementElement.style.height = element.style.height;
  replacementElement.style.width = element.style.width;
  replacementElement.style.left = element.style.left;
  replacementElement.style.top = element.style.top;
  replacementElement.style.border = "none";
  replacementElement.style.boxShadow = "none";
  replacementElement.style.backgroundColor = "transparent";
}

/**
 * Clears any pending timeouts
 *
 * @param element The element being animated
 */
function clearPendingAnimationTimeout(element: HTMLElement) {
  let pendingTimeout = pendingTimeouts.get(element);
  if (pendingTimeout) {
    window.clearTimeout(pendingTimeout);
  }
  pendingTimeouts.delete(element);
}

function saveElementProperties(element: HTMLElement): HTMLElement | undefined {
  let elementInfo = savedInfo.get(element);
  if (elementInfo === undefined) {
    elementInfo = {
      prevTop: element.style.top,
      prevLeft: element.style.left,
      prevWidth: element.style.width,
      prevHeight: element.style.height,
      prevPosition: element.style.position,
      prevMargin: element.style.margin,
      prevZIndex: element.style.zIndex,
      replacementElement: document.createElement("div"),
    };
    savedInfo.set(element, elementInfo);
    return elementInfo.replacementElement;
  }
}

function moveOutOfFlow(element: HTMLElement) {
  let pos = getPosition(element);
  element.style.margin = "0px";
  element.style.top = pos.top;
  element.style.left = pos.left;
  element.style.width = pos.width;
  element.style.height = pos.height;
  element.style.position = "fixed";
  element.style.zIndex = "100";
}

const animation = {
  doOutOfFlowTransition,
};

export default animation;
