import { IUser } from "./User";

export interface IUserPassword {
  User: IUser;
  Password: string;
  OldPassword?: string;
}

export default class UserPassword implements IUserPassword {
  private user: IUser;
  private password: string;
  private oldPassword?: string;

  //#region Props
  public get User(): IUser {
    return this.user;
  }
  public set User(value: IUser) {
    this.user = value;
  }

  public get Password(): string {
    return this.password;
  }
  public set Password(value: string) {
    this.password = value;
  }

  public get OldPassword(): string | undefined {
    return this.oldPassword;
  }
  public set OldPassword(value: string | undefined) {
    this.oldPassword = value;
  }
  //#endregion

  constructor(user: IUser, password: string, oldPassword?: string) {
    this.user = user;
    this.password = password;
    this.oldPassword = oldPassword;
  }
}
