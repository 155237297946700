import Endpoints from "Sdk/Config/Endpoints";
import { ITrainingCamp } from "Sdk/Data/Models/TrainingCamp";
import { ITrainingCampPackage } from "Sdk/Data/Models/TrainingCampPackage";
import ApiResponse from "Sdk/Utils/ApiResponse";
import Request from "Sdk/Services/Request";
import { ICoach } from "Sdk/Data/Models/Coach";
import { ITrainingCampEvent } from "Sdk/Data/Models/TrainingCampEvent";

async function getTrainingCamp(
  campId: string
): Promise<ApiResponse<ITrainingCamp>> {
  let endpoint = Endpoints.trainingCamp.root.clone();
  Endpoints.addToRoute(endpoint, campId!);

  return await Request.get<ITrainingCamp>(endpoint);
}

async function getPurchasingOptions(
  camp: ITrainingCamp
): Promise<ApiResponse<ITrainingCampPackage[]>> {
  let endpoint = Endpoints.trainingCampPackages.getTrainingCampPackages.clone();
  endpoint.Url.searchParams.append("campId", camp.Id!.toString());
  endpoint.Url.searchParams.append("activeOnly", "true");

  return await Request.get<ITrainingCampPackage[]>(endpoint);
}

async function getEventsForCamp(
  camp: ITrainingCamp
): Promise<ApiResponse<ITrainingCampEvent[]>> {
  let endpoint = Endpoints.trainingCampEvents.getEventsForCamp.clone();
  endpoint.Url.searchParams.append("campId", camp.Id!.toString());

  return await Request.get<ITrainingCampEvent[]>(endpoint);
}

async function getStripeToken(
  camp: ITrainingCamp
): Promise<ApiResponse<string>> {
  let endpoint = Endpoints.trainingCamp.getStripeToken.clone();
  endpoint.Url.searchParams.set("campId", camp.Id!);

  return await Request.get<string>(endpoint);
}

async function getCampsForCoach(
  coach: ICoach,
  ignoreDisabled: boolean = true
): Promise<ApiResponse<ITrainingCamp[]>> {
  let endpoint = Endpoints.trainingCamp.getCampsForCoach.clone();
  endpoint.Url.searchParams.set("coachId", coach.Id!);
  endpoint.Url.searchParams.append("ignoreDisabled", ignoreDisabled.toString());

  return await Request.get<ITrainingCamp[]>(endpoint);
}

const TrainingCampsController = {
  getTrainingCamp,
  getPurchasingOptions,
  getStripeToken,
  getCampsForCoach,
  getEventsForCamp,
};

export default TrainingCampsController;
