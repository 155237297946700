// Closure used to assign directly to const without checking node_env every function call.
const isKanoHosted = (() => {
  let hostname = window.location.hostname;
  if (process.env.NODE_ENV === "production") {
    return () => hostname.endsWith("kanotrain.com");
  } else {
    return () => true;
  }
})();

function shouldRemoveBranding() {
  return isKanoHosted();
}

const Licensing = {
  shouldRemoveBranding,
};

export default Licensing;
