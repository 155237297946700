import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Endpoints from "Sdk/Config/Endpoints";
import { sortBy as _sortBy } from "lodash-es";
import { BlueGray } from "Themes/Colors";
import SecondaryButton from "Components/SecondaryButton";
import { ITrainingCampPackage } from "Sdk/Data/Models/TrainingCampPackage";
import TrainingCampsController from "Sdk/Controllers/TrainingCampsController";
import { ITrainingCamp } from "Sdk/Data/Models/TrainingCamp";
import PurchaseCampPackage from "Components/PurchaseCampPackage";
import { DaysBetweenCalendarDates } from "Utils/date";

//#region Properties
export interface TrainingCampCardComponentProps {
  Camp: ITrainingCamp;
  IsOpen: boolean;
  BackCallback: (e: React.MouseEvent) => void;
  SuccessCallback: () => void;
}
interface Props
  extends TrainingCampCardComponentProps,
    WithStyles<typeof styles> {}
interface State {
  Packages: ITrainingCampPackage[];
  Error: string;
  SelectedPurchaseOption?: ITrainingCampPackage;
  StartDate?: Date;
  DurationDays?: number;
}
//#endregion

//#region Component
class TrainingCampCardComponent extends React.Component<Props, State> {
  private currencyFormatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  constructor(props: Props) {
    super(props);

    this.state = { Packages: [], Error: "" };
  }

  async componentDidMount() {
    let sessionPackagePromise = TrainingCampsController.getPurchasingOptions(
      this.props.Camp
    );

    let eventsPromise = TrainingCampsController.getEventsForCamp(
      this.props.Camp
    );

    const sessionPackageResponse = await sessionPackagePromise;
    if (!sessionPackageResponse.wasSuccess) {
      this.setState({ Error: sessionPackageResponse.error!.message });
      return;
    }

    const eventsResponse = await eventsPromise;
    if (!eventsResponse.wasSuccess) {
      this.setState({ Error: eventsResponse.error!.message });
      return;
    }

    this.props.Camp.TrainingCampEvents = eventsResponse.data!;

    this.setState({
      Packages: _sortBy(sessionPackageResponse.data!, (sp) => sp.Price),
      StartDate: this.calculateStartDate(this.props.Camp),
      DurationDays: this.calculateDurationDays(this.props.Camp),
    });
  }

  calculateStartDate(camp: ITrainingCamp): Date | undefined {
    if (camp.TrainingCampEvents.length <= 0) {
      return undefined;
    }

    let firstEvent = camp.TrainingCampEvents[0];
    camp.TrainingCampEvents.forEach((event) => {
      if (event.StartTime < firstEvent.StartTime) {
        firstEvent = event;
      }
    });

    return firstEvent.StartTime!;
  }

  calculateDurationDays(camp: ITrainingCamp): number | undefined {
    const startDate = this.calculateStartDate(camp);
    if (startDate === undefined) {
      return undefined;
    }

    let lastEvent = camp.TrainingCampEvents[0];
    camp.TrainingCampEvents.forEach((event) => {
      if (event.StopTime > lastEvent.StopTime) {
        lastEvent = event;
      }
    });

    return DaysBetweenCalendarDates(startDate, lastEvent.StopTime);
  }

  daysAsLabelledString(days: number): string {
    if (days === 1) {
      return "1 Day";
    } else {
      return `${days} Days`;
    }
  }

  onClickPurchaseOption(
    e: React.MouseEvent<HTMLElement>,
    purchaseOption: ITrainingCampPackage
  ) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ SelectedPurchaseOption: purchaseOption });
  }

  onBackFromPurchaseOption() {
    this.setState({ SelectedPurchaseOption: undefined });
  }

  // TODO: It would be cool if this were more fun on mobile - e.g. swiping down keeps the card with your finger, returns to original position on release
  render() {
    const { classes, Camp, IsOpen } = this.props;
    const {
      Packages: SessionPackages,
      SelectedPurchaseOption,
      StartDate,
      DurationDays,
    } = this.state;

    return (
      <Card className={`${classes.root} ${IsOpen ? classes.scrollable : ""}`}>
        {Camp.HeaderImageUrl ? (
          <CardMedia
            image={new URL(
              Camp.HeaderImageUrl,
              Endpoints.imageBackend.trainingCourse
            ).toString()}
            style={{ minHeight: "10em", height: "40%" }}
          />
        ) : (
          <CardMedia
            image="fallback_image.svg"
            style={{ minHeight: "10em", height: "40%" }}
          />
        )}
        <CardContent>
          <Box>
            <Box display="inline-block">
              <Typography variant={IsOpen ? "h3" : "h4"}>
                {Camp.Name}
              </Typography>
              <hr
                className={classes.coloredUnderline}
                color={Camp.HighlightColor}
                style={{
                  color: Camp.HighlightColor,
                  backgroundColor: Camp.HighlightColor,
                  borderColor: Camp.HighlightColor,
                }}
              />
            </Box>
            <Typography color="textSecondary">{Camp.Description}</Typography>
            <Box m={2} />
            {IsOpen ? (
              <>
                <Typography variant="h4">Camp Details</Typography>
                <Box m={1} />
              </>
            ) : (
              ""
            )}
            {StartDate !== undefined && (
              <Typography gutterBottom>
                <b>Start Date:</b>{" "}
                {new Date(StartDate).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </Typography>
            )}
            {DurationDays !== undefined && (
              <Typography gutterBottom>
                <b>Duration:</b> {this.daysAsLabelledString(DurationDays)}
              </Typography>
            )}
            <Box m={2} />
            {IsOpen ? (
              SelectedPurchaseOption ? (
                <PurchaseCampPackage
                  Package={SelectedPurchaseOption}
                  OnBack={() => this.onBackFromPurchaseOption()}
                  SuccessCallback={() => this.props.SuccessCallback()}
                />
              ) : (
                <>
                  <Typography variant="h4">
                    Choose a purchasing option
                  </Typography>
                  <Box display="flex" flexWrap="wrap">
                    {!this.state.Error
                      ? SessionPackages.map((purchaseOption) => (
                          <Box
                            m={3}
                            borderColor={Camp.HighlightColor}
                            className={classes.purchaseOption}
                            boxShadow={8}
                            onClick={(e) =>
                              this.onClickPurchaseOption(e, purchaseOption)
                            }
                          >
                            <CardActionArea>
                              <Box p={2}>
                                <Typography>
                                  Price:{" "}
                                  {this.currencyFormatter.format(
                                    purchaseOption.Price * 1.12
                                  )}
                                </Typography>
                                <Box m={1} />
                              </Box>
                            </CardActionArea>
                          </Box>
                        ))
                      : this.state.Error}
                  </Box>

                  <SecondaryButton
                    text="Back"
                    onClick={(e) => this.props.BackCallback(e)}
                  />
                </>
              )
            ) : (
              ""
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }
}
//#endregion

//#region Styles
const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: "100%",
    },
    scrollable: {
      overflowY: "auto",
    },
    coloredUnderline: {
      height: "4px",
      borderRadius: "2px",
    },
    purchaseOption: {
      borderRadius: "5px",
      borderStyle: "solid",
      borderWidth: "3px",
      "&:hover": {
        filter: "brightness(150%)",
        backgroundColor: BlueGray[500],
        transform: "translatey(-1px)",
      },
    },
  });
};
//#endregion

export default withStyles(styles, { withTheme: true })(
  TrainingCampCardComponent
);
